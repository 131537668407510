import {PROFESSIONAL} from 'constant/constants';
import {checkUserPermission, getAverageScore} from './utils';
import {doc, getDoc} from 'firebase/firestore';
import {firestore} from 'utils/firebaseInit';
const APPSETTINGS_COLLECTION = 'appSettings';

export function extractSideViewJson(jsonData, localJsonVersion) {
  const json_version = jsonData?.json_version
    ? jsonData?.json_version
    : jsonData[0]?.json_version;

  function getSegmentAngle(frame, segment, direction) {
    const angles = frame?.angles;
    const side = frame?.side;

    const getAngle = key =>
      angles && angles[key] ? angles[key].toFixed(2) : null;

    const segmentMap = {
      arm: direction === 'right' ? 'RightElbow' : 'LeftElbow',
      front_leg: side === 'left' || !side ? 'LeftKnee' : 'RightKnee',
      back_leg: side === 'right' ? 'LeftKnee' : 'RightKnee',
    };

    return getAngle(segmentMap[segment]);
  }

  if (json_version < 3) {
    return {
      cache_version: localJsonVersion,
      json_version: jsonData[0]?.json_version,
    };
  }

  if (json_version === 3)
    return {
      cache_version: localJsonVersion,
      personalised: jsonData[0]?.personalised,
      json_version: jsonData[0]?.json_version,
      direction: jsonData[0]?.direction,
      video_id: null,
      total_score: getAverageScore(
        Object.entries(jsonData[0]?.analyse?.content),
      ),
      analysis_content: {
        head: {
          score_number: jsonData[0]?.analyse?.content?.head?.score_number,
          asset_id: jsonData[0]?.analyse?.content?.head?.explanation,
          range_good: null,
          range_okay: null,
          texts: {
            exercice: jsonData[0]?.analyse?.content?.head?.texts?.exercice,
            explanation:
              jsonData[0]?.analyse?.content?.head?.texts?.explanation,
            position: jsonData[0]?.analyse?.content?.head?.texts?.position,
            score: jsonData[0]?.analyse?.content?.head?.texts?.score,
            title: jsonData[0]?.analyse?.content?.head?.texts?.title,
            recap: null,
            segmented_explanation: {
              coach_tips: null,
              consequences: null,
              correction: null,
            },
          },
        },
        trunk: {
          score_number: jsonData[0]?.analyse?.content?.trunk?.score_number,
          asset_id: jsonData[0]?.analyse?.content?.trunk?.explanation,
          range_good: null,
          range_okay: null,
          texts: {
            exercice: jsonData[0]?.analyse?.content?.trunk?.texts?.exercice,
            explanation:
              jsonData[0]?.analyse?.content?.trunk?.texts?.explanation,
            position: jsonData[0]?.analyse?.content?.trunk?.texts?.position,
            score: jsonData[0]?.analyse?.content?.trunk?.texts?.score,
            title: jsonData[0]?.analyse?.content?.trunk?.texts?.title,
            recap: null,
            segmented_explanation: {
              coach_tips: null,
              consequences: null,
              correction: null,
            },
          },
        },
        arm: {
          score_number: jsonData[0]?.analyse?.content?.arm?.score_number,
          asset_id: jsonData[0]?.analyse?.content?.arm?.explanation,
          range_good: null,
          range_okay: null,
          texts: {
            exercice: jsonData[0]?.analyse?.content?.arm?.texts?.exercice,
            explanation: jsonData[0]?.analyse?.content?.arm?.texts?.explanation,
            position: jsonData[0]?.analyse?.content?.arm?.texts?.position,
            score: jsonData[0]?.analyse?.content?.arm?.texts?.score,
            title: jsonData[0]?.analyse?.content?.arm?.texts?.title,
            recap: null,
            segmented_explanation: {
              coach_tips: null,
              consequences: null,
              correction: null,
            },
          },
        },
        back_cycle: {
          score_number: jsonData[0]?.analyse?.content?.backCycle?.score_number,
          asset_id: jsonData[0]?.analyse?.content?.backCycle?.explanation,
          range_good: null,
          range_okay: null,
          texts: {
            exercice: jsonData[0]?.analyse?.content?.backCycle?.texts?.exercice,
            explanation:
              jsonData[0]?.analyse?.content?.backCycle?.texts?.explanation,
            position: jsonData[0]?.analyse?.content?.backCycle?.texts?.position,
            score: jsonData[0]?.analyse?.content?.backCycle?.texts?.score,
            title: jsonData[0]?.analyse?.content?.backCycle?.texts?.title,
            recap: null,
            segmented_explanation: {
              coach_tips: null,
              consequences: null,
              correction: null,
            },
          },
        },
        forward_cycle: {
          score_number:
            jsonData[0]?.analyse?.content?.forwardCycle?.score_number,
          asset_id: jsonData[0]?.analyse?.content?.forwardCycle?.explanation,
          range_good: null,
          range_okay: null,
          texts: {
            exercice:
              jsonData[0]?.analyse?.content?.forwardCycle?.texts?.exercice,
            explanation:
              jsonData[0]?.analyse?.content?.forwardCycle?.texts?.explanation,
            position:
              jsonData[0]?.analyse?.content?.forwardCycle?.texts?.position,
            score: jsonData[0]?.analyse?.content?.forwardCycle?.texts?.score,
            title: jsonData[0]?.analyse?.content?.forwardCycle?.texts?.title,
            recap: null,
            segmented_explanation: {
              coach_tips: null,
              consequences: null,
              correction: null,
            },
          },
        },
        foot: {
          score_number: 0.49500000000000005,
          asset_id: null,
          foot_strike_pattern:
            jsonData[0]?.foot_landing[
              Math.round((jsonData[0]?.foot_landing?.length - 1) / 2)
            ]?.type,
          texts: {
            score: jsonData[0]?.analyse?.content?.foot?.texts?.score,
            title: jsonData[0]?.analyse?.content?.foot?.texts?.title,
            position: jsonData[0]?.analyse?.content?.foot?.texts?.position,
            overstride_title: null,
            overstride_explanation: null,
            foot_strike_explanation:
              jsonData[0]?.analyse?.content?.foot?.texts?.explanation,
            phases: null,
            exercice: jsonData[0]?.analyse?.content?.foot?.texts?.exercice,
          },
          range_good: null,
          range_okay: null,
        },
      },
      running_styles: jsonData[0]?.styles || [],
      metrics: {
        speed_meter_per_second: jsonData[0]?.speed?.toFixed(2),
        step_length_meter: jsonData[0]?.step_length?.toFixed(2),
        pace_min_per_km: jsonData[0]?.pace?.toFixed(2),
        step_frequency_steps_per_min: jsonData[0]?.steps_per_min,
        ground_contact_time_second:
          jsonData[0]?.ground_contact_time?.toFixed(2),
        time_of_flight_second: jsonData[0]?.time_between_steps?.toFixed(2),
        duty_factor_percent: jsonData[0]?.duty_factor,
      },
      joint_angles: null,
      foot_strike: {
        trunk_mean_angle: 6,
        head_mean_angle: -1,
        arm_mean_angle: getSegmentAngle(
          jsonData[0]?.foot_landing[
            Math.round((jsonData[0]?.foot_landing?.length - 1) / 2)
          ],
          'arm',
          jsonData[0]?.direction,
        ),
        front_leg_mean_angle: getSegmentAngle(
          jsonData[0]?.foot_landing[
            Math.round((jsonData[0]?.foot_landing?.length - 1) / 2)
          ],
          'front_leg',
          jsonData[0]?.direction,
        ),
        back_leg_mean_angle: getSegmentAngle(
          jsonData[0]?.foot_landing[
            Math.round((jsonData[0]?.foot_landing?.length - 1) / 2)
          ],
          'back_leg',
          jsonData[0]?.direction,
        ),
      },
      overstride: {
        mean_angle: jsonData[0]?.overstride_value,
        score: jsonData[0]?.overstride_score,
      },
    };
  if (json_version === 4)
    return {
      cache_version: localJsonVersion,
      personalised: jsonData[0]?.personalised,
      json_version: jsonData[0]?.json_version,
      direction: jsonData[0]?.direction,
      video_id: jsonData[0]?.video_id,
      total_score: getAverageScore(
        Object.entries(jsonData[0]?.analyse?.content),
      ),
      analysis_content: {
        head: {
          score_number: jsonData[0]?.analyse?.content?.head?.score_number,
          asset_id: jsonData[0]?.analyse?.content?.head?.explanation,
          range_good: jsonData[0]?.analyse?.content?.head?.range_good,
          range_okay: jsonData[0]?.analyse?.content?.head?.range_okay,
          texts: {
            exercice: jsonData[0]?.analyse?.content?.head?.texts?.exercice,
            explanation:
              jsonData[0]?.analyse?.content?.head?.texts?.explanation,
            position: jsonData[0]?.analyse?.content?.head?.texts?.position,
            score: jsonData[0]?.analyse?.content?.head?.texts?.score,
            title: jsonData[0]?.analyse?.content?.head?.texts?.title,
            recap: jsonData[0]?.analyse?.content?.head?.texts?.recap,
            segmented_explanation: {
              coach_tips:
                jsonData[0]?.analyse?.content?.head?.texts
                  ?.segmented_explanation?.coach_tips,
              consequences:
                jsonData[0]?.analyse?.content?.head?.texts
                  ?.segmented_explanation?.consequences,
              correction:
                jsonData[0]?.analyse?.content?.head?.texts
                  ?.segmented_explanation?.correction,
            },
          },
        },
        trunk: {
          score_number: jsonData[0]?.analyse?.content?.trunk?.score_number,
          asset_id: jsonData[0]?.analyse?.content?.trunk?.explanation,
          range_good: jsonData[0]?.analyse?.content?.trunk?.range_good,
          range_okay: jsonData[0]?.analyse?.content?.trunk?.range_okay,
          texts: {
            exercice: jsonData[0]?.analyse?.content?.trunk?.texts?.exercice,
            explanation:
              jsonData[0]?.analyse?.content?.trunk?.texts?.explanation,
            position: jsonData[0]?.analyse?.content?.trunk?.texts?.position,
            score: jsonData[0]?.analyse?.content?.trunk?.texts?.score,
            title: jsonData[0]?.analyse?.content?.trunk?.texts?.title,
            recap: jsonData[0]?.analyse?.content?.trunk?.texts?.recap,
            segmented_explanation: {
              coach_tips:
                jsonData[0]?.analyse?.content?.trunk?.texts
                  ?.segmented_explanation?.coach_tips,
              consequences:
                jsonData[0]?.analyse?.content?.trunk?.texts
                  ?.segmented_explanation?.consequences,
              correction:
                jsonData[0]?.analyse?.content?.trunk?.texts
                  ?.segmented_explanation?.correction,
            },
          },
        },
        arm: {
          score_number: jsonData[0]?.analyse?.content?.arm?.score_number,
          asset_id: jsonData[0]?.analyse?.content?.arm?.explanation,
          range_good: jsonData[0]?.analyse?.content?.arm?.range_good,
          range_okay: jsonData[0]?.analyse?.content?.arm?.range_okay,
          texts: {
            exercice: jsonData[0]?.analyse?.content?.arm?.texts?.exercice,
            explanation: jsonData[0]?.analyse?.content?.arm?.texts?.explanation,
            position: jsonData[0]?.analyse?.content?.arm?.texts?.position,
            score: jsonData[0]?.analyse?.content?.arm?.texts?.score,
            title: jsonData[0]?.analyse?.content?.arm?.texts?.title,
            recap: jsonData[0]?.analyse?.content?.arm?.texts?.recap,
            segmented_explanation: {
              coach_tips:
                jsonData[0]?.analyse?.content?.arm?.texts?.segmented_explanation
                  ?.coach_tips,
              consequences:
                jsonData[0]?.analyse?.content?.arm?.texts?.segmented_explanation
                  ?.consequences,
              correction:
                jsonData[0]?.analyse?.content?.arm?.texts?.segmented_explanation
                  ?.correction,
            },
          },
        },
        back_cycle: {
          score_number: jsonData[0]?.analyse?.content?.backCycle?.score_number,
          asset_id: jsonData[0]?.analyse?.content?.backCycle?.explanation,
          range_good: jsonData[0]?.analyse?.content?.backCycle?.range_good,
          range_okay: jsonData[0]?.analyse?.content?.backCycle?.range_okay,
          texts: {
            exercice: jsonData[0]?.analyse?.content?.backCycle?.texts?.exercice,
            explanation:
              jsonData[0]?.analyse?.content?.backCycle?.texts?.explanation,
            position: jsonData[0]?.analyse?.content?.backCycle?.texts?.position,
            score: jsonData[0]?.analyse?.content?.backCycle?.texts?.score,
            title: jsonData[0]?.analyse?.content?.backCycle?.texts?.title,
            recap: jsonData[0]?.analyse?.content?.backCycle?.texts?.recap,
            segmented_explanation: {
              coach_tips:
                jsonData[0]?.analyse?.content?.backCycle?.texts
                  ?.segmented_explanation?.coach_tips,
              consequences:
                jsonData[0]?.analyse?.content?.backCycle?.texts
                  ?.segmented_explanation?.consequences,
              correction:
                jsonData[0]?.analyse?.content?.backCycle?.texts
                  ?.segmented_explanation?.correction,
            },
          },
        },
        forward_cycle: {
          score_number:
            jsonData[0]?.analyse?.content?.forwardCycle?.score_number,
          asset_id: jsonData[0]?.analyse?.content?.forwardCycle?.explanation,
          range_good: jsonData[0]?.analyse?.content?.forwardCycle?.range_good,
          range_okay: jsonData[0]?.analyse?.content?.forwardCycle?.range_okay,
          texts: {
            exercice:
              jsonData[0]?.analyse?.content?.forwardCycle?.texts?.exercice,
            explanation:
              jsonData[0]?.analyse?.content?.forwardCycle?.texts?.explanation,
            position:
              jsonData[0]?.analyse?.content?.forwardCycle?.texts?.position,
            score: jsonData[0]?.analyse?.content?.forwardCycle?.texts?.score,
            title: jsonData[0]?.analyse?.content?.forwardCycle?.texts?.title,
            recap: jsonData[0]?.analyse?.content?.forwardCycle?.texts?.recap,
            segmented_explanation: {
              coach_tips:
                jsonData[0]?.analyse?.content?.forwardCycle?.texts
                  ?.segmented_explanation?.coach_tips,
              consequences:
                jsonData[0]?.analyse?.content?.forwardCycle?.texts
                  ?.segmented_explanation?.consequences,
              correction:
                jsonData[0]?.analyse?.content?.forwardCycle?.texts
                  ?.segmented_explanation?.correction,
            },
          },
        },
        foot: {
          score_number: null,
          asset_id: null,
          foot_strike_pattern:
            jsonData[0]?.mean_foot_landing?.foot_strike_pattern,
          texts: {
            score: jsonData[0]?.analyse?.content?.foot?.texts?.score,
            title: jsonData[0]?.analyse?.content?.foot?.texts?.title,
            position: jsonData[0]?.analyse?.content?.foot?.texts?.position,
            overstride_title: null,
            overstride_explanation: null,
            foot_strike_explanation:
              jsonData[0]?.analyse?.content?.foot?.texts?.explanation,
            phases: null,
            exercice: jsonData[0]?.analyse?.content?.foot?.texts?.exercice,
          },
          range_good: null,
          range_okay: null,
        },
      },
      running_styles: jsonData[0]?.running_styles,
      metrics: {
        speed_meter_per_second: jsonData[0]?.metrics?.speed?.toFixed(2),
        step_length_meter: jsonData[0]?.metrics?.step_length?.toFixed(2),
        pace_min_per_km: jsonData[0]?.metrics?.pace?.toFixed(2),
        step_frequency_steps_per_min: jsonData[0]?.metrics?.steps_per_min,
        ground_contact_time_second:
          jsonData[0]?.metrics?.ground_contact_time?.toFixed(2),
        time_of_flight_second: jsonData[0]?.metrics?.time_of_flight?.toFixed(2),
        duty_factor_percent: jsonData[0]?.metrics?.duty_factor,
      },
      joint_angles: jsonData[0]?.joint_angles,
      foot_strike: {
        trunk_mean_angle:
          jsonData[0]?.mean_angles_at_foot_strike?.trunk_mean_angle,
        head_mean_angle:
          jsonData[0]?.mean_angles_at_foot_strike?.head_mean_angle,
        arm_mean_angle: jsonData[0]?.mean_angles_at_foot_strike?.arm_mean_angle,
        front_leg_mean_angle:
          jsonData[0]?.mean_angles_at_foot_strike?.front_leg_mean_angle,
        back_leg_mean_angle:
          jsonData[0]?.mean_angles_at_foot_strike?.back_leg_mean_angle,
      },
      overstride: {
        mean_angle: jsonData[0]?.mean_foot_landing?.overstride_value,
        score: jsonData[0]?.mean_foot_landing?.overstride_score,
      },
    };
  if (json_version === 5)
    return {
      cache_version: localJsonVersion,
      personalised: jsonData?.personalised,
      json_version: jsonData?.json_version,
      direction: jsonData?.direction,
      video_id: jsonData?.video_id,
      total_score: (
        jsonData?.biomechanical_analysis?.total_score * 100
      )?.toFixed(0),
      analysis_content: {
        head: {
          score_number:
            jsonData?.biomechanical_analysis?.analysis_content?.head
              ?.score_number,
          asset_id:
            jsonData?.biomechanical_analysis?.analysis_content?.head?.asset_id,
          range_good:
            jsonData?.biomechanical_analysis?.analysis_content?.head
              ?.range_good,
          range_okay:
            jsonData?.biomechanical_analysis?.analysis_content?.head
              ?.range_okay,
          texts: {
            exercice:
              jsonData?.biomechanical_analysis?.analysis_content?.head?.texts
                ?.exercice,
            explanation:
              jsonData?.biomechanical_analysis?.analysis_content?.head?.texts
                ?.explanation,
            position:
              jsonData?.biomechanical_analysis?.analysis_content?.head?.texts
                ?.position,
            score:
              jsonData?.biomechanical_analysis?.analysis_content?.head?.texts
                ?.score,
            title:
              jsonData?.biomechanical_analysis?.analysis_content?.head?.texts
                ?.title,
            recap:
              jsonData?.biomechanical_analysis?.analysis_content?.head?.texts
                ?.recap,
            segmented_explanation: {
              coach_tips:
                jsonData?.biomechanical_analysis?.analysis_content?.head?.texts
                  ?.segmented_explanation?.coach_tips,
              consequences:
                jsonData?.biomechanical_analysis?.analysis_content?.head?.texts
                  ?.segmented_explanation?.consequences,
              correction:
                jsonData?.biomechanical_analysis?.analysis_content?.head?.texts
                  ?.segmented_explanation?.correction,
            },
          },
        },
        trunk: {
          score_number:
            jsonData?.biomechanical_analysis?.analysis_content?.trunk
              ?.score_number,
          asset_id:
            jsonData?.biomechanical_analysis?.analysis_content?.trunk?.asset_id,
          range_good:
            jsonData?.biomechanical_analysis?.analysis_content?.trunk
              ?.range_good,
          range_okay:
            jsonData?.biomechanical_analysis?.analysis_content?.trunk
              ?.range_okay,
          texts: {
            exercice:
              jsonData?.biomechanical_analysis?.analysis_content?.trunk?.texts
                ?.exercice,
            explanation:
              jsonData?.biomechanical_analysis?.analysis_content?.trunk?.texts
                ?.explanation,
            position:
              jsonData?.biomechanical_analysis?.analysis_content?.trunk?.texts
                ?.position,
            score:
              jsonData?.biomechanical_analysis?.analysis_content?.trunk?.texts
                ?.score,
            title:
              jsonData?.biomechanical_analysis?.analysis_content?.trunk?.texts
                ?.title,
            recap:
              jsonData?.biomechanical_analysis?.analysis_content?.trunk?.texts
                ?.recap,
            segmented_explanation: {
              coach_tips:
                jsonData?.biomechanical_analysis?.analysis_content?.trunk?.texts
                  ?.segmented_explanation?.coach_tips,
              consequences:
                jsonData?.biomechanical_analysis?.analysis_content?.trunk?.texts
                  ?.segmented_explanation?.consequences,
              correction:
                jsonData?.biomechanical_analysis?.analysis_content?.trunk?.texts
                  ?.segmented_explanation?.correction,
            },
          },
        },
        arm: {
          score_number:
            jsonData?.biomechanical_analysis?.analysis_content?.arm
              ?.score_number,
          asset_id:
            jsonData?.biomechanical_analysis?.analysis_content?.arm?.asset_id,
          range_good:
            jsonData?.biomechanical_analysis?.analysis_content?.arm?.range_good,
          range_okay:
            jsonData?.biomechanical_analysis?.analysis_content?.arm?.range_okay,
          texts: {
            exercice:
              jsonData?.biomechanical_analysis?.analysis_content?.arm?.texts
                ?.exercice,
            explanation:
              jsonData?.biomechanical_analysis?.analysis_content?.arm?.texts
                ?.explanation,
            position:
              jsonData?.biomechanical_analysis?.analysis_content?.arm?.texts
                ?.position,
            score:
              jsonData?.biomechanical_analysis?.analysis_content?.arm?.texts
                ?.score,
            title:
              jsonData?.biomechanical_analysis?.analysis_content?.arm?.texts
                ?.title,
            recap:
              jsonData?.biomechanical_analysis?.analysis_content?.arm?.texts
                ?.recap,
            segmented_explanation: {
              coach_tips:
                jsonData?.biomechanical_analysis?.analysis_content?.arm?.texts
                  ?.segmented_explanation?.coach_tips,
              consequences:
                jsonData?.biomechanical_analysis?.analysis_content?.arm?.texts
                  ?.segmented_explanation?.consequences,
              correction:
                jsonData?.biomechanical_analysis?.analysis_content?.arm?.texts
                  ?.segmented_explanation?.correction,
            },
          },
        },
        back_cycle: {
          score_number:
            jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
              ?.score_number,
          asset_id:
            jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
              ?.asset_id,
          range_good:
            jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
              ?.range_good,
          range_okay:
            jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
              ?.range_okay,
          texts: {
            exercice:
              jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
                ?.texts?.exercice,
            explanation:
              jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
                ?.texts?.explanation,
            position:
              jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
                ?.texts?.position,
            score:
              jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
                ?.texts?.score,
            title:
              jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
                ?.texts?.title,
            recap:
              jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
                ?.texts?.recap,
            segmented_explanation: {
              coach_tips:
                jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
                  ?.texts?.segmented_explanation?.coach_tips,
              consequences:
                jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
                  ?.texts?.segmented_explanation?.consequences,
              correction:
                jsonData?.biomechanical_analysis?.analysis_content?.back_cycle
                  ?.texts?.segmented_explanation?.correction,
            },
          },
        },
        forward_cycle: {
          score_number:
            jsonData?.biomechanical_analysis?.analysis_content?.forward_cycle
              ?.score_number,
          asset_id:
            jsonData?.biomechanical_analysis?.analysis_content?.forward_cycle
              ?.asset_id,
          range_good:
            jsonData?.biomechanical_analysis?.analysis_content?.forward_cycle
              ?.range_good,
          range_okay:
            jsonData?.biomechanical_analysis?.analysis_content?.forward_cycle
              ?.range_okay,
          texts: {
            exercice:
              jsonData?.biomechanical_analysis?.analysis_content?.forward_cycle
                ?.texts?.exercice,
            explanation:
              jsonData?.biomechanical_analysis?.analysis_content?.forward_cycle
                ?.texts?.explanation,
            position:
              jsonData?.biomechanical_analysis?.analysis_content?.forward_cycle
                ?.texts?.position,
            score:
              jsonData?.biomechanical_analysis?.analysis_content?.forward_cycle
                ?.texts?.score,
            title:
              jsonData?.biomechanical_analysis?.analysis_content?.forward_cycle
                ?.texts?.title,
            recap:
              jsonData?.biomechanical_analysis?.analysis_content?.forward_cycle
                ?.texts?.recap,
            segmented_explanation: {
              coach_tips:
                jsonData?.biomechanical_analysis?.analysis_content
                  ?.forward_cycle?.texts?.segmented_explanation?.coach_tips,
              consequences:
                jsonData?.biomechanical_analysis?.analysis_content
                  ?.forward_cycle?.texts?.segmented_explanation?.consequences,
              correction:
                jsonData?.biomechanical_analysis?.analysis_content
                  ?.forward_cycle?.texts?.segmented_explanation?.correction,
            },
          },
        },
        foot: {
          score_number:
            jsonData?.biomechanical_analysis?.analysis_content?.foot
              ?.score_number,
          asset_id:
            jsonData?.biomechanical_analysis?.analysis_content?.foot?.asset_id,
          foot_strike_pattern:
            jsonData?.biomechanical_analysis?.analysis_content?.foot
              ?.foot_strike_pattern,
          texts: {
            score:
              jsonData?.biomechanical_analysis?.analysis_content?.foot?.texts
                ?.score,
            title:
              jsonData?.biomechanical_analysis?.analysis_content?.foot?.texts
                ?.title,
            position:
              jsonData?.biomechanical_analysis?.analysis_content?.foot?.texts
                ?.position,
            overstride_title:
              jsonData?.biomechanical_analysis?.analysis_content?.foot?.texts
                ?.overstride_title,
            overstride_explanation:
              jsonData?.biomechanical_analysis?.analysis_content?.foot?.texts
                ?.overstride_explanation,
            foot_strike_explanation:
              jsonData?.biomechanical_analysis?.analysis_content?.foot?.texts
                ?.foot_strike_explanation,
            phases: {
              impact:
                jsonData?.biomechanical_analysis?.analysis_content?.foot?.texts
                  ?.phases?.impact,
              amortisation:
                jsonData?.biomechanical_analysis?.analysis_content?.foot?.texts
                  ?.phases?.amortisation,
              propulsion:
                jsonData?.biomechanical_analysis?.analysis_content?.foot?.texts
                  ?.phases?.propulsion,
            },
            exercice:
              jsonData?.biomechanical_analysis?.analysis_content?.foot?.texts
                ?.exercice,
          },
          range_good: [-10, 5],
          range_okay: [5, 10],
        },
      },
      running_styles: jsonData?.biomechanical_analysis?.running_styles,
      metrics: {
        speed_meter_per_second:
          jsonData?.calculated_data?.metrics?.speed_meter_per_second?.toFixed(
            2,
          ),
        step_length_meter:
          jsonData?.calculated_data?.metrics?.step_length_meter?.toFixed(2),
        pace_min_per_km:
          jsonData?.calculated_data?.metrics?.pace_min_per_km?.toFixed(2),
        step_frequency_steps_per_min:
          jsonData?.calculated_data?.metrics?.step_frequency_steps_per_min,
        ground_contact_time_second:
          jsonData?.calculated_data?.metrics?.ground_contact_time_second?.toFixed(
            2,
          ),
        time_of_flight_second:
          jsonData?.calculated_data?.metrics?.time_of_flight_second?.toFixed(2),
        duty_factor_percent:
          jsonData?.calculated_data?.metrics?.duty_factor_percent,
      },
      joint_angles: jsonData?.calculated_data?.joint_angles,
      foot_strike: {
        trunk_mean_angle:
          jsonData?.calculated_data?.foot_landing?.foot_strike
            ?.trunk_mean_angle,
        head_mean_angle:
          jsonData?.calculated_data?.foot_landing?.foot_strike?.head_mean_angle,
        arm_mean_angle:
          jsonData?.calculated_data?.foot_landing?.foot_strike?.arm_mean_angle,
        front_leg_mean_angle:
          jsonData?.calculated_data?.foot_landing?.foot_strike
            ?.front_leg_mean_angle,
        back_leg_mean_angle:
          jsonData?.calculated_data?.foot_landing?.foot_strike
            ?.back_leg_mean_angle,
      },
      overstride: {
        mean_angle: jsonData?.calculated_data?.overstride?.mean_angle,
        score: jsonData?.calculated_data?.overstride?.score,
      },
    };
}

export function extractBackViewJson(jsonData, localJsonVersion) {
  const json_version = jsonData?.json_version;

  if (!json_version) {
    return jsonData;
  }

  if (json_version === 1) {
    const contentKey = checkUserPermission([PROFESSIONAL])
      ? 'professional_content'
      : 'vulgarized_content';
    return {
      json_version: 1,
      cache_version: localJsonVersion,
      video_id: jsonData?.video_id,
      personalised: jsonData?.personalised,
      direction: jsonData?.direction,
      date: jsonData?.date,
      language: jsonData?.language,
      analysis_content: {
        left_foot: {
          score_number:
            jsonData?.biomechanical_analysis?.analysis_content?.left_foot
              ?.score_number,
          texts: {
            explanation:
              jsonData?.biomechanical_analysis?.analysis_content?.left_foot
                ?.texts?.[contentKey]?.explanation,
            recap:
              jsonData?.biomechanical_analysis?.analysis_content?.left_foot
                ?.texts?.[contentKey]?.recap,
            segmented_explanation: {
              consequences:
                jsonData?.biomechanical_analysis?.analysis_content?.left_foot
                  ?.texts?.[contentKey]?.segmented_explanation?.consequences,
              correction:
                jsonData?.biomechanical_analysis?.analysis_content?.left_foot
                  ?.texts?.[contentKey]?.segmented_explanation?.correction,
            },
            position:
              jsonData?.biomechanical_analysis?.analysis_content?.left_foot
                ?.texts?.position,
            score:
              jsonData?.biomechanical_analysis?.analysis_content?.left_foot
                ?.texts?.score,
            title:
              jsonData?.biomechanical_analysis?.analysis_content?.left_foot
                ?.texts?.[contentKey]?.title,
            side: jsonData?.biomechanical_analysis?.analysis_content?.left_foot
              ?.texts?.side,
          },
          asset_id:
            jsonData?.biomechanical_analysis?.analysis_content?.left_foot
              ?.asset_id,
          range_good:
            jsonData?.biomechanical_analysis?.analysis_content?.left_foot
              ?.range_good,
          range_okay:
            jsonData?.biomechanical_analysis?.analysis_content?.left_foot
              ?.range_okay,
        },
        right_foot: {
          score_number:
            jsonData?.biomechanical_analysis?.analysis_content?.right_foot
              ?.score_number,
          texts: {
            explanation:
              jsonData?.biomechanical_analysis?.analysis_content?.right_foot
                ?.texts?.[contentKey]?.explanation,
            recap:
              jsonData?.biomechanical_analysis?.analysis_content?.right_foot
                ?.texts?.[contentKey]?.recap,
            segmented_explanation: {
              consequences:
                jsonData?.biomechanical_analysis?.analysis_content?.right_foot
                  ?.texts?.[contentKey]?.segmented_explanation?.consequences,
              correction:
                jsonData?.biomechanical_analysis?.analysis_content?.right_foot
                  ?.texts?.[contentKey]?.segmented_explanation?.correction,
            },
            position:
              jsonData?.biomechanical_analysis?.analysis_content?.right_foot
                ?.texts?.position,
            score:
              jsonData?.biomechanical_analysis?.analysis_content?.right_foot
                ?.texts?.score,
            title:
              jsonData?.biomechanical_analysis?.analysis_content?.right_foot
                ?.texts?.[contentKey]?.title,
            side: jsonData?.biomechanical_analysis?.analysis_content?.right_foot
              ?.texts?.side,
          },
          asset_id:
            jsonData?.biomechanical_analysis?.analysis_content?.right_foot
              ?.asset_id,
          range_good:
            jsonData?.biomechanical_analysis?.analysis_content?.right_foot
              ?.range_good,
          range_okay:
            jsonData?.biomechanical_analysis?.analysis_content?.right_foot
              ?.range_okay,
        },
      },
      gait_cycle: {
        stance: {
          left_mean_angle:
            jsonData?.calculated_data?.gait_cycle?.stance?.left_mean_angle,
          right_mean_angle:
            jsonData?.calculated_data?.gait_cycle?.stance?.right_mean_angle,
        },
      },
      total_score: jsonData?.biomechanical_analysis?.total_score * 100,
    };
  }
}

export async function getLocalStorageVersion() {
  try {
    const docRef = doc(firestore, APPSETTINGS_COLLECTION, 'local_storage');
    const docSnap = await getDoc(docRef);

    if (docSnap?.exists()) {
      return docSnap?.data();
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document! in getLocalStorageVersion');
      return;
    }
  } catch (error) {
    console.log('error in getLocalStorageVersion', error);
  }
}
