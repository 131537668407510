import React from 'react';
import TextComponent from 'components/atoms/TextComponent';
import { colors } from 'themes';

const OptionButton = ({ Icon, text, type, onClick }) => (
  <div
    className={`button button-${type}`}
    onClick={onClick}
    style={{ width: '100%' }}>
    <TextComponent text={text} sx={{ color: colors.WHITE, width: '100%', textAlign: 'center' }} />
    <Icon className="icon" />
  </div>
);

export default OptionButton;
