import TextComponent from "components/atoms/TextComponent";
import { BOLD, H5 } from "constant/constants";
import React from "react";
import { colors } from "themes";
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';



function DeleteModal({ textKey, setState, setEditDb, modalRef, type = null }) {

    return (
        <>
            <TextComponent
                text='Are you sure to delete this field?'
                variant={H5}
                weight={BOLD}
                sx={{ color: colors.WHITE, textAlign: 'center', marginBottom: '2vh' }}
            />
            <div className={`button button-delete`} onClick={async () => {
                setState((prevUserInfo) => {
                    const newUserInfo = { ...prevUserInfo };
                    const keys = textKey.split('-');
                    type && keys?.unshift(type);
                    let target = newUserInfo;
                    for (let i = 0; i < keys?.length - 1; i++) {
                        target = target[keys[i]];
                        if (!target) {
                            return newUserInfo;
                        }
                    }
                    const lastKey = keys[keys.length - 1];
                    delete target[lastKey];
                    return newUserInfo;
                });

                setEditDb(true);
                modalRef.current.setClose();

            }}>
                <TrashIcon className="button-icon" fill="white" />
                <TextComponent text={'Confirm delete'} sx={{ color: colors.WHITE }} />
            </div>
        </>
    )

}


export default DeleteModal;

