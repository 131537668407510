import TextComponent from "components/atoms/TextComponent";
import { ATHLETE, BOLD, H5 } from "constant/constants";
import React, { useEffect, useState } from "react";
import { colors } from "themes";
import { createTextObjectReusable } from "utils/utils";
import './styles/BackOfficeUsage.css';
import './styles/BackOffice.css';
import { getPromoCodePartner } from "services/promoCodeApi";
import { Timestamp } from "firebase/firestore";
import getUserLocale from "get-user-locale";

function BackOfficeUsage({ usage, modalRef, setUserInfo, setSidePanContent, sidePanRef, userType }) {

    const [promoCodeText, setPromoCodeText] = useState('');

    useEffect(() => {
        const fetchPromoCode = async () => {
            const promoCode = await getPromoCode();
            setPromoCodeText(promoCode);
        };

        fetchPromoCode();
    }, []);

    function getSubscriptionType() {
        if (usage?.yearly10)
            return 'yearly10'
        else if (usage?.yearly30)
            return 'yearly30'
        else if (usage?.yearlyPlan)
            return 'yearlyPlan'
        else if (usage?.quaterlyPlan)
            return 'quaterlyPlan'
        else if (usage?.monthly30)
            return 'monthly30'
        else if (usage?.monthly10)
            return 'monthly10'
        else if (usage?.monthlyPlan)
            return 'monthlyPlan'
        else
            return 'Not subscribed'
    }

    function handleClick() {
        setSidePanContent({ data: usage, type: 'usage' });
        sidePanRef.current.open();
    }

    async function getPromoCode() {
        if (!usage?.promoCode || usage?.promoCode?.length === 0) return 'No promo code used';
        else {
            let promoCodes = '';

            await Promise.all(
                usage?.promoCode?.map(async (code) => {
                    if (!code?.promoCode) return;
                    const partner = await getPromoCodePartner(code?.promoCode);
                    promoCodes += `${code?.promoCode} (${partner}), `;
                })
            );

            return promoCodes.slice(0, -2);
        }
    }

    function displayExpirationDate() {
        if (!usage?.subscriptionExpiresDate) return 'Not subscribed';
        if (usage?.subscriptionExpiresDate?.seconds) {
            return new Timestamp(usage?.subscriptionExpiresDate?.seconds, usage?.subscriptionExpiresDate?.nanoseconds).toDate().toLocaleDateString(getUserLocale());
        } else {
            return new Date(usage?.subscriptionExpiresDate)?.toLocaleDateString(getUserLocale());
        }
    }

    return (
        <div className="category-container" data-type="usage" onClick={handleClick}>
            <TextComponent
                text={'Usages'}
                variant={H5}
                weight={BOLD}
                sx={{ color: colors.WHITE, textAlign: 'center', marginBottom: '2vh' }} />
            <TextComponent
                multipleText={createTextObjectReusable(
                    `[[Credit]] : ${usage?.creditNumber}`,
                    { style: { color: colors.WHITE } },
                    {},
                    {
                        style: { color: colors.SECONDARY },
                        weight: BOLD,
                    },
                )} />
            <TextComponent
                multipleText={createTextObjectReusable(
                    `[[Subscription]] : ${getSubscriptionType()}`,
                    { style: { color: colors.WHITE } },
                    {},
                    {
                        style: { color: colors.SECONDARY },
                        weight: BOLD,
                    },
                )} />
            {userType !== ATHLETE && <TextComponent
                multipleText={createTextObjectReusable(
                    `[[Monthly credits]] : ${usage?.monthlyCreditNumber}`,
                    { style: { color: colors.WHITE } },
                    {},
                    {
                        style: { color: colors.SECONDARY },
                        weight: BOLD,
                    },
                )} />}
            <TextComponent
                multipleText={createTextObjectReusable(
                    `[[Subscription expiration date]] : ${displayExpirationDate()}`,
                    { style: { color: colors.WHITE } },
                    {},
                    {
                        style: { color: colors.SECONDARY },
                        weight: BOLD,
                    },
                )} />
            <TextComponent
                multipleText={createTextObjectReusable(
                    `[[Promo code]] : ${promoCodeText}`,
                    { style: { color: colors.WHITE } },
                    {},
                    {
                        style: { color: colors.SECONDARY },
                        weight: BOLD,
                    },
                )} />



        </div>
    )
}

export default BackOfficeUsage;