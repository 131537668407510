import React, { useEffect, useState } from "react";

import { Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";

import TextComponent from "components/atoms/TextComponent";
import LoadingComponent from "components/atoms/LoadingComponent";

import { translate } from "services/remoteConfig";

import { PRONATION } from "constant/constants";

import { colors } from "themes";

function AthleteGraph({ analysisListDatas, loading }) {
    const runningAnalysis = analysisListDatas?.filter(obj => obj?.analysis_type !== PRONATION);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (analysisListDatas?.length === 0) return;
        const contentArray = runningAnalysis?.map(obj => {
            const date = new Date(obj?.date);
            const day = date?.getDate();
            const month = date?.getMonth() + 1;
            const year = date?.getFullYear();

            const formattedDay = day < 10 ? `0${day}` : day;
            const formattedMonth = month < 10 ? `0${month}` : month;

            const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;


            return { date: formattedDate, score: (obj?.analysis_content?.total_score * 100)?.toFixed(0) };
        }).filter(content => content !== null);

        setData(contentArray?.reverse());
    }, [analysisListDatas]);

    const customTickFormatter = (value) => {
        const dateParts = value?.split('/');
        return dateParts[0] + '/' + dateParts[1];
    };

    return (
        <div style={styles.container}>
            <TextComponent text={translate('AnalysisScore')} sx={styles.title} />
            {analysisListDatas?.length === 0 ?
                <div style={styles.noVideo}>
                    <TextComponent text={translate('NoVideos')} sx={styles.title} />
                </div>
                :
                loading ?
                    <div style={styles.loading}>
                        <LoadingComponent />
                    </div>
                    :
                    <div style={styles.chart}>
                        <ResponsiveContainer width="90%" height="90%">
                            <LineChart data={data}>
                                <XAxis dataKey="date" tickFormatter={customTickFormatter} />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey="score" stroke={colors.SECONDARY} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
            }
        </div>
    );
}

const styles = {
    chart: {
        width: '100%',
        height: '100%',
        overflow: 'auto'
    },
    container: {
        width: '100%',
        backgroundColor: 'black',
        borderRadius: 10,
        height: '60%'
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90%'
    },
    noVideo: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        color: colors.TEXT_GRAY,
        marginLeft: '1vw',
        marginTop: '1vh',
        marginBottom: '1vh',
        fontSize: '1.2vw'
    }
};

export default AthleteGraph;
