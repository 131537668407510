import React from 'react';

import { ReactComponent as PronationIcon } from 'assets/svg/pronation.svg';
import { ReactComponent as SupinationIcon } from 'assets/svg/supination.svg';
import { ReactComponent as NeutralIcon } from 'assets/svg/neutral.svg';
import { translate } from 'services/remoteConfig';
import { colors } from 'themes';
import { BODY_1, BOLD, H1, H5, H6, LIGHT } from 'constant/constants';
import TextComponent from 'components/atoms/TextComponent';
import { score_colors } from '../constants/analysisListConstants';

function PronationAnalysisBottomCardUpdated({ analysisData, locked }) {
  function getScoreIcon(score_text, type, direction) {
    if (!type) return <></>;
    const itemCopy = type?.toLowerCase();

    if (itemCopy.includes('supination') && score_text) {
      return (
        <SupinationIcon
          width="40"
          height="40"
          className="aCenter"
          fill={
            score_text === 'score_good'
              ? score_colors.score_good.stroke
              : score_text === 'score_okay'
                ? score_colors.score_okay.stroke
                : score_colors.score_bad.stroke
          }
          strokeWidth={8}
          stroke={
            score_text === 'score_good'
              ? score_colors.score_good.stroke
              : score_text === 'score_okay'
                ? score_colors.score_okay.stroke
                : score_colors.score_bad.stroke
          }
        />
      );
    }
    if (itemCopy.includes('pronation') && score_text) {
      return (
        <PronationIcon
          width="40"
          height="40"
          className="aCenter"
          fill={
            score_text === 'score_good'
              ? score_colors.score_good.stroke
              : score_text === 'score_okay'
                ? score_colors.score_okay.stroke
                : score_colors.score_bad.stroke
          }
          stroke={
            score_text === 'score_good'
              ? score_colors.score_good.stroke
              : score_text === 'score_okay'
                ? score_colors.score_okay.stroke
                : score_colors.score_bad.stroke
          }
          strokeWidth={8}
        />
      );
    }
    if (itemCopy.includes('neutral') && score_text) {
      return (
        <NeutralIcon
          width="40"
          height="40"
          className="aCenter"
          fill={
            score_text === 'score_good'
              ? score_colors.score_good.stroke
              : score_text === 'score_okay'
                ? score_colors.score_okay.stroke
                : score_colors.score_bad.stroke
          }
          stroke={
            score_text === 'score_good'
              ? score_colors.score_good.stroke
              : score_text === 'score_okay'
                ? score_colors.score_okay.stroke
                : score_colors.score_bad.stroke
          }
          strokeWidth={8}
        />
      );
    }
  }

  if (locked) {
    return (
      <div style={styles.locked}>
        <TextComponent
          variant={H5}
          sx={{ color: colors.YELLOW }}
          text={translate('FreeAnalysis')}
        />
      </div>
    );
  }

  return (
    <div style={styles.cardSubmenu}>
      <div style={styles.footContainer}>
        <TextComponent
          text={analysisData?.analysis_content?.left_foot?.title}
          variant={BODY_1}
          weight={LIGHT}
        />
        <div style={styles.leftFoot}>
          {getScoreIcon(
            analysisData?.analysis_content?.left_foot?.score,
            analysisData?.analysis_content?.left_foot?.position,
            true,
          )}
        </div>
      </div>
      <div style={{ ...styles.footContainer, ...styles.jStart }}>
        <div style={styles.rightFoot}>
          {getScoreIcon(
            analysisData?.analysis_content?.right_foot?.score,
            analysisData?.analysis_content?.right_foot?.position,
          )}
        </div>
        <TextComponent
          text={analysisData?.analysis_content?.right_foot?.title}
          variant={BODY_1}
          weight={LIGHT}
        />
      </div>
    </div>
  );
}

const styles = {
  aCenter: {
    alignSelf: 'center',
  },
  cardSubmenu: {
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
  },
  footContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '5px',
    verticalAlign: 'middle',
  },
  footTitle: {
    alignSelf: 'center',
    justifyContent: 'center',
    color: colors.TEXT_WHITE, // Replace with your color variable
  },
  jStart: {
    justifyContent: 'flex-start',
  },
  locked: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  leftFoot: {
    justifyContent: 'center',
    transform: 'scaleX(-1)',
  },
  rightFoot: {
    justifyContent: 'center',
  },
};

export default PronationAnalysisBottomCardUpdated;
