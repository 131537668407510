import React, {useEffect, useState} from 'react';
import {colors} from 'themes';
import Ruler from '../analysisRightPanelUpdated/Ruler';
import MultipleButton from 'components/molecules/MultipleButton';
import {translate} from 'services/remoteConfig';
import BodyContainerContentRecapDisplay from '../analysisRightPanelUpdated/BodyContainerContentRecapDisplay';
import TextComponent from 'components/atoms/TextComponent';
import {BODY_1, BODY_2, BODY_3, BOLD, H5, H6} from 'constant/constants';
import CardWithRoundedIcon from 'components/molecules/CardWithRoundedIcon';
import {ReactComponent as Advice} from 'assets/svg/advice.svg';

const analysisImages = {
  neutralR: require('assets/images/pronationAnalysisImages/neutralR.png'),
  pronationR: require('assets/images/pronationAnalysisImages/pronationR.png'),
  supinationR: require('assets/images/pronationAnalysisImages/supinationR.png'),
  oversupinationR: require('assets/images/pronationAnalysisImages/overSupinationR.png'),
  overpronationR: require('assets/images/pronationAnalysisImages/overPronationR.png'),

  neutralL: require('assets/images/pronationAnalysisImages/neutralL.png'),
  pronationL: require('assets/images/pronationAnalysisImages/pronationL.png'),
  supinationL: require('assets/images/pronationAnalysisImages/supinationL.png'),
  oversupinationL: require('assets/images/pronationAnalysisImages/overSupinationL.png'),
  overpronationL: require('assets/images/pronationAnalysisImages/overPronationL.png'),
};

function PronationAnalysisPanelUpdated({analysisData, angle, side}) {
  const [phase, setPhase] = useState('Recap');
  const [phaseContent, setPhaseContent] = useState(null);
  console.log(analysisData);
  const config = [
    {
      title: 'Recap',
      recap: analysisData?.texts?.recap,
      score: analysisData?.texts?.score,
    },
    {
      title: translate('Consequences'),
      text: analysisData?.texts?.segmented_explanation?.consequences,
      score: analysisData?.texts?.score,
    },
    analysisData?.texts?.segmented_explanation?.correction
      ? {
          title: translate('Correction'),
          text: analysisData?.texts?.segmented_explanation?.correction,
          score: analysisData?.score,
        }
      : null,
  ].filter(Boolean);

  useEffect(() => {
    setPhaseContent(config?.find(item => item?.title === phase));
  }, [phase, analysisData]);

  return (
    <div style={styles.footContainer}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '3rem',
          marginTop: 20,
        }}>
        <TextComponent
          text={translate(analysisData?.texts?.side)}
          variant={BODY_2}
          sx={{color: colors?.WHITE_OPACITY_60}}
        />
        <div style={styles.footIcon}>
          <img alt="" src={analysisImages[analysisData?.asset_id]} />
        </div>
        <TextComponent text={analysisData?.texts?.title} variant={H6} />
      </div>
      <TextComponent
        text={translate('MeanFootAngleStance')}
        sx={{marginBottom: 3}}
        variant={BODY_2}
      />
      <div style={{marginBottom: 40}}>
        <Ruler
          angle={angle}
          rangeGood={analysisData?.range_good}
          rangeOkay={analysisData?.range_okay}
        />
      </div>
      <div
        style={{
          marginBottom: 40,
          width: '95%',
          margin: 'auto',
          alignSelf: 'center',
        }}>
        <MultipleButton
          config={config}
          navigation={phase}
          setNavigation={setPhase}
        />
        <BodyContainerContentRecapDisplay
          item={phaseContent}
          multipleLines={true}
        />
        <div
          style={{
            marginTop: 40,
          }}>
          <CardWithRoundedIcon icon={Advice}>
            <TextComponent
              text={translate('Definition')}
              weight={BOLD}
              style={styles.definition}
            />
            <TextComponent
              text={analysisData?.texts?.explanation || ''}
              variant={BODY_2}
              style={styles.explanation}
            />
            /
          </CardWithRoundedIcon>
        </div>
      </div>
    </div>
  );
}
const styles = {
  footContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    flex: 1,
  },
  footIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
};
export default PronationAnalysisPanelUpdated;
