import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';
import { useSelector } from 'react-redux';
import { Fade, Modal } from '@mui/material';

import PronationAnalysis from './components/pronationAnalysis/PronationAnalysis';
import RunningAnalysis from './RunningAnalysis';
import TextComponent from 'components/atoms/TextComponent';
import ButtonComponent from 'components/atoms/ButtonComponent';

import { translate } from 'services/remoteConfig';
import { removeCredit } from 'services/usageApi';
import { getDowloadUrlFromGsUrl, updateAnalysis } from 'services/analysisApi';
import { mpTrack } from 'services/mixpanel';

import { useUsageChanges } from 'hooks/useUsageChanges';

import { ORANGE, PRONATION } from 'constant/constants';

import { colors } from 'themes';

import fireworks from 'assets/animations/fireworks.json';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import { useAnalysisListener } from 'hooks/useAnalysisChanges';
import { analysisListData } from 'slices/analysisSlice';
import { fetchData } from 'services/jsonApi';
import { extractBackViewJson, extractSideViewJson } from 'utils/jsonAdaptater';
import { store } from 'store';
import LoadingComponent from 'components/atoms/LoadingComponent';
import { updateAnalysisInProject } from 'services/projectApi';
import PDFSelectionList from './components/PDFSelectionList';
import { fetchJsonData } from 'utils/analysis';

function Analysis() {
  useUsageChanges();

  const location = useLocation();
  const analysisId = location?.state?.analysis?.data?.uploadVideoId;

  const { analysis } = useAnalysisListener(analysisId, location?.state?.analysis?.data?.locked);

  const usageSlice = useSelector(state => state.usage);

  const modalRef = useRef();
  const [open, setOpen] = useState(false);


  const [success, setSuccess] = useState(false);
  const [locked, setLocked] = useState(null);
  const [trackingEvent, setTrackingEvent] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const { jsonData, loading } = await fetchJsonData(location?.state?.analysis);
      setJsonData(jsonData);
      setLoading(loading);
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    if (analysis?.data?.locked !== undefined) setLocked(analysis?.data?.locked);
  }, [analysis?.data?.locked]);

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(modalRef, () => ({
    getOpen() {
      return open;
    },
    open(modalContent, trackingEvent = null) {
      setTrackingEvent(trackingEvent);
      if (modalContent === 'generatePDF') {
        setModalContent(<PDFSelectionList
          jsonData={jsonData}
          setOpen={setOpen}
          analysisType={location?.state?.analysis?.analysis_type}
        />);
      } else if (modalContent === 'locked') {
        setModalContent(onLockedPress());
      } else {
        setModalContent(modalContent);
      }
      setOpen(true);
    },
    setClose() {
      setOpen(false);
    },
  }));

  function unlockAnalysis() {
    //setLoading(true);
    //remove a credit from user's account and then update analysis with locked = false
    removeCredit();
    const timeoutId = setTimeout(() => {
      mpTrack('Web App' + trackingEvent + 'Unlocked');
      setSuccess(true);
      setLoading(false);
      setLocked(false);
      updateAnalysis({ data: { locked: false } }, analysis?.data?.uploadVideoId);
      updateAnalysisInProject(
        { data: { locked: false } },
        analysis?.data?.uploadVideoId,
      ).then(() => {
        setTimeout(() => {
          handleClose();
        }, 2000);
      });
    }, 1500);
    return () => clearTimeout(timeoutId);
  }

  function onLockedPress() {
    if (
      usageSlice?.creditNumber > 0 ||
      (usageSlice?.monthlyPlan === true &&
        usageSlice?.monthlyCreditNumber > 0) ||
      usageSlice?.yearlyPlan === true ||
      loading === true ||
      success === true
    ) {
      return (
        <>
          <TextComponent
            text={translate('UnlockWithCredit')}
            sx={styles.title}
          />
          {success === true && !loading ? (
            <>
              <Lottie animationData={fireworks} style={styles.fireworks} />
              <TextComponent
                text={translate('CongratsUnlock')}
                sx={styles.congratsUnlock}
              />
            </>
          ) : (
            <ButtonComponent
              onClick={unlockAnalysis}
              loading={loading}
              type={ORANGE}
              title={translate('Unlock')}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          <TextComponent
            text={
              usageSlice?.monthlyPlan === true &&
                usageSlice?.monthlyCreditNumber === 0
                ? translate('OutOfCredits')
                : translate('FreemiumFeatureOnly')
            }
            sx={styles.title}
          />
          <TextComponent
            text={translate('ContactChat')}
            sx={styles.contactChat}
          />
        </>
      );
    }
  }
  function renderAnalysis() {
    if (location?.state?.analysis?.analysis_type === 'pronation' && location?.state?.analysis) {
      return (
        <PronationAnalysis
          data={jsonData}
          locked={locked}
          modalRef={modalRef}
          onLockedPress={onLockedPress}
        />
      );
    } else if (Object.entries(location?.state?.analysis).length > 0) {
      return (
        <RunningAnalysis
          jsonData={jsonData}
          locked={locked}
          modalRef={modalRef}
          orientation={location?.state?.analysis?.video?.orientation}
          onLockedPress={onLockedPress}
        />
      );
    }
  }

  if (loading)
    return (
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          display: 'flex',
        }}>
        <LoadingComponent size={125} />
      </div>
    );

  return (
    <div>
      {renderAnalysis()}

      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            style: { backgroundColor: 'transparent' },
          },
        }}
        sx={styles.modal}>
        <Fade in={open}>
          <div style={styles.container}>
            <div
              style={styles.closeCross}
              onClick={event => {
                event?.preventDefault();
                event?.stopPropagation();
                handleClose();
              }}>
              <Close stroke="white" width={50} height={50} />
            </div>
            {modalContent}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const styles = {
  button: {
    marginBottom: '2vh',
  },

  closeCross: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
  },
  congratsUnlock: {
    color: colors.VALID_IMAGE,
    fontSize: '1.2vw',
    marginTop: '2vh',
  },
  contactChat: {
    color: colors.SECONDARY,
    fontSize: '0.8vw',
    textAlign: 'center',
  },

  fireworks: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
  container: {
    backgroundColor: colors.BLACK,
    position: 'relative',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2vh',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: colors.WHITE,
    margin: '2vh',
    fontSize: '1.2vw',
    width: '90%',
  },
};

export default Analysis;
