import TextComponent from "components/atoms/TextComponent";
import React from "react";
import { colors } from "themes";
import { ReactComponent as PenIcon } from 'assets/svg/pen.svg';
import TextInput from "components/atoms/TextInput";
import { BOLD, H5 } from "constant/constants";


function EditModal({ textKey, path, type = null, value, modalRef, setState, setEditDb }) {
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '2vh' }}>
                <TextComponent
                    text={textKey}
                    variant={H5}
                    weight={BOLD}
                    sx={{ color: colors.WHITE, }}
                />
                <TextComponent
                    text={'='}
                    variant={H5}
                    sx={{ color: colors.WHITE, marginLeft: '1vw', marginRight: '1vw' }}
                />

                <TextInput
                    value={value}
                    onChange={(e) => { modalRef.current.setEditField(e.target.value) }}
                />
            </div>

            <div className={`button button-edit`} onClick={async () => {
                setState((prev) => {
                    const newState = { ...prev };
                    const keys = path.split('-');
                    type && keys?.unshift(type);
                    let target = newState;
                    for (let i = 0; i < keys?.length - 1; i++) {
                        target = target[keys[i]];
                        if (!target) {
                            return newState;
                        }
                    }
                    const lastKey = keys[keys.length - 1];
                    const editField = modalRef.current.getEditField();
                    if (typeof value === 'string') {
                        target[lastKey] = String(editField); // Conversion explicite si nécessaire
                    } else if (typeof value === 'number') {
                        target[lastKey] = Number(editField); // Conversion en nombre si nécessaire
                    } else if (typeof value === 'boolean') {
                        target[lastKey] = String(editField).toLowerCase() === 'true'; // Conversion en booléen si nécessaire
                    } else {
                        target[lastKey] = editField; // Pour les objets ou autres types
                    }
                    return newState;
                });

                setEditDb(true);
                modalRef.current.setClose();

            }}>
                <PenIcon className="button-icon" fill="white" />
                <TextComponent text={'Edit'} sx={{ color: colors.WHITE }} />
            </div>
        </>
    )

}

export default EditModal;