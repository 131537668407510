import React, { useEffect } from 'react';

import VideoComponent from '../VideoComponent';
import PronationMetricsPanel from './PronationMetricsPanel';
import { mpTrack } from 'services/mixpanel';
import TextComponent from 'components/atoms/TextComponent';
import { translate } from 'services/remoteConfig';
import { BLUE_BORDER, H6 } from 'constant/constants';
import { ReactComponent as Lock } from 'assets/svg/lock.svg';
import { colors } from 'themes';
import PronationAnalysisPanelUpdated from './PronationAnalysisPanelUpdated';
import ButtonComponent from 'components/atoms/ButtonComponent';

export default function PronationAnalysis({ data, locked, modalRef, onLockedPress, shared = false }) {
  useEffect(() => {
    mpTrack('Web App Pronnation Analysis Viewed');
  }, []);

  function handleExportPDF(locked) {
    if (locked) {
      modalRef?.current?.open('locked', 'generatePDF');
    } else {
      mpTrack('Web App Export PDF button Clicked');
      modalRef?.current?.open('generatePDF');
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.videoContainer}>
        <VideoComponent video={data?.videoUrl} />
        {data?.json_version > 0 && !shared && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonComponent
              type={BLUE_BORDER}
              title={translate('ExportToPDF')}
              onClick={() => handleExportPDF(locked)}
              sx={{ height: '5vh', marginTop: '2vh', marginBottom: '2vh' }}
            />
          </div>
        )}
      </div>
      <div style={styles.metricsContainer}>
        <div
          style={{
            ...styles.metricsSubContainer,
            ...(locked ? styles.blur : null),
          }}>
          {data?.json_version > 0 ? (
            <>
              <PronationAnalysisPanelUpdated
                analysisData={data?.analysis_content?.left_foot}
                angle={data?.gait_cycle?.stance?.left_mean_angle}
              />
              <div
                style={{
                  width: 2,
                  height: '100%',
                  backgroundColor: colors.WHITE_OPACITY_10,
                  marginTop: 20,
                  borderRadius: 1,
                }}
              />
              <PronationAnalysisPanelUpdated
                analysisData={data?.analysis_content?.right_foot}
                angle={data?.gait_cycle?.stance?.right_mean_angle}
              />
            </>
          ) : (
            <>
              <PronationMetricsPanel
                posture={data?.left_foot?.posture}
                meanAngle={data?.left_foot?.mean_angle}
                angles_list={data?.left_foot?.angles_during_footlanded}
                side={'left'}
              />
              <PronationMetricsPanel
                posture={data?.right_foot?.posture}
                meanAngle={data?.right_foot?.mean_angle}
                angles_list={data?.right_foot?.angles_during_footlanded}
                side={'right'}
              />
            </>
          )}
        </div>
      </div>
      {locked ? (
        <div
          style={styles.lockedContainer}
          onClick={() => modalRef?.current?.open('locked')}>
          <Lock height={20} width={20} />
          <TextComponent
            text={translate('Unlock')}
            variant={H6}
            sx={styles.textUnlock}
          />
        </div>
      ) : null}
    </div>
  );
}
const styles = {
  blur: {
    filter: 'blur(30px)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  metricsContainer: {
    display: 'flex',
    flex: 1,
    bacgroundColor: 'red',
    flexDirection: 'column',
  },
  metricsSubContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    color: '#FF884D',
    fontWeight: 'light',
  },
  videoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    height: '90vh',
  },
  warningIcon: {
    display: 'flex',
  },
  lockedContainer: {
    display: 'flex',
    flexDirection: 'row',
    top: '15%',
    left: '55%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    cursor: 'pointer',
  },
  textUnlock: {
    color: colors.YELLOW,
    marginLeft: '1vh',
  },
};
