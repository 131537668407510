import React, { useEffect, useState } from "react";

import TextComponent from "components/atoms/TextComponent";
import GradientScoreSystem from "screen/analysis/AnalysisListCard/components/GradientScoreSystem";
import RoundedScoreMeter from "screen/analysis/AnalysisListCard/components/RoundedScoreMeter";

import { checkUserPermission, getAverageScore } from "utils/utils";

import { colors } from "themes";
import { ATHLETE } from "constant/constants";
import { translate } from "services/remoteConfig";

function RecapResultsCard({ data }) {
    const [average, setAverage] = useState(null);
    const [bestSegment, setBestSegment] = useState(null);
    const [worstSegment, setWorstSegment] = useState(null);


    useEffect(() => {
        if (data?.length === 0) return;

        const { averageScoreArray, contentArray } = fetchData(data);

        const sum = averageScoreArray?.reduce((acc, currentValue) =>
            Number(acc) + Number(currentValue), 0);
        setAverage((sum / averageScoreArray?.length).toFixed(0));

        const means = excludeFootKey(contentArray);
        const bestMean = Object.keys(means)?.reduce((best, key) => means[key] > means[best] ? key : best);
        const worstMean = Object.keys(means)?.reduce((worst, key) => means[key] < means[worst] ? key : worst);

        setBestSegment({ value: means[bestMean], name: bestMean });
        setWorstSegment({ value: means[worstMean], name: worstMean });
    }, [data]);

    function fetchData(data) {
        const contentArray = [];
        const averageScoreArray = [];
        data?.forEach(obj => {
            if (obj?.json_version > 2) {
                averageScoreArray?.push(obj?.analysis_content?.total_score * 100);
                const contentWithoutTotalScore = { ...obj?.analysis_content };
                delete contentWithoutTotalScore.total_score;
                contentArray?.push(contentWithoutTotalScore);
            }
        });
        return { averageScoreArray, contentArray }
    }

    function excludeFootKey(array) {
        if (array?.length === 0 || !array) return null;
        return Object.keys(array[0])?.reduce((acc, key) => {
            if (key !== 'foot') {
                acc[key] = array?.reduce((total, curr) => total + (curr[key]?.score_number || 0), 0) / array?.length;
            }
            return acc;
        }, {})
    }

    if (data?.length === 0) return null;

    return (
        <div style={styles.card}>
            <TextComponent text={checkUserPermission([ATHLETE]) ? translate('AnalysisAverageScore') : translate('AthletesAverageScore')} sx={styles.scoreTitle} />

            <GradientScoreSystem scoreNumber={average} />
            <div style={styles.segmentsContainer}>
                <div style={styles.bestSegment}>
                    <TextComponent text={translate('StrongPoint')} sx={styles.fs08} />
                    <RoundedScoreMeter scoreNumber={bestSegment?.value} item={bestSegment?.name} size={60} />
                </div>
                <div style={styles.worstSegment}>
                    <TextComponent text={translate('WeakPoint')} sx={styles.fs08} />
                    <RoundedScoreMeter scoreNumber={worstSegment?.value} item={worstSegment?.name} size={60} />
                </div>
            </div>


        </div>
    )

}

const styles = {
    bestSegment: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRight: '2px solid grey',
        width: '100%'
    },
    card: {
        width: '30%',
        height: '90%',
        backgroundColor: colors.APP_BACKGROUND,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    fs08: {
        fontSize: '0.8vw'
    },
    scoreTitle: {
        fontSize: '1vw',
        marginBottom: '1vh',
        marginLeft: '1vw'

    },
    segmentsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '2vh'
    },
    worstSegment: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
}

export default RecapResultsCard;