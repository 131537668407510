import React from 'react';
import CompareAnalysisSegments from './CompareAnalysisSegments';
import { translate } from 'services/remoteConfig';
import ButtonComponent from 'components/atoms/ButtonComponent';
import { BLUE_BORDER, ORANGE } from 'constant/constants';
import PronationAnalysisBottomCardUpdated from 'screen/analysis/AnalysisListCard/components/PronationAnalysisBottomCardUpdated';
import PronationAnalysisBottomCard from 'screen/analysis/AnalysisListCard/components/PronationAnalysisBottomCard';

function CompareAnalysisScreen({
  analysisData,
  handleVideoFlip,
  handleAnalysisPressed,
  portraits,
  backView,
}) {
  const RenderAnalysisContent = () => {
    if (backView) {
      if (analysisData?.json_version > 0) {
        return (
          <div style={{ marginTop: 20 }}>
            <PronationAnalysisBottomCardUpdated
              analysisData={analysisData}
              locked={false}
            />
          </div>
        );
      } else {
        return (
          <div style={{ marginTop: 20 }}>
            <PronationAnalysisBottomCard
              analysisData={analysisData}
              locked={false}
            />
          </div>
        );
      }
    }
    return (
      <CompareAnalysisSegments
        analysisData={analysisData?.analysis_content}
        portraits={portraits}
      />
    );
  };
  return (
    <>
      <RenderAnalysisContent />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 40,
          alignItems: 'center',
          marginBottom: 20,
        }}>
        <ButtonComponent
          type={BLUE_BORDER}
          title={translate('Flip')}
          onClick={handleVideoFlip}
        />
        <ButtonComponent
          type={ORANGE}
          title={translate('GoToAnalysis')}
          onClick={handleAnalysisPressed}
          sx={{ marginLeft: '1vw' }}
        />
      </div>
    </>
  );
}

export default CompareAnalysisScreen;
