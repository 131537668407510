import React from 'react';

import { ReactComponent as Checked } from 'assets/svg/checkMarkOrange.svg';

import { RUNNING } from 'constant/constants';

import { colors } from 'themes';

function CompareAnalysisButton({
  analysis,
  analysisSelected,
  disabled = false,
}) {
  if (analysis?.data?.locked === true || disabled) return <></>;
  return (
    <div className="share-icon-container" >
      <div
        style={{
          alignItems: 'center',
          backgroundColor: analysisSelected ? colors.SECONDARY : '',
          border: analysisSelected
            ? `5px solid ${colors.SECONDARY}`
            : '5px solid #00000033',
          borderRadius: '100%',
          display: 'flex',
          height: '30px',
          justifyContent: 'center',
          width: '30px',
        }}>
        {analysisSelected ? <Checked height={50} width={50} /> : null}
      </div>
    </div>
  );
}

export default CompareAnalysisButton;
