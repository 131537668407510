import React from 'react';

import AnalysisCardTopContent from '../CardComponent/AnalysisCardTopContent';
import TextComponent from 'components/atoms/TextComponent';

import { mpTrack } from 'services/mixpanel';

import { BODY_2 } from 'constant/constants';

import './styles/AnalysisDetailCard.css';
import { isMobile } from 'react-device-detect';
import { translate } from 'services/remoteConfig';
import AnalysisFootCardTopContent from '../CardComponent/AnalysisFootCardTopContent';

function AnalysisCardContent({
  cardData,
  setAnalysisData,
  overstrideData,
  locked,
  modalRef,
}) {

  return (
    <div
      className="analysis-detail-card-container"
      onClick={() => {
        if (locked) {
          mpTrack('Web App Analysis Card Locked Button Clicked');
          modalRef?.current?.open('locked', 'analysisCard');
        } else {
          mpTrack('Web App Analysis Position Clicked', {
            'Analysis Item': cardData?.type,
          });
        }

        setAnalysisData(
          locked ? { ...cardData, locked: true } : { ...cardData, locked: false },
        );
      }}>
      <div style={{ ...{ padding: 8 }, ...(locked ? styles.blur : null) }}>
        {cardData?.type === 'RightAnkle' ? (
          <AnalysisFootCardTopContent
            cardData={cardData}
            overstrideData={overstrideData}
          />
        ) : (
          <AnalysisCardTopContent
            scoreNumber={cardData?.score_number}
            scoreText={cardData?.score}
            item={cardData?.type}
          />
        )}

        <div style={{ marginTop: 20, marginLeft: 10 }}>
          <TextComponent
            text={cardData?.title}
            sx={{ color: '#737373', fontSize: '1vw' }}
          />
          <TextComponent
            text={cardData?.text1}
            sx={{ marginTop: 0.5, owerflowWrap: 'break-word', fontSize: '1.2vw' }}
          />
          {isMobile && (
            <TextComponent
              text={translate('ViewMore')}
              sx={{ marginTop: 0.5, owerflowWrap: 'break-word', float: 'right' }}
              variant={BODY_2}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const styles = {
  blur: {
    filter: 'blur(30px)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
};

export default AnalysisCardContent;
