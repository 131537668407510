import React from 'react'

import { ReactComponent as ValidateIcon } from 'assets/svg/checkMarkColored.svg';

import { getAnalysisItems } from 'constant/pdfConstants';

import { mpTrack } from 'services/mixpanel';
import { translate } from 'services/remoteConfig';

import { determineInvalidElementToExport } from 'utils/utils';

import './styles/PDFSelectionList.css';



function PDFCategoryData({ selectedButtons, setSelectedButtons, jsonData, analysisType }) {
    const PDFItems = getAnalysisItems(analysisType);


    const handleItemClick = (category, item) => {
        setSelectedButtons(prevState => {
            const newSelectedButtons = { ...prevState };
            if (newSelectedButtons[category]?.includes(item)) {
                newSelectedButtons[category] = newSelectedButtons[category]?.filter(i => i !== item);
                mpTrack('Web App PDF Unselect ' + item);
            } else {
                newSelectedButtons[category] = [...(newSelectedButtons[category] || []), item];
                mpTrack('Web App PDF Select ' + item);
            }
            return newSelectedButtons;
        });
    };


    const handleCategoryClick = (category) => {
        const categoryItems = PDFItems?.find(cat => cat?.category === category)?.items;
        const filteredItems = Object?.values(categoryItems)?.filter(key =>
            !determineInvalidElementToExport(jsonData, category, key)
        );

        setSelectedButtons(prevState => {
            const newSelectedButtons = { ...prevState };
            if (newSelectedButtons[category] && newSelectedButtons[category]?.length === filteredItems?.length) {
                newSelectedButtons[category] = [];
                mpTrack('Web App PDF Unselect All ' + category);
            } else {
                newSelectedButtons[category] = filteredItems || [];
                mpTrack('Web App PDF Select All ' + category);
            }
            return newSelectedButtons;
        });
    };

    return (
        <div className="categoryContainer">
            {PDFItems?.map((categoryData, index) => (
                <div key={index} className="categoryColumn">
                    <div className="category">
                        <div
                            className={`itemButton categoryButton ${selectedButtons[categoryData?.category] && selectedButtons[categoryData?.category]?.length === Object.entries(categoryData?.items)?.filter(key => !determineInvalidElementToExport(jsonData, categoryData?.category, key))?.length && selectedButtons[categoryData?.category]?.length !== 0 ? 'selected' : ''}`}
                            onClick={() =>
                                handleCategoryClick(categoryData?.category)
                            }
                        >
                            {translate(categoryData?.category)}
                        </div>
                        <div className="category-items">
                            {Object.entries(categoryData?.items)?.map(([key, value], idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className={`itemButton ${selectedButtons[categoryData?.category]?.includes(value) ? 'selected' : determineInvalidElementToExport(jsonData, categoryData?.category, value) ? 'disabled' : ''}`}
                                        onClick={() => handleItemClick(categoryData?.category, value)}
                                    >
                                        {translate(key)}
                                        {selectedButtons[categoryData?.category]?.includes(value) && <ValidateIcon className="validateIcon" />}
                                    </div>)

                            })}

                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}


export default PDFCategoryData  