import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ClickableTextComponent from 'components/atoms/ClickableTextComponent';

import { translate } from 'services/remoteConfig';


import backArrow from 'assets/svg/back-arrow.svg';

import { colors } from 'themes';

import './styles/Header.css';
import { ORANGE } from 'constant/constants';
import { resetAthleteSelected } from 'slices/athleteSlice';
import ButtonComponent from 'components/atoms/ButtonComponent';
import { mpTrack } from 'services/mixpanel';
import { handleLogout } from 'utils/utils';

const ANALYSIS = 'analysis';
const UPLOAD_VIDEO = 'upload-video';
const EXERCICE = 'exercice';
const LEADERBOARD = 'leaderboard';

function Header({ isAuth }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <AppBar
      component="nav"
      sx={{
        ...styles.container,
        ...(location?.pathname === '/leaderboard' ? { width: '100%' } : null),
      }}>
      <Toolbar sx={styles.toolbar}>
        <div style={styles.logoContainer}>
          {location?.pathname?.includes(ANALYSIS) ||
            location?.pathname?.includes(UPLOAD_VIDEO) ||
            location?.pathname?.includes(LEADERBOARD) ||
            location?.pathname?.includes(EXERCICE) ? (
            <img
              onClick={() => {
                navigate(-1);
                if (location?.pathname?.includes(UPLOAD_VIDEO)) return;
                if (location?.pathname?.includes(ANALYSIS + '-athlete'))
                  dispatch(resetAthleteSelected());
              }}
              style={{ cursor: 'pointer' }}
              src={backArrow}
              alt="Back button"
              className="go-back-button"
            />
          ) : null}
        </div>
        <div style={{ float: 'Left' }}></div>
        {isAuth ? (
          <>
            <div style={styles.buttonContainer}>
              <ButtonComponent
                type={ORANGE}
                sx={{
                  backgroundColor: colors.SECONDARY,
                  borderRadius: 20,
                  textTransform: 'none',
                  minWidth: '20vh',
                }}
                onClick={() => {
                  mpTrack('Web App Upload Button Clicked');
                  navigate('/upload-video/');
                }}
                title={translate('NewVideo')}
              />
            </div>
            <div style={{ marginBottom: 5 }}>
              <ClickableTextComponent
                onPress={() => handleLogout(dispatch, navigate)}
                title={translate('Logout')}
              />
            </div>
          </>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}

const styles = {
  button: {
    color: colors.WHITE,
    fontWeight: 'bold',
    marginLeft: 3,
    textTransform: 'none',
  },
  buttonContainer: {
    marginBottom: 5,
    marginRight: 20,
  },
  container: {
    backgroundColor: colors.BACKGROUND,
    width: '87.5%',
    height: 60,
    borderRadius: 3,
    position: 'relative',
    display: 'flex',
    float: 'right',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
};
export default Header;
