import React, { useEffect, useState } from 'react';

import ErrorCard from './components/ErrorCard';
import LoadingCard from './components/LoadingCard';
import SuccessCard from './components/SuccessCard';

import { addCredit } from 'services/usageApi';
import { getDowloadUrlFromGsUrl, updateAnalysis } from 'services/analysisApi';

import { isOwnerOfItem } from 'utils/utils';

import { FAILED, PENDING, SUCCESS } from 'constant/constants';

import { useAnalysisListener } from 'hooks/useAnalysisChanges';
import AnalysisProgressCard from './components/AnalysisProgressCard';
import { mpTrack } from 'services/mixpanel';
import { useSelector } from 'react-redux';
import {
  analysisListData,
  correctLocalStorageJsonVersion,
} from 'slices/analysisSlice';
import { selectAthleteSelected } from 'slices/athleteSlice';
import { updateAnalysisInProject } from 'services/projectApi';



function AnalysisCard({ analysisId, comparedAnalysis, setComparedAnalysis, analysisFromStore }) {
  const [loading, setLoading] = useState(false);
  const analysisListDataFromStore = useSelector(analysisListData);
  const athleteSlice = useSelector(selectAthleteSelected);
  const [status, setStatus] = useState(analysisFromStore?.status);


  //If analysis is failed and user is owner of the item then add credit
  //If analysis is success then get the data urls and set it to the state and storage
  useEffect(() => {
    const localData = analysisListDataFromStore?.find(
      item => item?.data?.uploadVideoId === analysisId,
    );
    if (!localData) return;

    if (analysisListDataFromStore?.status === FAILED) {
      mpTrack('Web App Analysis Failed', analysisListDataFromStore?.data?.uploadVideoId);
      if (!analysisListDataFromStore?.refund && isOwnerOfItem(analysisListDataFromStore?.data?.owner)) {
        addCredit(1);
        updateAnalysis({ refund: true }, analysisListDataFromStore);
        updateAnalysisInProject({ refund: true }, analysisListDataFromStore);
      }
    }

  }, [analysisFromStore]);


  if (athleteSlice?.id && athleteSlice?.id !== analysisFromStore?.data?.tag?.id)
    return <></>;

  if (analysisFromStore && status === PENDING) {
    return (
      <AnalysisProgressCard
        analysisFromStore={analysisFromStore}
        id={analysisFromStore?.data?.uploadVideoId}
        setStatus={setStatus}
      />
    );
  } else if (!loading && status === SUCCESS) {
    return (
      <SuccessCard
        analysisFromStore={analysisFromStore}
        comparedAnalysis={comparedAnalysis}
        setComparedAnalysis={setComparedAnalysis}
      />
    );
  } else if (status === FAILED)
    return <ErrorCard analysis={analysisFromStore} />;
  else
    return (
      <LoadingCard
        id={analysisFromStore?.data?.uploadVideoId}
        analysis={analysisFromStore}
      />
    );
}

export default AnalysisCard;
