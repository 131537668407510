import React from 'react';

import { useSelector } from 'react-redux';

import ButtonComponent from 'components/atoms/ButtonComponent';

import { USER_LOCALE, translate } from 'services/remoteConfig';
import { getAuth } from 'firebase/auth';
import { DOWNLOADED, FAILED, GENERATION } from 'constant/constants';

const GeneratePDF = ({ videoId, itemsToDisplay, setSuccess, setLoading, setUploading }) => {
    const analysisData = useSelector((state) => state?.analysis?.listAnalysisData);
    const analysisDB = analysisData?.find((item) => item?.data?.uploadVideoId === videoId);


    const generatePDF = () => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                setUploading(GENERATION);
                const urlAPI = process.env.REACT_APP_API_KEY + '/analysis/generate_pdf';
                const auth = getAuth();
                const currentUser = auth.currentUser;

                const idToken = await currentUser.getIdToken();

                const formattedDate = new Date(analysisDB?.date).toLocaleString(
                    USER_LOCALE,
                    {
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                    },
                );

                const dataTosend = {
                    id: analysisDB?.data?.uploadVideoId,
                    language: USER_LOCALE,
                    athlete_name: analysisDB?.data?.tag?.name,
                    date: formattedDate,
                    display_score: itemsToDisplay?.display_score,
                    segments: itemsToDisplay?.segments,
                    metrics: itemsToDisplay?.metrics,
                    joint_angle: itemsToDisplay?.joint_angle,
                    others: itemsToDisplay?.others,
                    analysis_type: analysisDB?.analysis_type,
                    note: itemsToDisplay?.note,
                };


                const response = await fetch(urlAPI, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        token: `${idToken}`,
                    },
                    body: JSON.stringify(dataTosend),
                });
                if (response.status !== 200) {
                    setUploading(FAILED)
                }
                const blob = await response.blob();

                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${analysisDB?.data?.tag?.name}_${new Date(analysisDB?.date).toLocaleDateString(USER_LOCALE, {
                    month: "numeric",
                    day: "numeric",
                })}.pdf`;
                a.click();
                URL.revokeObjectURL(url);
                setLoading(false);
                setSuccess(true);
                setUploading(DOWNLOADED)
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    return (
        <div style={{ marginTop: "2vh" }}>
            <ButtonComponent type="orange" onClick={generatePDF} title={translate('GeneratePDF')} />
        </div>
    );
};



export default GeneratePDF;
