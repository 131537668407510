export const onBoardingWelcomeTypologieText = {
  injured: ['WelcomeTypologieTextInjured', 'WelcomeTypologieTextInjured2'],
  performer: ['WelcomeTypologieTextPerfomer', 'WelcomeTypologieTextPerfomer2'],
  lifestyle: [
    'WelcomeTypologieTextLifestyle',
    'WelcomeTypologieTextLifestyle2',
  ],
};

export const onboardingAnalysisPresentation = {
  man: {first: 'AnalysisPresentation', second: 'AnalysisPresentationSecondary'},
  woman: {
    first: 'AnalysisPresentationWoman',
    second: 'AnalysisPresentationSecondary',
  },
};

export const analysisPresentationScore = {
  performer: 'AnalysisPresentationScorePerformer',
  injured: 'AnalysisPresentationScoreInjured',
  lifestyle: 'AnalysisPresentationScoreLifestyle',
};

export const tutoVideoIntro = {
  athlete: 'AthleteIntroTutoVideo',
  coach: 'CoachIntroTutoVideo',
  professional: 'CoachIntroTutoVideo',
};

export const accountCreationHeadline = {
  athlete: 'AccountCreationHeadline',
  coach: 'AccountCreationHeadlineCoach',
  professional: 'AccountCreationHeadlineCoach',
};

export const segments = {
  fr: [
    {
      score_number: 0.4002,
      foot_strike_pattern: 'midfoot',
      asset_id: 'MidfootO',
      texts: {
        score: 'score_okay',
        title: 'Pose du pied',
        position: 'Pose pied a plat',
        overstride_title: 'Pose du pied à plat en avant du centre de gravité',
        overstride_explanation:
          'Quand le pied est posé plus en avant du centre de gravité, avec un angle de 5° à 10°, les muscles travaillent plus dur. Cela peut causer des douleurs ou des blessures. Il est important de faire des exercices de renforcement pour aider les muscles à mieux supporter cette surcharge. Les gammes de course sont aussi nécessaires pour ajuster la foulée et diminuer cet écart et revenir petit a petit sous le centre de gravité.',
        foot_strike_explanation:
          "Lorsque la pose médio-pied s'effectue en avant du centre de gravité, bien que la distribution des forces soit plus homogène qu'avec une attaque talon, elle entraîne néanmoins une brève phase de freinage. Les muscles postérieurs de la jambe sont fortement mobilisés pour stabiliser l'appui, alors que les forces de réaction au sol se dirigent en direction opposée à la course.",
        phases: {
          impact:
            'Le médio-pied entre en contact avec le sol alors que le genou est partiellement fléchi. Le centre de gravité étant positionné derrière l’appui, la charge est essentiellement transmise aux muscles stabilisateurs et aux tissus conjonctifs de la cheville.',
          amortisation:
            "Les muscles gastrocnémiens et le tendon d’Achille sont particulièrement sollicités pour amortir l'impact et stabiliser l'articulation de la cheville, qui subit une flexion dorsale importante. Les ischio-jambiers participent à la gestion de l'extension de la hanche, tout en contrôlant la flexion du genou.",
          propulsion:
            "La transition vers la propulsion est ralentie par la position avant du pied, mais les muscles du mollet et du pied parviennent néanmoins à fournir une propulsion modérée. Les ischio-jambiers interviennent activement pour contrôler l'extension de la hanche et générer de la puissance.",
        },
        exercise: [],
      },
      type: 'RightAnkle',
      overstride: {
        mean_angle: 9.4,
        score: 'score_okay',
      },
      range_good: [-10, 5],
      range_okay: [5, 10],
    },
    {
      type: 'RightEar',
      score_number: 0.5775,
      angle: 4,
      asset_id: 'HeadOT',
      texts: {
        exercice: ['trapeze_cervical_stretch'],
        explanation:
          "Regarder trop vers le ciel pendant la course peut occasionner une tension musculaire, tant au niveau du cou que le long de la colonne vertébrale, engendrant ainsi de la fatigue et de l'inconfort. En élevant excessivement la tête, cela peut également affecter la respiration, réduisant ainsi l'efficacité de l'apport en oxygène aux muscles.\nMaintiens ta tête dans une position neutre, alignée avec ta colonne vertébrale. Un alignement adéquat de la tête, du cou et du dos contribue à une foulée plus efficace.\nChoisis un point à l'horizon pour diriger ton regard. Cela t'aidera à maintenir une position de tête adéquate.",
        position: 'Regard légèrement porté vers le ciel',
        score: 'score_okay',
        title: 'Position de la tête',
        recap: [
          'Aligner tête, cou et dos.',
          'Fatigue accrue.',
          'Regard trop élevé.',
          'Risque de tensions musculaires.',
        ],
        segmented_explanation: {
          coach_tips:
            "Choisis un point à l'horizon pour diriger ton regard. Cela t'aidera à maintenir une position de tête adéquate.",
          consequences:
            "Regarder trop vers le ciel pendant la course peut occasionner une tension musculaire, tant au niveau du cou que le long de la colonne vertébrale, engendrant ainsi de la fatigue et de l'inconfort. En élevant excessivement la tête, cela peut également affecter la respiration, réduisant ainsi l'efficacité de l'apport en oxygène aux muscles.",
          correction:
            'Maintiens ta tête dans une position neutre, alignée avec ta colonne vertébrale. Un alignement adéquat de la tête, du cou et du dos contribue à une foulée plus efficace.',
        },
      },
      range_good: [-8, 2],
      range_okay: [-12, 6],
    },
    {
      type: 'RightHip',
      score_number: 0.33,
      asset_id: 'TrunkOT',
      angle: 3,
      texts: {
        exercice: ['ab_reinforcement', 'dorsal_reinforcement'],
        explanation:
          "Un léger basculement du buste vers l'arrière peut augmenter une pression sur les muscles lombaires, entraînant une fatigue précoce et augmentant le risque des douleurs au bas du dos. Pour contrebalancer cette inclinaison, les muscles fessiers peuvent être sollicités de manière excessive, générant ainsi des tensions musculaires dans cette région. En outre, cette inclinaison du buste vers l'arrière peut déplacer le centre de gravité vers l'arrière, imposant une charge supplémentaire sur les muscles des jambes. Cela peut entraîner une diminution de la capacité du coureur à propulser son corps vers l'avant, se traduisant par une perte d'efficacité dans le mouvement et une augmentation de la dépense énergétique.\nLe buste devrait être légèrement plus basculé vers l'avant. Pour cela, active tes muscles abdominaux afin de stabiliser ton torse et réduire la pression sur la région lombaire.\nTente de toucher le ciel avec ta tête tout en gardant l'envie de te grandir le plus possible.",
        position: "Légèrement penché vers l'arrière",
        score: 'score_okay',
        title: 'Position du dos',
        recap: [
          'Buste trop en arrière.',
          'Engager les abdominaux pour stabiliser le dos.',
          'Risques de douleurs dans le bas du dos.',
          'Surcharge des muscles lombaires et fessiers.',
        ],
        segmented_explanation: {
          coach_tips:
            "Tente de toucher le ciel avec ta tête tout en gardant l'envie de te grandir le plus possible.",
          consequences:
            "Un léger basculement du buste vers l'arrière peut augmenter une pression sur les muscles lombaires, entraînant une fatigue précoce et augmentant le risque des douleurs au bas du dos. Pour contrebalancer cette inclinaison, les muscles fessiers peuvent être sollicités de manière excessive, générant ainsi des tensions musculaires dans cette région. En outre, cette inclinaison du buste vers l'arrière peut déplacer le centre de gravité vers l'arrière, imposant une charge supplémentaire sur les muscles des jambes. Cela peut entraîner une diminution de la capacité du coureur à propulser son corps vers l'avant, se traduisant par une perte d'efficacité dans le mouvement et une augmentation de la dépense énergétique.",
          correction:
            "Le buste devrait être légèrement plus basculé vers l'avant. Pour cela, active tes muscles abdominaux afin de stabiliser ton torse et réduire la pression sur la région lombaire.",
        },
      },
      range_good: [6, 12],
      range_okay: [2, 16],
    },
    {
      type: 'RightElbow',
      angle: 71,
      score_number: 0.334,
      asset_id: 'ArmOT',
      texts: {
        exercice: ['bi_stretch', 'trapeze_cervical_stretch'],
        explanation:
          "Un balancement excessif des bras peut solliciter de manière excessive les muscles du haut du corps. Cela peut également entraîner une fatigue prématurée de ces muscles donc augmenter le risque de douleurs musculaires. Tout cela va entrainer une perte d'énergie et un déséquilibre pendant la course.\nDes mouvements plus contrôlés minimiseront la sollicitation excessive des muscles et amélioreront l'efficacité de la course. Essaie donc de maintenir tes coudes un peu plus pliés.\nImagine tes bras comme un pendule, se balançant naturellement et efficacement.",
        position: 'Flexion modérée',
        score: 'score_okay',
        title: 'Position des bras',
        recap: [
          'Amplitude du haut du corps restreinte.',
          'Augmenter le balancement des bras.',
          'Coude trop fermé.',
          'Relâcher les épaules et desserer les poings.',
        ],
        segmented_explanation: {
          coach_tips:
            "Travaille le mouvement des bras devant un miroir avec l'intention d'avoir les épaules bien relachées.",
          consequences:
            "Un mouvement de bras trop fléchi limite l'amplitude du haut du corps pendant la course. Ce qui peut affecter l'équilibre et la coordination. Un mouvement de bras inadéquat peut entraîner une tension excessive au niveau du haut du corps et provoquer un sentiment de fatigue durant l'effort.",
          correction:
            "Tes bras ne devraient pas seulement se balancer vers l'avant, mais aussi vers l'arrière; ce qui va contribuer à l'efficacité de la propulsion. Évite de serrer les poings et pense à bien relâcher les épaules.",
        },
      },
      range_good: [75, 85],
      range_okay: [71, 89],
    },
    {
      type: 'forwardCycle',
      angle: 159,
      score_number: 0.7987755102040817,
      asset_id: 'ForwardCycleG',
      texts: {
        exercice: null,
        explanation:
          "Si la jambe avant est trop fléchie lorsque de la pose de pied au sol pendant la course, cela peut entraîner une perte d'efficacité et potentiellement augmenter le risque de blessures.\nContinue à pratiquer en te concentrant à garder le genou dans cette position.\nEn courant, imagine un fil invisible attaché à ton genou, tirant doucement vers le haut. À chaque pas, élève légèrement le genou vers cette ligne invisible, puis laisse-le redescendre naturellement.",
        position: 'Position Idéale',
        score: 'score_good',
        title: 'Angle genou avant à la pose du pied',
        recap: [
          'Continuer à pratiquer dans cette position.',
          'Efficacité de course optimale.',
          "L'angle du genou avant est idéal.",
        ],
        segmented_explanation: {
          coach_tips:
            'En courant, imagine un fil invisible attaché à ton genou, tirant doucement vers le haut. À chaque pas, élève légèrement le genou vers cette ligne invisible, puis laisse-le redescendre naturellement.',
          consequences:
            " Si la jambe avant est trop fléchie lorsque de la pose de pied au sol pendant la course, cela peut entraîner une perte d'efficacité et potentiellement augmenter le risque de blessures.",
          correction:
            'Continue à pratiquer en te concentrant à garder le genou dans cette position.',
        },
      },
      range_good: [131, 180],
      range_okay: [122, 131],
    },
    {
      type: 'backCycle',
      angle: 120.71435499139415,
      score_number: 0.24444444444444446,
      asset_id: 'BackCycleB',
      texts: {
        exercice: [
          'ab_reinforcement',
          'ischio_reinforcement',
          'psoas_reinforcement',
        ],
        explanation:
          "Un cycle de jambe restreint crée des limitations dans l'amplitude des mouvements de la hanche, exerçant ainsi un impact sur l'efficacité de la propulsion. Cette restriction peut également agir de façon néfaste au niveaux des genoux et des chevilles et donc augmenter le risque de blessure. En adoptant des foulées plus courtes, la dépense énergétique nécessaire pour maintenir une vitesse donnée s'amplifie de façon significative. Cette surcharge énergétique peut ainsi induire une fatigue précoce.\nEtre attentif à l'amplitude de tes mouvements de jambes et de la hanche va te permettre d'avoir une meilleure technique. Introduis des sprints ou des accélérations pour encourager des mouvements de jambe arrière plus dynamiques. Intégrer des exercices de mobilité et de proprioception serait intéressant également.\nImagine que ton talon arrière est attaché à un ballon, le soulevant derrière toi à chaque foulée.",
        position: 'Retour de la jambe arrière trop bas ',
        score: 'score_bad',
        title: 'Angle genou arrière à la pose du pied',
        recap: [
          'Le genou arrière est trop ouvert.',
          "Nécessité de plus d'énergie pour conserver la vitesse.",
          "Restriction de l'amplitude des mouvements.",
        ],
        segmented_explanation: {
          coach_tips:
            'Imagine que ton talon arrière est attaché à un ballon, le soulevant derrière toi à chaque foulée.',
          consequences:
            "Un cycle de jambe restreint crée des limitations dans l'amplitude des mouvements de la hanche, exerçant ainsi un impact sur l'efficacité de la propulsion. Cette restriction peut également agir de façon néfaste au niveaux des genoux et des chevilles et donc augmenter le risque de blessure. En adoptant des foulées plus courtes, la dépense énergétique nécessaire pour maintenir une vitesse donnée s'amplifie de façon significative. Cette surcharge énergétique peut ainsi induire une fatigue précoce.",
          correction:
            "Etre attentif à l'amplitude de tes mouvements de jambes et de la hanche va te permettre d'avoir une meilleure technique. Introduis des sprints ou des accélérations pour encourager des mouvements de jambe arrière plus dynamiques. Intégrer des exercices de mobilité et de proprioception serait intéressant également.",
        },
      },
      range_good: [0, 90],
      range_okay: [90, 99],
    },
  ],
  zh: [
    {
      score_number: 0.4002,
      foot_strike_pattern: 'midfoot',
      asset_id: 'MidfootO',
      texts: {
        score: 'score_okay',
        title: '脚的位置',
        position: '平足',
        overstride_title: '平足落地在重心前方',
        overstride_explanation:
          '当脚在重心前方着地，角度在5°到10°之间时，肌肉的工作负荷增加。这可能导致疼痛或受伤。因此，进行增强练习以帮助肌肉更好地承受这一额外负荷是很重要的。还需要进行跑步节奏训练，以调整步态并逐渐将其恢复到重心下方。',
        foot_strike_explanation:
          '当中足着地位置在重心前方时，尽管力量分配比脚跟着地时更为均匀，但仍然会导致短暂的制动阶段。后腿肌肉被高度调动以稳定支撑，同时地面的反作用力朝向与奔跑相反的方向。',
        phases: {
          impact:
            '中足在膝盖部分弯曲的情况下与地面接触。由于重心位于支撑后方，负担主要传递给稳定肌肉和踝关节的结缔组织。',
          amortisation:
            '腓肠肌和跟腱在吸收冲击和稳定踝关节方面被特别激活，踝关节受到明显的背屈。腿后肌参与控制髋关节的伸展，同时控制膝关节的弯曲。',
          propulsion:
            '由于脚的位置前置，推进的过渡受到延迟，但小腿和脚的肌肉依然能够提供适度的推进。腿后肌积极参与控制髋关节的伸展并产生力量。',
        },
        exercise: [],
      },
      type: 'RightAnkle',
      overstride: {
        mean_angle: 9.4,
        score: 'score_okay',
      },
      range_good: [-10, 5],
      range_okay: [5, 10],
    },
    {
      type: 'RightEar',
      score_number: 0.5775,
      angle: 4,
      asset_id: 'HeadOT',
      texts: {
        exercice: ['trapeze_cervical_stretch'],
        explanation:
          '跑步时过多地向天空看可以导致颈部和脊柱沿线的肌肉紧张，导致疲劳和不适。过度抬头还会影响呼吸，从而降低氧气输送到肌肉的效率。\n保持头部处于中立位置，与你的脊柱对齐。头部、颈部和背部的正确对齐有助于更有效的步伐。\n选择地平线上的一个点来定向你的目光。这将帮助你保持适当的头部位置。',
        position: '俯视地面',
        score: 'score_okay',
        title: '头部位置',
        recap: [
          '头部、颈部和背部对齐。',
          '疲劳增加。',
          '目光过高。',
          '肌肉紧张的风险。',
        ],
        segmented_explanation: {
          coach_tips:
            '选择地平线上的一个点来定向你的目光。这将帮助你保持适当的头部位置。',
          consequences:
            '跑步时过多地向天空看可以导致颈部和脊柱沿线的肌肉紧张，导致疲劳和不适。过度抬头还会影响呼吸，从而降低氧气输送到肌肉的效率。',
          correction:
            '保持头部处于中立位置，与你的脊柱对齐。头部、颈部和背部的正确对齐有助于更有效的步伐。',
        },
      },
      range_good: [-8, 2],
      range_okay: [-12, 6],
    },
    {
      type: 'RightHip',
      score_number: 0.33,
      asset_id: 'TrunkOT',
      angle: 3,
      texts: {
        exercice: ['ab_reinforcement', 'dorsal_reinforcement'],
        explanation:
          '躯干轻微的向后倾斜可以增加对下背部肌肉的压力，导致早期疲劳并提高腰背痛的风险。为了平衡这种倾斜，臀部肌肉可能会过度使用，产生该区域的肌肉紧张。此外，躯干的向后倾斜可以将重心向后移，给腿部肌肉增加额外负担。这可能会减少跑者向前推进身体的能力，导致运动效率的损失和能量消耗的增加。\n躯干应该轻微向前倾斜。为了实现这一点，激活你的腹部肌肉以稳定你的胸部并减少对腰部区域的压力。\n尝试用你的头顶向天空伸展，同时保持尽可能伸长自己的愿望。',
        position: '身体过于前倾',
        score: 'score_okay',
        title: '背部位置',
        recap: [
          '使用腹部肌肉来稳定背部。',
          '对腰部和臀部肌肉的过载。',
          '腰背痛的风险。',
          '躯干过于后仰。',
        ],
        segmented_explanation: {
          coach_tips:
            '尝试用你的头顶向天空伸展，同时保持尽可能伸长自己的愿望。',
          consequences:
            '躯干轻微的向后倾斜可以增加对下背部肌肉的压力，导致早期疲劳并提高腰背痛的风险。为了平衡这种倾斜，臀部肌肉可能会过度使用，产生该区域的肌肉紧张。此外，躯干的向后倾斜可以将重心向后移，给腿部肌肉增加额外负担。这可能会减少跑者向前推进身体的能力，导致运动效率的损失和能量消耗的增加。',
          correction:
            '躯干应该轻微向前倾斜。为了实现这一点，激活你的腹部肌肉以稳定你的胸部并减少对腰部区域的压力。',
        },
      },
      range_good: [6, 12],
      range_okay: [2, 16],
    },
    {
      type: 'RightElbow',
      angle: 71,
      score_number: 0.334,
      asset_id: 'ArmOT',
      texts: {
        exercice: ['bi_stretch', 'trapeze_cervical_stretch'],
        explanation:
          '过度弯曲的手臂动作会限制跑步时上半身的运动范围。这可能会影响平衡和协调。不恰当的手臂动作会导致上半身过度紧张，并在训练中引起疲劳感。\n你的手臂不仅应该向前摆动，还应该向后摆动，有助于提高推进效率。避免握紧你的拳头，专注于保持你的肩膀放松。\n在镜子前练习你的手臂动作，目的是保持肩膀放松。',
        position: '弯曲',
        score: 'score_okay',
        title: '手臂位置',
        recap: [
          '上半身范围受限。',
          '增加手臂摆动。',
          '手肘过于闭合。',
          '放松肩膀，松开拳头。',
        ],
        segmented_explanation: {
          coach_tips: '在镜子前练习你的手臂动作，目的是保持肩膀放松。',
          consequences:
            '过度弯曲的手臂动作会限制跑步时上半身的运动范围。这可能会影响平衡和协调。不恰当的手臂动作会导致上半身过度紧张，并在训练中引起疲劳感。',
          correction:
            '你的手臂不仅应该向前摆动，还应该向后摆动，有助于提高推进效率。避免握紧你的拳头，专注于保持你的肩膀放松。',
        },
      },
      range_good: [75, 85],
      range_okay: [71, 89],
    },
    {
      type: 'forwardCycle',
      angle: 159,
      score_number: 0.7987755102040817,
      asset_id: 'ForwardCycleG',
      texts: {
        exercice: null,
        explanation:
          '如果在跑步时脚着地时前腿过于弯曲，这可能会导致效率降低，并可能增加受伤的风险。\n如果在跑步时前腿在着地时弯曲过度，这可能会导致效率损失，并有可能增加受伤的风险\n在跑步时，想象有一根看不见的绳子连接着你的膝盖，轻轻向上拉。每迈出一步，轻微地将膝盖提向这条看不见的线，然后自然地放下来。 ',
        position: '理想位置',
        score: 'score_good',
        title: '前循环',
        recap: ['前膝角度理想。', '继续在这个姿势下练习。', '跑步效率最佳。'],
        segmented_explanation: {
          coach_tips:
            '在跑步时，想象有一根看不见的绳子连接着你的膝盖，轻轻向上拉。每迈出一步，轻微地将膝盖提向这条看不见的线，然后自然地放下来。',
          consequences:
            '如果在跑步时脚着地时前腿过于弯曲，这可能会导致效率降低，并可能增加受伤的风险。',
          correction:
            '如果在跑步时前腿在着地时弯曲过度，这可能会导致效率损失，并有可能增加受伤的风险。',
        },
      },
      range_good: [131, 180],
      range_okay: [122, 131],
    },
    {
      type: 'backCycle',
      angle: 120.71435499139415,
      score_number: 0.24444444444444446,
      asset_id: 'BackCycleB',
      texts: {
        exercice: [
          'ab_reinforcement',
          'ischio_reinforcement',
          'psoas_reinforcement',
        ],
        explanation:
          '受限的腿部循环会限制臀部的运动范围，从而影响推进效率。这种限制还可能对膝盖和脚踝产生不利影响，增加受伤的风险。采用较短的步幅会显著增加维持给定速度所需的能量消耗。这种增加的能量需求可能会导致早期疲劳。\n注意腿部和臀部动作的运动范围将使你拥有更好的技巧。加入冲刺或加速训练以鼓励后腿的更多动态移动。包括灵活性和本体感知练习也会有益。\n想象你的后脚跟被一只气球绑着，在每一步跑步中都会被提起。',
        position: '后腿回程太低',
        score: 'score_bad',
        title: '后循环',
        recap: [
          '后膝过于张开。',
          '维持速度需要更多能量。',
          '运动幅度受限。',
          '进行冲刺练习以提高运动幅度。',
        ],
        segmented_explanation: {
          coach_tips:
            '想象你的后脚跟被一只气球绑着，在每一步跑步中都会被提起。',
          consequences:
            '受限的腿部循环会限制臀部的运动范围，从而影响推进效率。这种限制还可能对膝盖和脚踝产生不利影响，增加受伤的风险。采用较短的步幅会显著增加维持给定速度所需的能量消耗。这种增加的能量需求可能会导致早期疲劳。',
          correction:
            '注意腿部和臀部动作的运动范围将使你拥有更好的技巧。加入冲刺或加速训练以鼓励后腿的更多动态移动。包括灵活性和本体感知练习也会有益。',
        },
      },
      range_good: [0, 90],
      range_okay: [90, 99],
    },
  ],
  en: [
    {
      score_number: 0.4002,
      foot_strike_pattern: 'midfoot',
      asset_id: 'MidfootO',
      texts: {
        score: 'score_okay',
        title: 'Foot landing',
        position: 'Flat foot landing',
        overstride_title: 'Mid-foot strike in front of the center of gravity',
        overstride_explanation:
          'When the foot is placed further in front of the center of gravity, with an angle of 5° to 10°, the muscles work harder. This can cause pain or injuries. It is important to perform strengthening exercises to help the muscles better support this overload. Running drills are also necessary to adjust the stride and gradually return to under the center of gravity.',
        foot_strike_explanation:
          'When the mid-foot landing occurs in front of the center of gravity, although the distribution of forces is more even than with a heel strike, it still leads to a brief braking phase. The posterior leg muscles are heavily engaged to stabilize the support, while the ground reaction forces move in the opposite direction to the run.',
        phases: {
          impact:
            'The mid-foot contacts the ground while the knee is partially bent. With the center of gravity positioned behind the support, the load is mainly transmitted to the stabilizing muscles and connective tissues of the ankle.',
          amortisation:
            'The gastrocnemius muscles and the Achilles tendon are particularly stressed to absorb impact and stabilize the ankle joint, which undergoes significant dorsiflexion. The hamstrings assist in managing hip extension while controlling knee flexion.',
          propulsion:
            "The transition to propulsion is slowed by the foot's forward position, but the calf and foot muscles still manage to provide moderate propulsion. The hamstrings actively help control hip extension and generate power.",
        },
        exercise: [],
      },
      type: 'RightAnkle',
      overstride: {
        mean_angle: 9.4,
        score: 'score_okay',
      },
      range_good: [-10, 5],
      range_okay: [5, 10],
    },
    {
      type: 'RightEar',
      score_number: 0.5775,
      angle: 4,
      asset_id: 'HeadOT',
      texts: {
        exercice: ['trapeze_cervical_stretch'],
        explanation:
          'Looking too much towards the sky while running can cause muscle tension in both the neck and along the spine, resulting in fatigue and discomfort. Elevating the head excessively can also affect breathing, thereby reducing the efficiency of oxygen delivery to the muscles.\nMaintain your head in a neutral position, aligned with your spine. Proper alignment of the head, neck, and back contributes to a more efficient stride.\nChoose a point on the horizon to direct your gaze. This will help you maintain an appropriate head position.',
        position: 'Gaze slightly skyward',
        score: 'score_okay',
        title: 'Head position',
        recap: [
          'Align head, neck, and back.',
          'Gaze too high.',
          'Increased fatigue.',
          'Risk of muscle tension.',
        ],
        segmented_explanation: {
          coach_tips:
            'Choose a point on the horizon to direct your gaze. This will help you maintain an appropriate head position.',
          consequences:
            'Looking too much towards the sky while running can cause muscle tension in both the neck and along the spine, resulting in fatigue and discomfort. Elevating the head excessively can also affect breathing, thereby reducing the efficiency of oxygen delivery to the muscles.',
          correction:
            'Maintain your head in a neutral position, aligned with your spine. Proper alignment of the head, neck, and back contributes to a more efficient stride.',
        },
      },
      range_good: [-8, 2],
      range_okay: [-12, 6],
    },
    {
      type: 'RightHip',
      score_number: 0.33,
      asset_id: 'TrunkOT',
      angle: 3,
      texts: {
        exercice: ['ab_reinforcement', 'dorsal_reinforcement'],
        explanation:
          "A torso not enough tilted forward can increase pressure on the lower back muscles, causing early fatigue and raising the risk of lower back pain. To counterbalance this tilt, the gluteal muscles may be excessively engaged, generating muscle tension in that region. Additionally, this backward tilt of the torso can shift the center of gravity backward, imposing an additional burden on the leg muscles. This can decrease the runner's ability to propel their body forward, leading to a loss of efficiency in movement and an increase in energy expenditure.\nThe torso should be slightly tilted forward. To achieve this, activate your abdominal muscles to stabilize your chest and reduce pressure on the lumbar region.\nTry to reach the sky with your head while maintaining the desire to elongate yourself as much as possible.",
        position: 'Not sufficiently forward-leaning',
        score: 'score_okay',
        title: 'Back position',
        recap: [
          'Engage the abdominals to stabilize the back.',
          'Overload on the lumbar and gluteal muscles.',
          'Risks of lower back pain.',
          'Torso too far back.',
        ],
        segmented_explanation: {
          coach_tips:
            'Try to reach the sky with your head while maintaining the desire to elongate yourself as much as possible.',
          consequences:
            "A torso not enough tilted forward can increase pressure on the lower back muscles, causing early fatigue and raising the risk of lower back pain. To counterbalance this tilt, the gluteal muscles may be excessively engaged, generating muscle tension in that region. Additionally, this backward tilt of the torso can shift the center of gravity backward, imposing an additional burden on the leg muscles. This can decrease the runner's ability to propel their body forward, leading to a loss of efficiency in movement and an increase in energy expenditure.",
          correction:
            'The torso should be slightly tilted forward. To achieve this, activate your abdominal muscles to stabilize your chest and reduce pressure on the lumbar region.',
        },
      },
      range_good: [6, 12],
      range_okay: [2, 16],
    },
    {
      type: 'RightElbow',
      angle: 71,
      score_number: 0.334,
      asset_id: 'ArmOT',
      texts: {
        exercice: ['bi_stretch', 'trapeze_cervical_stretch'],
        explanation:
          'An overly bent arm movement limits the range of motion of the upper body during running. This can affect balance and coordination. Improper arm movement can result in excessive tension in the upper body and lead to a feeling of fatigue during training.\nYour arms should not only swing forward but also backward, contributing to the efficiency of propulsion. Avoid clenching your fists and focus on keeping your shoulders relaxed.\nPractice the movement of your arms in front of a mirror with the intention of keeping your shoulders relaxed.',
        position: 'Moderate flexion',
        score: 'score_okay',
        title: 'Arms position',
        recap: [
          'Elbow too closed.',
          'Increase arm swinging.',
          'Relax shoulders and loosen fists.',
          'Upper body range restricted.',
        ],
        segmented_explanation: {
          coach_tips:
            'Practice the movement of your arms in front of a mirror with the intention of keeping your shoulders relaxed.',
          consequences:
            'An overly bent arm movement limits the range of motion of the upper body during running. This can affect balance and coordination. Improper arm movement can result in excessive tension in the upper body and lead to a feeling of fatigue during training.',
          correction:
            'Your arms should not only swing forward but also backward, contributing to the efficiency of propulsion. Avoid clenching your fists and focus on keeping your shoulders relaxed.',
        },
      },
      range_good: [75, 85],
      range_okay: [71, 89],
    },
    {
      type: 'forwardCycle',
      angle: 159,
      score_number: 0.7987755102040817,
      asset_id: 'ForwardCycleG',
      texts: {
        exercice: null,
        explanation:
          'If the leading leg is too bent during foot placement on the ground while running, it can lead to a loss of efficiency and potentially increase the risk of injuries.\nKeep practicing, concentrating on keeping your knee in this position.\nWhile running, imagine an invisible string attached to your knee, gently pulling upward. With each step, lift your knee slightly toward this invisible line, then let it come down naturally.',
        position: 'Ideal Position',
        score: 'score_good',
        title: 'Front knee angle at foot landing',
        recap: [
          'Continue to practice in this position.',
          'Optimal running efficiency.',
          'The front knee angle is ideal.',
        ],
        segmented_explanation: {
          coach_tips:
            'While running, imagine an invisible string attached to your knee, gently pulling upward. With each step, lift your knee slightly toward this invisible line, then let it come down naturally.',
          consequences:
            'If the leading leg is too bent during foot placement on the ground while running, it can lead to a loss of efficiency and potentially increase the risk of injuries.',
          correction:
            'Keep practicing, concentrating on keeping your knee in this position.',
        },
      },
      range_good: [131, 180],
      range_okay: [122, 131],
    },
    {
      type: 'backCycle',
      angle: 120.71435499139415,
      score_number: 0.24444444444444446,
      asset_id: 'BackCycleB',
      texts: {
        exercice: [
          'ab_reinforcement',
          'ischio_reinforcement',
          'psoas_reinforcement',
        ],
        explanation:
          'A restricted leg cycle imposes limitations on the range of motion in the hip, thereby affecting propulsion efficiency. This restriction can also have adverse effects on the knees and ankles, increasing the risk of injury. Adopting shorter strides significantly amplifies the energy expenditure required to maintain a given speed. This increased energy demand can result in early fatigue.\nBeing mindful of the range of motion in your leg and hip movements will allow you to have better technique. Incorporate sprints or accelerations to encourage more dynamic movements of the back leg. Including mobility and proprioception exercises would also be beneficial.\nVisualize your rear heel being tethered to a balloon, lifting it behind you with each stride.',
        position: 'Low heel kick',
        score: 'score_bad',
        title: 'Back knee angle at foot landing',
        recap: [
          'Need for more energy to maintain speed.',
          'Restriction of movement amplitude.',
          'Sprint exercises to improve amplitude.',
          'The back knee is too open.',
        ],
        segmented_explanation: {
          coach_tips:
            'Visualize your rear heel being tethered to a balloon, lifting it behind you with each stride.',
          consequences:
            'A restricted leg cycle imposes limitations on the range of motion in the hip, thereby affecting propulsion efficiency. This restriction can also have adverse effects on the knees and ankles, increasing the risk of injury. Adopting shorter strides significantly amplifies the energy expenditure required to maintain a given speed. This increased energy demand can result in early fatigue.',
          correction:
            'Being mindful of the range of motion in your leg and hip movements will allow you to have better technique. Incorporate sprints or accelerations to encourage more dynamic movements of the back leg. Including mobility and proprioception exercises would also be beneficial.',
        },
      },
      range_good: [0, 90],
      range_okay: [90, 99],
    },
  ],
  de: [
    {
      score_number: 0.4002,
      foot_strike_pattern: 'midfoot',
      asset_id: 'MidfootO',
      texts: {
        score: 'score_okay',
        title: 'Fußlandung',
        position: 'Landung mit flachem Fuß',
        overstride_title: 'Flacher Fußauftritt vor dem Körperschwerpunkt',
        overstride_explanation:
          'Wenn der Fuß zwischen 5° und 10° vor dem Schwerpunkt landet, kann dies zu einer ungünstigen Druckverteilung führen. Diese Landetechnik erhöht die Belastung der Gelenke und kann bei wiederholtem Auftreten zu Verletzungen führen. Es ist ratsam, die Muskulatur gezielt zu stärken und die Lauftechnik zu verbessern, um diese Risiken zu minimieren.',
        foot_strike_explanation:
          'Die Landung auf dem Mittelfuß in diesem Winkel kann die Belastung gleichmäßiger verteilen, birgt aber immer noch Risiken. Der Fuß ist jedoch immer noch signifikant vor dem Schwerpunkt, was zu einem ineffizienten Schritt führt.',
        phases: {
          impact:
            'Die Landung erfolgt mit einem leicht gebeugten Knie, aber die Druckkräfte sind erhöht, da der Schwerpunkt vor dem Kontaktpunkt liegt. Dies kann zu einer Überlastung der Oberschenkelmuskulatur führen.',
          amortisation:
            'Die Wadenmuskulatur wird aktiviert, um die Aufprallkräfte abzufangen, aber die Belastung kann zu Ermüdung führen. Der Knöchel muss sich stabilisieren, was zusätzlich Druck erzeugt.',
          propulsion:
            'Obwohl der Übergang zur Fortbewegung moderat ist, kann die Überlastung der hinteren Kette die Effizienz verringern und die Energieübertragung beeinträchtigen.',
        },
        exercise: [],
      },
      type: 'RightAnkle',
      overstride: {
        mean_angle: 9.4,
        score: 'score_okay',
      },
      range_good: [-10, 5],
      range_okay: [5, 10],
    },
    {
      type: 'RightEar',
      score_number: 0.5775,
      angle: 4,
      asset_id: 'HeadOT',
      texts: {
        exercice: ['trapeze_cervical_stretch'],
        explanation:
          'Wenn Du beim Laufen zu sehr in den Himmel blicken, kann dies zu Muskelverspannungen im Nacken und entlang der Wirbelsäule führen, was zu Müdigkeit und Unwohlsein führt. ',
        position: 'Schaue leicht in den Himmel',
        score: 'score_okay',
        title: 'Kopfposition',
        recap: [
          'Blick zu hoch.',
          'Gefahr von Muskelverspannungen.',
          'Erhöhte Müdigkeit.',
          'Richte deinen Kopf, Nacken und Rücken aus.',
        ],
        segmented_explanation: {
          consequences:
            'Wenn Du beim Laufen zu sehr in den Himmel blickst, kann dies zu Muskelverspannungen im Nacken und entlang der Wirbelsäule führen, was zu Müdigkeit und Unwohlsein führt. ',
          correction:
            'Halte deinen Kopf in einer neutralen Position, ausgerichtet auf deine Wirbelsäule. ',
          coach_tips:
            'Wähle einen Punkt am Horizont, um deinen Blick zu richten. ',
        },
      },
      range_good: [-8, 2],
      range_okay: [-12, 6],
    },
    {
      type: 'RightHip',
      score_number: 0.33,
      asset_id: 'TrunkOT',
      angle: 3,
      texts: {
        exercice: ['ab_reinforcement', 'dorsal_reinforcement'],
        explanation:
          'Ein nach vorne geneigter Oberkörper kann die Belastung der unteren Rückenmuskulatur verstärken, was möglicherweise zu vorzeitiger Ermüdung und übermäßiger Muskelanspannung führt und das Risiko von Schmerzen im unteren Rücken erhöht. ',
        position: 'Leicht nach hinten geneigt',
        score: 'score_okay',
        title: 'Rückenposition',
        recap: [
          'Oberkörper zu weit hinten.',
          'Überlastung der Lenden- und Gesäßmuskulatur.',
          'Risiken von Schmerzen im unteren Rückenbereich.',
          'Spanne deine Bauchmuskeln an, um den Rücken zu stabilisieren.',
        ],
        segmented_explanation: {
          consequences:
            'Ein nach vorne geneigter Oberkörper kann die Belastung der unteren Rückenmuskulatur verstärken, was möglicherweise zu vorzeitiger Ermüdung und übermäßiger Muskelanspannung führt und das Risiko von Schmerzen im unteren Rücken erhöht. ',
          correction: 'Denke daran den Oberkörper aufzurichten.',
          coach_tips:
            'Stelle dir vor, Du würdest von einer Schnur, die an deiner Brust befestigt ist, nach vorne gezogen.',
        },
      },
      range_good: [6, 12],
      range_okay: [2, 16],
    },
    {
      type: 'RightElbow',
      angle: 71,
      score_number: 0.334,
      asset_id: 'ArmOT',
      texts: {
        exercice: ['bi_stretch', 'trapeze_cervical_stretch'],
        explanation:
          'Eine zu stark gebeugte Armbewegung schränkt die Bewegungsfreiheit des Oberkörpers beim Laufen ein. ',
        position: 'Mäßige Beugung',
        score: 'score_okay',
        title: 'Armposition',
        recap: [
          'Ellenbogen zu geschlossen.',
          'Oberkörperbereich eingeschränkt.',
          'Erweitere den Armschwung.',
          'Entspanne die Schultern und locker die Fäuste.',
        ],
        segmented_explanation: {
          consequences:
            'Eine zu stark gebeugte Armbewegung schränkt die Bewegungsfreiheit des Oberkörpers beim Laufen ein. ',
          correction:
            'Deine Arme sollten nicht nur nach vorne, sondern auch nach hinten schwingen und so zur Effizienz des Vortriebs beitragen. ',
          coach_tips:
            'Übe die Bewegung deiner Arme vor einem Spiegel mit der Absicht deine Schultern entspannt zu halten.',
        },
      },
      range_good: [75, 85],
      range_okay: [71, 89],
    },
    {
      type: 'forwardCycle',
      angle: 159,
      score_number: 0.7987755102040817,
      asset_id: 'ForwardCycleG',
      texts: {
        exercice: null,
        explanation:
          'Wenn beim Laufen das Vorderbein beim Aufsetzen des Fußes auf den Boden zu stark gebeugt ist, kann dies zu Leistungseinbußen führen und möglicherweise das Verletzungsrisiko erhöhen.',
        position: 'Ideale Position',
        score: 'score_good',
        title: 'Vorderer Kniewinkel bei der Fußlandung',
        recap: [
          'Der vordere Kniewinkel ist ideal.',
          'Optimale Laufeffizienz.',
          'Behalte diese Position bei.',
        ],
        segmented_explanation: {
          consequences:
            'Wenn beim Laufen das Vorderbein beim Aufsetzen des Fußes auf den Boden zu stark gebeugt ist, kann dies zu Leistungseinbußen führen und möglicherweise das Verletzungsrisiko erhöhen.',
          correction:
            'Wenn beim Laufen das Vorderbein beim Aufsetzen des Fußes auf den Boden zu stark gebeugt ist, kann dies zu Leistungseinbußen führen und möglicherweise das Verletzungsrisiko erhöhen.',
          coach_tips:
            'Stelle dir beim Laufen eine unsichtbare Schnur vor, die an deinem Knie befestigt ist und sanft nach oben zieht. ',
        },
      },
      range_good: [131, 180],
      range_okay: [122, 131],
    },
    {
      type: 'backCycle',
      angle: 120.71435499139415,
      score_number: 0.24444444444444446,
      asset_id: 'BackCycleB',
      texts: {
        exercice: [
          'ab_reinforcement',
          'ischio_reinforcement',
          'psoas_reinforcement',
        ],
        explanation:
          'Ein eingeschränkter Beinzyklus schränkt den Bewegungsbereich der Hüfte ein und beeinträchtigt dadurch die Antriebseffizienz. ',
        position: 'Niedriger Fersentritt',
        score: 'score_bad',
        title: 'Hinterer Kniewinkel bei der Fußlandung',
        recap: [
          'Der hintere Kniewinkel ist zu weit.',
          'Einschränkung der Bewegungsamplitude.',
          'Höherer Energiebedarf, um die Geschwindigkeit aufrechtzuerhalten.',
          'Sprintübungen zur Verbesserung des Bewegungsradius.',
        ],
        segmented_explanation: {
          consequences:
            'Ein eingeschränkter Beinzyklus schränkt den Bewegungsbereich der Hüfte ein und beeinträchtigt dadurch die Antriebseffizienz. ',
          correction:
            'Wenn Du auf den Bewegungsradius deiner Bein- und Hüftbewegungen achtest, kannst Du eine bessere Technik erzielen. ',
          coach_tips:
            'Stelle dir vor, wie deine hintere Ferse an einen Ballon gebunden ist und sie sich bei jedem Schritt hinter dir anhebt.',
        },
      },
      range_good: [0, 90],
      range_okay: [90, 99],
    },
  ],
  pt: [
    {
      score_number: 0.4002,
      foot_strike_pattern: 'midfoot',
      asset_id: 'MidfootO',
      texts: {
        score: 'score_okay',
        title: 'Aterrissagem do pé',
        position: 'Aterrissagem com o pé plano',
        overstride_title:
          'Aterrissagem do pé plano à frente do centro de gravidade',
        overstride_explanation:
          'Quando o pé aterrissa em um ângulo de 5° a 10°, a carga nos músculos aumenta, levando a potenciais lesões. É importante reduzir o ângulo e corrigir a cadência de corrida para melhorar o desempenho e prevenir lesões. São recomendados exercícios de fortalecimento muscular para gerenciar a carga.',
        foot_strike_explanation:
          'Quando o mesopé aterrissa à frente do centro de gravidade, várias dificuldades biomecânicas podem ocorrer. Embora a distribuição da força seja relativamente boa, se o ângulo exceder 10°, importantes efeitos de frenagem ocorrerão.',
        phases: {
          impact:
            'Os efeitos de frenagem no corpo se amplificam, aumentando a pressão sobre o joelho e os riscos de lesões.',
          amortisation:
            'O corpo precisa se esforçar para controlar a flexão do joelho e do tornozelo. Nesse momento, os músculos da panturrilha e os outros músculos envolvidos estão sob pressão significativa.',
          propulsion:
            'O impulso está comprometido, a transferência de energia é perdida e a corrida se torna instável.',
        },
        exercise: [],
      },
      type: 'RightAnkle',
      overstride: {
        mean_angle: 9.4,
        score: 'score_okay',
      },
      range_good: [-10, 5],
      range_okay: [5, 10],
    },
    {
      type: 'RightEar',
      score_number: 0.5775,
      angle: 4,
      asset_id: 'HeadOT',
      texts: {
        exercice: ['trapeze_cervical_stretch'],
        explanation:
          'Olhar demasiado para o cima durante a corrida pode causar tensão muscular, tanto no pescoço como ao longo da coluna vertebral, levando à fadiga e ao desconforto. Levantar demasiado a cabeça pode também afetar a respiração, reduzindo a eficiência do fornecimento de oxigénio aos músculos. Mantenha a cabeça numa posição neutra, alinhada com a coluna vertebral. O alinhamento correto da cabeça, do pescoço e das costas contribui para uma passada mais eficiente. Escolha um ponto no horizonte para dirigir o seu olhar. Isto ajudá-lo-á a manter a posição correta da cabeça.',
        position: 'Olhar ligeiramente para o céu',
        score: 'score_okay',
        title: 'Posição da cabeça',
        recap: [
          'Alinhar a cabeça, o pescoço e as costas.',
          'Aumento da fadiga.',
          'Olhar demasiado alto.',
          'Risco de tensão muscular.',
        ],
        segmented_explanation: {
          coach_tips:
            'Escolha um ponto no horizonte para dirigir o seu olhar. Isto ajudá-lo-á a manter a posição correta da cabeça.',
          consequences:
            'Olhar demasiado para o céu durante a corrida pode causar tensão muscular, tanto no pescoço como ao longo da coluna vertebral, levando à fadiga e ao desconforto. Levantar demasiado a cabeça pode também afetar a respiração, reduzindo a eficiência do fornecimento de oxigénio aos músculos.',
          correction:
            'Mantenha a cabeça numa posição neutra, alinhada com a coluna vertebral. O alinhamento correto da cabeça, do pescoço e das costas contribui para uma passada mais eficiente.',
        },
      },
      range_good: [-8, 2],
      range_okay: [-12, 6],
    },
    {
      type: 'RightHip',
      score_number: 0.33,
      asset_id: 'TrunkOT',
      angle: 3,
      texts: {
        exercice: ['ab_reinforcement', 'dorsal_reinforcement'],
        explanation:
          'Uma ligeira inclinação do tronco para trás pode aumentar a pressão sobre os músculos lombares, levando a uma fadiga precoce e aumentando o risco de dor lombar. Para contrabalançar esta inclinação, os músculos glúteos podem ser sobrecarregados, gerando tensão muscular nesta região. Além disso, esta inclinação do tronco para trás pode deslocar o centro de gravidade para trás, colocando uma carga adicional nos músculos das pernas. Isto pode levar a uma redução da capacidade do corredor para impulsionar o seu corpo para a frente, resultando numa perda de eficiência do movimento e num aumento do gasto de energia.\nO tronco deve ser inclinado ligeiramente mais para a frente. Para isso, active os músculos abdominais para estabilizar o tronco e reduzir a pressão sobre a região lombar.\nTente tocar o céu com a cabeça, mantendo o desejo de crescer o mais alto possível.',
        position: 'Inclinar-se ligeiramente para trás',
        score: 'score_okay',
        title: 'Posição traseira',
        recap: [
          'Tronco demasiado recuado.',
          'Envolver os músculos abdominais para estabilizar as costas.',
          'Risco de dores lombares.',
          'Sobrecarga dos músculos lombares e das nádegas.',
        ],
        segmented_explanation: {
          coach_tips:
            'Tenta tocar o céu com a cabeça, mantendo o desejo de crescer o mais possível.',
          consequences:
            'Uma ligeira inclinação do tronco para trás pode aumentar a pressão sobre os músculos lombares, levando a uma fadiga precoce e aumentando o risco de dor lombar. Para contrabalançar esta inclinação, os músculos glúteos podem ser sobrecarregados, gerando tensão muscular nesta região. Além disso, esta inclinação do tronco para trás pode deslocar o centro de gravidade para trás, colocando uma carga adicional nos músculos das pernas. Isto pode levar a uma redução da capacidade do corredor para impulsionar o seu corpo para a frente, resultando numa perda de eficiência do movimento e num aumento do gasto de energia.',
          correction:
            'O tronco deve ser ligeiramente inclinado para a frente. Para isso, active os músculos abdominais para estabilizar o tronco e reduzir a pressão sobre a região lombar.',
        },
      },
      range_good: [6, 12],
      range_okay: [2, 16],
    },
    {
      type: 'RightElbow',
      angle: 71,
      score_number: 0.334,
      asset_id: 'ArmOT',
      texts: {
        exercice: ['bi_stretch', 'trapeze_cervical_stretch'],
        explanation:
          'O balançar excessivo dos braços pode exercer uma tensão excessiva sobre os músculos da parte superior do corpo. Pode também provocar a fadiga prematura destes músculos, aumentando o risco de dores musculares. Tudo isto conduzirá a uma perda de energia e a um desequilíbrio durante a corrida.\nMovimentos mais controlados minimizarão a utilização excessiva dos músculos e melhorarão a eficiência da corrida. Tente manter os cotovelos ligeiramente mais dobrados.\nImagine os seus braços como um pêndulo, balançando de forma natural e eficiente.',
        position: 'Flexão moderada',
        score: 'score_okay',
        title: 'Posição do braço',
        recap: [
          'Aumente o balanço dos seus braços.',
          'Cotovelo demasiado apertado.',
          'Limitação da amplitude da parte superior do corpo.',
          'Relaxe os ombros e abra os punhos.',
        ],
        segmented_explanation: {
          coach_tips:
            'Movimente os braços em frente a um espelho com a intenção de manter os ombros relaxados.',
          consequences:
            'Os braços demasiado dobrados limitam a amplitude de movimento da parte superior do corpo durante a corrida. Isto pode afetar o equilíbrio e a coordenação. Um movimento inadequado dos braços pode levar a uma tensão excessiva da parte superior do corpo e a uma sensação de fadiga durante o exercício.',
          correction:
            'Os seus braços devem balançar não só para a frente, mas também para trás, o que contribuirá para a eficiência da propulsão. Evite cerrar os punhos e lembre-se de relaxar os ombros.',
        },
      },
      range_good: [75, 85],
      range_okay: [71, 89],
    },
    {
      type: 'forwardCycle',
      angle: 159,
      score_number: 0.7987755102040817,
      asset_id: 'ForwardCycleG',
      texts: {
        exercice: null,
        explanation:
          'Se a perna da frente estiver demasiado dobrada ao colocar o pé no chão durante a corrida, isso pode levar a uma perda de eficiência e potencialmente aumentar o risco de lesões.\nContinue a praticar, concentrando-se em manter o joelho nesta posição.\nEnquanto corre, imagine um fio invisível preso ao seu joelho, puxando-o suavemente para cima. A cada passo, levante ligeiramente o joelho em direção a esta linha invisível e depois deixe-o cair naturalmente.',
        position: 'Posição ideal',
        score: 'score_good',
        title: 'Ângulo do joelho da frente ao bater o pé',
        recap: [
          'Continuar a praticar nesta posição.',
          'Eficiência de funcionamento óptima.',
          'O ângulo do joelho da frente é ideal.',
        ],
        segmented_explanation: {
          coach_tips:
            'Enquanto corre, imagine um fio invisível preso ao seu joelho, puxando suavemente para cima. A cada passo, levante ligeiramente o joelho em direção a esta linha invisível e depois deixe-o cair naturalmente.',
          consequences:
            ' Se a perna da frente estiver demasiado dobrada ao colocar o pé no chão durante a corrida, isso pode levar a uma perda de eficiência e aumentar potencialmente o risco de lesões.',
          correction:
            'Continue a praticar, concentrando-se em manter o joelho nesta posição.',
        },
      },
      range_good: [131, 180],
      range_okay: [122, 131],
    },
    {
      type: 'backCycle',
      angle: 120.71435499139415,
      score_number: 0.24444444444444446,
      asset_id: 'BackCycleB',
      texts: {
        exercice: [
          'ab_reinforcement',
          'ischio_reinforcement',
          'psoas_reinforcement',
        ],
        explanation:
          'Um ciclo de perna restrito cria limitações na amplitude de movimento da anca, com impacto na eficiência da propulsão. Esta restrição pode também ter um efeito negativo nos joelhos e tornozelos, aumentando o risco de lesões. Ao adotar passadas mais curtas, o gasto de energia necessário para manter uma determinada velocidade aumenta significativamente. Esta sobrecarga de energia pode conduzir a uma fadiga precoce.\nPrestar atenção à amplitude dos movimentos das pernas e das ancas permite-lhe melhorar a sua técnica. Introduzir sprints ou acelerações para encorajar movimentos mais dinâmicos da perna de trás. A incorporação de exercícios de mobilidade e propriocepção também seria interessante.\nImagine que o calcanhar de trás está preso a uma bola, levantando-a atrás de si a cada passada.',
        position: 'Retorno da perna traseira demasiado baixo',
        score: 'score_bad',
        title: 'Ângulo posterior do joelho ao bater o pé',
        recap: [
          'O joelho traseiro é demasiado aberto.',
          'Restrição da amplitude de movimentos.',
          'É necessária mais energia para manter a velocidade.',
        ],
        segmented_explanation: {
          coach_tips:
            'Imagine que o seu calcanhar traseiro está preso a uma bola, levantando-a atrás de si a cada passo.',
          consequences:
            'Um ciclo de perna restrito cria limitações na amplitude de movimento da anca, com impacto na eficiência da propulsão. Esta restrição pode também ter um efeito negativo nos joelhos e tornozelos, aumentando o risco de lesões. Ao adotar passadas mais curtas, o gasto de energia necessário para manter uma determinada velocidade aumenta significativamente. Esta sobrecarga de energia pode conduzir a uma fadiga precoce.',
          correction:
            'Prestar atenção à amplitude dos movimentos da perna e da anca ajudá-lo-á a melhorar a sua técnica. Introduzir sprints ou acelerações para encorajar movimentos mais dinâmicos da perna de trás. A incorporação de exercícios de mobilidade e de propriocepção também seria interessante.',
        },
      },
      range_good: [0, 90],
      range_okay: [90, 99],
    },
  ],
  es: [
    {
      score_number: 0.4002,
      foot_strike_pattern: 'midfoot',
      asset_id: 'MidfootO',
      texts: {
        score: 'score_okay',
        title: 'Aterrizaje del pie.',
        position: 'Aterrizaje con el pie plano',
        overstride_title:
          'Aterrizaje de pie plano frente al centro de gravedad',
        overstride_explanation:
          'Cuando el pie aterriza en un ángulo de 5° a 10°, la carga en los músculos aumenta, llevando a lesiones potenciales. Es importante reducir el ángulo y corregir la cadencia de carrera para mejorar el rendimiento y prevenir lesiones. Se recomiendan ejercicios de fortalecimiento muscular y cambios en la técnica de carrera.',
        foot_strike_explanation:
          'Cuando el mediopie aterriza en un ángulo de 6° a 10°, pueden surgir varias dificultades biomecánicas. Aunque la distribución de la fuerza es relativamente buena, si el ángulo es superior a 6°, se pueden producir efectos de frenado significativos.',
        phases: {
          impact:
            'Los efectos de frenado en el cuerpo se amplifican, aumentando la presión sobre la rodilla y el riesgo de lesiones.',
          amortisation:
            'El cuerpo necesita esforzarse para controlar la flexión de la rodilla y el tobillo. En este momento, los músculos de la pantorrilla y los otros músculos involucrados están bajo una presión significativa.',
          propulsion:
            'El impulso está comprometido, la transferencia de energía se pierde y la carrera se vuelve inestable.',
        },
        exercise: [],
      },
      type: 'RightAnkle',
      overstride: {
        mean_angle: 9.4,
        score: 'score_okay',
      },
      range_good: [-10, 5],
      range_okay: [5, 10],
    },
    {
      type: 'RightEar',
      score_number: 0.5775,
      angle: 4,
      asset_id: 'HeadOT',
      texts: {
        exercice: ['trapeze_cervical_stretch'],
        explanation:
          'Mirar demasiado al cielo mientras corres puede provocar tensión muscular, tanto en el cuello como a lo largo de la columna vertebral, lo que provoca fatiga y malestar. Elevar excesivamente la cabeza también puede afectar a la respiración, reduciendo la eficacia del aporte de oxígeno a los músculos.\nMantenga la cabeza en una posición neutra, alineada con la columna vertebral. La correcta alineación de la cabeza, el cuello y la espalda contribuye a una zancada más eficaz.\nElige un punto en el horizonte para dirigir la mirada. Esto te ayudará a mantener la posición correcta de la cabeza.',
        position: 'Mirar ligeramente hacia el cielo',
        score: 'score_okay',
        title: 'Posición de la cabeza',
        recap: [
          'Alinear cabeza, cuello y espalda.',
          'Aumento de la fatiga.',
          'Mira demasiado alto.',
          'Riesgo de tensión muscular.',
        ],
        segmented_explanation: {
          coach_tips:
            'Elija un punto en el horizonte para dirigir la mirada. Esto te ayudará a mantener la posición correcta de la cabeza.',
          consequences:
            'Mirar demasiado al cielo mientras se corre puede provocar tensión muscular, tanto en el cuello como a lo largo de la columna vertebral, lo que provoca fatiga y malestar. Elevar demasiado la cabeza también puede afectar a la respiración, reduciendo la eficacia del aporte de oxígeno a los músculos.',
          correction:
            'Mantén la cabeza en una posición neutra, alineada con la columna vertebral. Una alineación correcta de la cabeza, el cuello y la espalda contribuye a una zancada más eficiente.',
        },
      },
      range_good: [-8, 2],
      range_okay: [-12, 6],
    },
    {
      type: 'RightHip',
      score_number: 0.33,
      asset_id: 'TrunkOT',
      angle: 3,
      texts: {
        exercice: ['ab_reinforcement', 'dorsal_reinforcement'],
        explanation:
          'Una ligera inclinación del torso hacia atrás puede aumentar la presión sobre los músculos lumbares, provocando una fatiga precoz y aumentando el riesgo de lumbalgia. Para contrarrestar esta inclinación, los músculos de los glúteos pueden trabajar en exceso, generando tensión muscular en esta región. Además, esta inclinación del torso hacia atrás puede desplazar el centro de gravedad hacia atrás, lo que supone una carga adicional para los músculos de las piernas. Esto puede provocar una reducción de la capacidad del corredor para impulsar su cuerpo hacia delante, lo que se traduce en una pérdida de eficacia en el movimiento y un aumento del gasto energético.\nEl torso debe inclinarse ligeramente más hacia delante. Para ello, activa los músculos abdominales para estabilizar el torso y reducir la presión sobre la región lumbar.\nIntente tocar el cielo con la cabeza manteniendo el deseo de crecer lo más alto posible.',
        position: 'Ligeramente inclinado hacia atrás',
        score: 'score_okay',
        title: 'Posición trasera',
        recap: [
          'Busto demasiado atrás.',
          'Contraiga los músculos abdominales para estabilizar la espalda.',
          'Riesgo de lumbalgia.',
          'Sobrecarga de los músculos lumbares y glúteos.',
        ],
        segmented_explanation: {
          coach_tips:
            'Intenta tocar el cielo con la cabeza, manteniendo el deseo de crecer lo máximo posible.',
          consequences:
            'Una ligera inclinación del torso hacia atrás puede aumentar la presión sobre los músculos lumbares, provocando una fatiga precoz y aumentando el riesgo de lumbalgia. Para contrarrestar esta inclinación, los músculos de los glúteos pueden trabajar en exceso, generando tensión muscular en esta región. Además, esta inclinación del torso hacia atrás puede desplazar el centro de gravedad hacia atrás, lo que supone una carga adicional para los músculos de las piernas. Esto puede provocar una reducción de la capacidad del corredor para impulsar su cuerpo hacia delante, lo que se traduce en una pérdida de eficacia en el movimiento y un aumento del gasto energético.',
          correction:
            'El torso debe inclinarse ligeramente hacia delante. Para ello, activa los músculos abdominales para estabilizar el torso y reducir la presión sobre la región lumbar.',
        },
      },
      range_good: [6, 12],
      range_okay: [2, 16],
    },
    {
      type: 'RightElbow',
      angle: 71,
      score_number: 0.334,
      asset_id: 'ArmOT',
      texts: {
        exercice: ['bi_stretch', 'trapeze_cervical_stretch'],
        explanation:
          'El balanceo excesivo de los brazos puede sobrecargar los músculos de la parte superior del cuerpo. También puede provocar la fatiga prematura de estos músculos, aumentando el riesgo de dolores musculares. Todo ello provocará una pérdida de energía y desequilibrio durante la carrera.\nUnos movimientos más controlados minimizarán el uso excesivo de los músculos y mejorarán la eficacia de la carrera. Intenta mantener los codos ligeramente más flexionados.\nImagina tus brazos como un péndulo, oscilando de forma natural y eficiente.',
        position: 'Flexión moderada',
        score: 'score_okay',
        title: 'Posición del brazo',
        recap: [
          'Alcance restringido de la parte superior del cuerpo.',
          'Aumenta el balanceo de tus brazos.',
          'Codo demasiado apretado.',
          'Relaja los hombros y abre los puños.',
        ],
        segmented_explanation: {
          coach_tips:
            'Trabaja moviendo los brazos delante de un espejo con la intención de mantener los hombros relajados.',
          consequences:
            'Los brazos demasiado flexionados limitan la amplitud de movimiento de la parte superior del cuerpo durante la carrera. Esto puede afectar al equilibrio y la coordinación. Un movimiento inadecuado de los brazos puede provocar una tensión excesiva de la parte superior del cuerpo y una sensación de fatiga durante el ejercicio.',
          correction:
            'Tus brazos deben oscilar no sólo hacia delante, sino también hacia atrás, lo que contribuirá a la eficacia de la propulsión. Evita apretar los puños y recuerda relajar los hombros.',
        },
      },
      range_good: [75, 85],
      range_okay: [71, 89],
    },
    {
      type: 'forwardCycle',
      angle: 159,
      score_number: 0.7987755102040817,
      asset_id: 'ForwardCycleG',
      texts: {
        exercice: null,
        explanation:
          'Si la pierna delantera está demasiado flexionada al apoyar el pie en el suelo durante la carrera, puede provocar una pérdida de eficacia y aumentar potencialmente el riesgo de lesiones.\nSigue practicando, concentrándote en mantener la rodilla en esta posición.\nMientras corres, imagina un hilo invisible atado a tu rodilla, tirando suavemente hacia arriba. A cada paso, eleva ligeramente la rodilla hacia esa línea invisible y, a continuación, deja que descienda de forma natural.',
        position: 'Posición ideal',
        score: 'score_good',
        title: 'Ángulo de la rodilla delantera en el momento de apoyar el pie',
        recap: [
          'Continúa practicando en esta posición.',
          'Eficacia de funcionamiento óptima.',
          'El ángulo de la rodilla delantera es ideal.',
        ],
        segmented_explanation: {
          coach_tips:
            'Mientras corres, imagina un hilo invisible atado a tu rodilla, tirando suavemente hacia arriba. A cada paso, levanta ligeramente la rodilla hacia esa línea invisible, y luego deja que vuelva a bajar con naturalidad.',
          consequences:
            ' Si la pierna delantera está demasiado flexionada al apoyar el pie en el suelo durante la carrera, puede provocar una pérdida de eficacia y aumentar potencialmente el riesgo de lesiones.',
          correction:
            'Sigue practicando, concentrándote en mantener la rodilla en esta posición.',
        },
      },
      range_good: [131, 180],
      range_okay: [122, 131],
    },
    {
      type: 'backCycle',
      angle: 120.71435499139415,
      score_number: 0.24444444444444446,
      asset_id: 'BackCycleB',
      texts: {
        exercice: [
          'ab_reinforcement',
          'ischio_reinforcement',
          'psoas_reinforcement',
        ],
        explanation:
          'Un ciclo de piernas restringido crea limitaciones en la amplitud de movimiento de la cadera, lo que repercute en la eficacia de la propulsión. Esta restricción también puede tener un efecto perjudicial sobre las rodillas y los tobillos, aumentando el riesgo de lesiones. Al adoptar zancadas más cortas, el gasto energético necesario para mantener una velocidad determinada aumenta considerablemente. Esta sobrecarga energética puede provocar una fatiga precoz.\nPrestar atención a la amplitud de los movimientos de tus piernas y caderas te ayudará a mejorar tu técnica. Introduce sprints o aceleraciones para fomentar movimientos más dinámicos de la pierna trasera. También sería interesante incorporar ejercicios de movilidad y propiocepción.\nImagina que tu talón trasero está sujeto a una pelota, levantándola detrás de ti con cada zancada.',
        position: 'Retorno de la pierna trasera demasiado bajo',
        score: 'score_bad',
        title: 'Ángulo de la rodilla trasera en el momento de apoyar el pie',
        recap: [
          'La rodilla trasera está demasiado abierta.',
          'Restricción de la amplitud de movimiento.',
          'Se necesita más energía para mantener la velocidad.',
        ],
        segmented_explanation: {
          coach_tips:
            'Imagina que tu talón trasero está sujeto a una pelota, levantándola detrás de ti con cada zancada.',
          consequences:
            'Un ciclo de piernas restringido crea limitaciones en la amplitud de movimiento de la cadera, lo que repercute en la eficacia de la propulsión. Esta restricción también puede tener un efecto perjudicial sobre las rodillas y los tobillos, aumentando el riesgo de lesiones. Al adoptar zancadas más cortas, el gasto energético necesario para mantener una velocidad determinada aumenta considerablemente. Esta sobrecarga energética puede provocar una fatiga precoz.',
          correction:
            'Prestar atención a la amplitud de los movimientos de tus piernas y caderas te ayudará a mejorar tu técnica. Introduce sprints o aceleraciones para fomentar movimientos más dinámicos de la pierna de atrás. También sería interesante incorporar ejercicios de movilidad y propiocepción.',
        },
      },
      range_good: [0, 90],
      range_okay: [90, 99],
    },
  ],
  it: [
    {
      score_number: 0.4002,
      foot_strike_pattern: 'midfoot',
      asset_id: 'MidfootO',
      texts: {
        score: 'score_okay',
        title: 'Appoggio del piede',
        position: 'Atterraggio con il piede piatto',
        overstride_title:
          'Appoggio del piede piatto davanti al centro di gravità',
        overstride_explanation:
          "Quando il piede atterra a un angolo di 5° a 10°, il carico sui muscoli aumenta, portando a potenziali infortuni. È importante ridurre l'angolo e correggere la cadenza della corsa per migliorare le prestazioni e prevenire lesioni. Si raccomandano esercizi di rafforzamento muscolare per gestire il carico.",
        foot_strike_explanation:
          "Quando il mesopiede atterra davanti al baricentro, si possono verificare varie sfide biomeccaniche. Sebbene la distribuzione della forza sia relativamente buona, se l'angolo supera i 10°, si verificheranno importanti effetti di frenata.",
        phases: {
          impact:
            'Si amplificano gli effetti di frenata sul corpo, aumentando la pressione sul ginocchio e con rischi di infortuni.',
          amortisation:
            'Il corpo deve sforzarsi per controllare la flessione del ginocchio e della caviglia. In questo momento, i muscoli del polpaccio e gli altri muscoli coinvolti sono sottoposti a pressioni significative.',
          propulsion:
            'La spinta è compromessa, il trasferimento di energia è perso e la corsa diventa instabile.',
        },
        exercise: [],
      },
      type: 'RightAnkle',
      overstride: {
        mean_angle: 9.4,
        score: 'score_okay',
      },
      range_good: [-10, 5],
      range_okay: [5, 10],
    },
    {
      type: 'RightEar',
      score_number: 0.5775,
      angle: 4,
      asset_id: 'HeadOT',
      texts: {
        exercice: ['trapeze_cervical_stretch'],
        explanation:
          "Guardare troppo in alto mentre si corre può causare tensioni muscolari, sia nel collo che lungo la colonna vertebrale, con conseguente affaticamento e disagio. Alzare eccessivamente la testa può anche influire sulla respirazione, riducendo l'efficienza dell'apporto di ossigeno ai muscoli. Mantenete la testa in posizione neutra, allineata con la colonna vertebrale. Un corretto allineamento di testa, collo e schiena contribuisce a una falcata più efficiente. Scegliete un punto all'orizzonte per dirigere lo sguardo. Questo vi aiuterà a mantenere la posizione corretta della testa.",
        position: 'Sguardo leggermente rivolto in alto',
        score: 'score_okay',
        title: 'Posizione della testa',
        recap: [
          'Allineare testa, collo e schiena.',
          'Aumento della stanchezza.',
          'Sguardo troppo in alto.',
          'Rischio di tensione muscolare.',
        ],
        segmented_explanation: {
          coach_tips:
            "Scegliete un punto all'orizzonte per dirigere lo sguardo. Questo vi aiuterà a mantenere la giusta posizione della testa.",
          consequences:
            "Guardare troppo in alto mentre si corre può causare tensione muscolare, sia nel collo che lungo la colonna vertebrale, con conseguente affaticamento e disagio. Alzare troppo la testa può anche influire sulla respirazione, riducendo l'efficienza dell'apporto di ossigeno ai muscoli.",
          correction:
            'Mantenere la testa in posizione neutra, in linea con la colonna vertebrale. Un corretto allineamento di testa, collo e schiena contribuisce a una falcata più efficiente.',
        },
      },
      range_good: [-8, 2],
      range_okay: [-12, 6],
    },
    {
      type: 'RightHip',
      score_number: 0.33,
      asset_id: 'TrunkOT',
      angle: 3,
      texts: {
        exercice: ['ab_reinforcement', 'dorsal_reinforcement'],
        explanation:
          "Una leggera inclinazione del busto all'indietro può aumentare la pressione sui muscoli lombari, provocando un affaticamento precoce e aumentando il rischio di lombalgia. Per controbilanciare questa inclinazione, i glutei possono essere sovraccaricati, generando tensione muscolare in questa regione. Inoltre, l'inclinazione all'indietro del busto può spostare il centro di gravità all'indietro, con un carico supplementare sui muscoli delle gambe. Ciò può portare a una riduzione della capacità dell'atleta di spingere il corpo in avanti, con conseguente perdita di efficienza del movimento e aumento del dispendio energetico. Il busto dovrebbe essere leggermente più inclinato in avanti. A tal fine, attivate i muscoli addominali per stabilizzare il busto e ridurre la pressione sulla regione lombare. Cercate di toccare il cielo con la testa, immaginando di estendervi il più possibile in altezza.",
        position: "Leggermente inclinato all'indietro",
        score: 'score_okay',
        title: 'Posizione posteriore',
        recap: [
          'Busto troppo arretrato.',
          'Impegnate gli addominali per stabilizzare la schiena.',
          'Rischio di lombalgia.',
          'Sovraccarico dei lombari e dei glutei.',
        ],
        segmented_explanation: {
          coach_tips:
            'Cercate di toccare il cielo con la testa, immaginando di estendervi il più possibile in altezza.',
          consequences:
            "Una leggera inclinazione del busto all'indietro può aumentare la pressione sui lombari, provocando un affaticamento precoce e aumentando il rischio di lombalgia. Per controbilanciare questa inclinazione, i glutei potrebbero essere sovraccaricati, generando tensione muscolare in questa regione. Inoltre, l'inclinazione all'indietro del busto può spostare il centro di gravità all'indietro, con un carico supplementare sui muscoli delle gambe. Ciò può portare a una riduzione della capacità del corridore di spingere il corpo in avanti, con conseguente perdita di efficienza del movimento e aumento del dispendio energetico.",
          correction:
            'Il busto deve essere leggermente inclinato in avanti. A tal fine, attivate i muscoli addominali per stabilizzare il busto e ridurre la pressione sulla regione lombare.',
        },
      },
      range_good: [6, 12],
      range_okay: [2, 16],
    },
    {
      type: 'RightElbow',
      angle: 71,
      score_number: 0.334,
      asset_id: 'ArmOT',
      texts: {
        exercice: ['bi_stretch', 'trapeze_cervical_stretch'],
        explanation:
          "Un movimento delle braccia eccessivamente piegate limita l'ampiezza di movimento della parte superiore del corpo durante la corsa. Questo può influenzare l'equilibrio e la coordinazione. Un movimento scorretto delle braccia può causare una tensione eccessiva nella parte superiore del corpo e portare a una sensazione di affaticamento durante l'allenamento. Le tue braccia dovrebbero oscillare non solo in avanti ma anche indietro, contribuendo all'efficienza della propulsione. Evita di stringere i pugni e concentrati sul mantenere le spalle rilassate. Esercitati nel movimento delle braccia davanti a uno specchio, con l'intento di mantenere le spalle rilassate.",
        position: 'Flessione moderata',
        score: 'score_okay',
        title: 'Posizione del braccio',
        recap: [
          "Aumentare l'oscillazione delle braccia.",
          'Gomito troppo stretto.',
          "Limitazione del raggio d'azione della parte superiore del corpo.",
          'Rilassare le spalle e stringere i pugni.',
        ],
        segmented_explanation: {
          coach_tips:
            "Lavorate a muovere le braccia davanti a uno specchio con l'intenzione di mantenere le spalle rilassate.",
          consequences:
            "Le braccia troppo piegate limitano la gamma di movimenti della parte superiore del corpo durante la corsa. Questo può influire sull'equilibrio e sulla coordinazione. Un movimento inadeguato delle braccia può portare a un'eccessiva tensione della parte superiore del corpo e a una sensazione di affaticamento durante l'esercizio.",
          correction:
            "Le braccia devono oscillare non solo in avanti ma anche all'indietro, contribuendo così all'efficienza della propulsione. Evitate di stringere i pugni e ricordate di rilassare le spalle.",
        },
      },
      range_good: [75, 85],
      range_okay: [71, 89],
    },
    {
      type: 'forwardCycle',
      angle: 159,
      score_number: 0.7987755102040817,
      asset_id: 'ForwardCycleG',
      texts: {
        exercice: null,
        explanation:
          "Se la gamba anteriore è troppo piegata quando si appoggia il piede a terra durante la corsa, ciò può comportare una perdita di efficienza e aumentare il rischio di lesioni. Continuate a esercitarvi, concentrandovi sul mantenimento del ginocchio in questa posizione. Mentre correte, immaginate un filo invisibile attaccato al ginocchio che tira delicatamente verso l'alto. A ogni passo, sollevate leggermente il ginocchio verso questo filo invisibile, poi lasciatelo ricadere naturalmente.",
        position: 'Posizione ideale',
        score: 'score_good',
        title:
          "Angolo del ginocchio anteriore al momento dell'appoggio del piede",
        recap: [
          'Continuate a esercitarvi in questa posizione.',
          'Efficienza di funzionamento ottimale.',
          "L'angolo del ginocchio anteriore è ideale.",
        ],
        segmented_explanation: {
          coach_tips:
            "Mentre correte, immaginate un filo invisibile attaccato al ginocchio, che tira delicatamente verso l'alto. A ogni passo, sollevate leggermente il ginocchio verso questo filo invisibile, poi lasciatelo ricadere naturalmente.",
          consequences:
            ' Se la gamba anteriore è troppo piegata nel momento in cui appoggia il piede a terra durante la corsa, questo può portare a una perdita di efficienza e potenzialmente aumentare il rischio di lesioni.',
          correction:
            'Continuate ad esercitarvi, concentrandovi sul mantenimento del ginocchio in questa posizione.',
        },
      },
      range_good: [131, 180],
      range_okay: [122, 131],
    },
    {
      type: 'backCycle',
      angle: 120.71435499139415,
      score_number: 0.24444444444444446,
      asset_id: 'BackCycleB',
      texts: {
        exercice: [
          'ab_reinforcement',
          'ischio_reinforcement',
          'psoas_reinforcement',
        ],
        explanation:
          "Una falcata più breve, crea limitazioni nel raggio di movimento dell'anca, incidendo sull'efficienza della propulsione. Questa limitazione può anche avere un effetto negativo sulle ginocchia e sulle caviglie, aumentando il rischio di lesioni. Adottando passi più corti, il dispendio energetico necessario per mantenere una determinata velocità aumenta in modo significativo. Questo sovraccarico energetico può portare a un affaticamento precoce. Prestare attenzione all'ampiezza dei movimenti delle gambe e delle anche vi aiuterà a migliorare la vostra tecnica. Introdurre sprint o accelerazioni per incoraggiare movimenti più dinamici della gamba posteriore. Sarebbe interessante anche incorporare esercizi di mobilità e propriocezione. Immaginate che il tallone posteriore sia attaccato a una palla, che si solleva dietro di voi a ogni passo.",
        position: 'Ritorno della gamba posteriore troppo basso',
        score: 'score_bad',
        title: "Angolo del ginocchio posteriore all'appoggio del piede",
        recap: [
          'Il ginocchio posteriore è troppo aperto.',
          "Limitazione dell'ampiezza di movimento.",
          'Maggiore energia necessaria per mantenere la velocità.',
        ],
        segmented_explanation: {
          coach_tips:
            'Immaginate che il tallone posteriore sia attaccato a una palla e che lo sollevi dietro di voi a ogni passo.',
          consequences:
            "Una falcata corta crea limitazioni nel raggio di movimento dell'anca, incidendo sull'efficienza della propulsione. Questa limitazione può anche avere un effetto negativo sulle ginocchia e sulle caviglie, aumentando il rischio di lesioni. Adottando passi più corti, il dispendio energetico necessario per mantenere una determinata velocità aumenta in modo significativo. Questo sovraccarico energetico può portare a un affaticamento precoce.",
          correction:
            "Prestare attenzione all'ampiezza dei movimenti delle gambe e delle anche aiuterà a migliorare la tecnica. Introdurre sprint o accelerazioni per incoraggiare movimenti più dinamici della gamba posteriore. Sarebbe interessante anche incorporare esercizi di mobilità e di propriocezione.",
        },
      },
      range_good: [0, 90],
      range_okay: [90, 99],
    },
  ],
  ja: [
    {
      score_number: 0.4002,
      foot_strike_pattern: 'midfoot',
      asset_id: 'MidfootO',
      texts: {
        score: 'score_okay',
        title: '足の着地',
        position: '足裏全体での着地',
        overstride_title: '重心の前での足裏全体着地',
        overstride_explanation:
          '足が重心よりさらに前に配置され、角度が5°から10°になると、筋肉の働きが増加します。この負荷は痛みや怪我の原因となる可能性があります。この負荷に対応するために筋肉を強化する運動が重要です。また、ランニングドリルを通じてストライドを調整し、徐々に重心の下に戻す必要があります。',
        foot_strike_explanation:
          '足裏全体が重心の前で着地する場合、かかと着地よりも力の分散が均等であるものの、短時間の制動フェーズが生じます。後肢の筋肉が支持を安定させるために大きく関与し、地面反力が走行方向とは逆に移動します。',
        phases: {
          impact:
            '足裏全体が地面に接触し、膝が部分的に曲がっています。支持点の後ろに重心が位置しているため、負荷は主に足首の安定化を担う筋肉と結合組織に伝達されます。',
          amortisation:
            '腓腹筋とアキレス腱が衝撃を吸収し、足首関節を安定させる役割を果たします。足首は著しい背屈を受け、ハムストリングスが股関節の伸展を管理しながら膝の屈曲を制御します。',
          propulsion:
            '足が前方に位置するため、推進への移行が遅れますが、ふくらはぎや足部の筋肉が適度な推進力を提供します。ハムストリングスは股関節の伸展を制御し、エネルギーを生み出します。',
        },
        exercise: [],
      },
      type: 'RightAnkle',
      overstride: {
        mean_angle: 9.4,
        score: 'score_okay',
      },
      range_good: [-10, 5],
      range_okay: [5, 10],
    },
    {
      type: 'RightEar',
      score_number: 0.5775,
      angle: 4,
      asset_id: 'HeadOT',
      texts: {
        exercice: ['trapeze_cervical_stretch'],
        explanation:
          'ランニング中に視線が空を向きすぎると、首や背骨の筋肉に緊張を引き起こし、疲労や不快感を招く可能性があります。頭を上げすぎると呼吸が制限され、筋肉への酸素供給が減少します。\n頭、首、背骨が一直線になるように、頭を中立的な位置に保ちましょう。この整列はより効率的なストライドに寄与します。\n地平線上の特定の点を見つめることで、適切な頭の位置を維持してください。',
        position: '視線がやや空向き',
        score: 'score_okay',
        title: '頭の位置',
        recap: [
          '頭、首、背骨を整列。',
          '視線が高すぎる。',
          '疲労の増加。',
          '筋肉緊張のリスク。',
        ],
        segmented_explanation: {
          coach_tips:
            '地平線上の特定の点を見つめることで、適切な頭の位置を維持してください。',
          consequences:
            'ランニング中に視線が空を向きすぎると、首や背骨の筋肉に緊張を引き起こし、疲労や不快感を招く可能性があります。頭を上げすぎると呼吸が制限され、筋肉への酸素供給が減少します。',
          correction:
            '頭、首、背骨が一直線になるように、頭を中立的な位置に保ちましょう。この整列はより効率的なストライドに寄与します。',
        },
      },
      range_good: [-8, 2],
      range_okay: [-12, 6],
    },
    {
      type: 'RightHip',
      score_number: 0.33,
      asset_id: 'TrunkOT',
      angle: 3,
      texts: {
        exercice: ['ab_reinforcement', 'dorsal_reinforcement'],
        explanation:
          '体幹が十分に前傾していないと、下背部の筋肉に過度な負荷がかかり、早期疲労や腰痛のリスクが高まります。これを補うために臀部の筋肉が過剰に働き、筋肉の緊張が生じる可能性があります。また、体幹が後方に傾くと重心が後方に移動し、脚の筋肉に過度な負担がかかります。このため、推進力が低下し、効率が悪化し、エネルギー消費が増加します。\n体幹をやや前傾させる必要があります。このために、腹筋を活性化して胸部を安定させ、腰部への負担を軽減してください。\n頭を空に向けながら、可能な限り体を伸ばす意識を持ちましょう。',
        position: '前傾が足りない',
        score: 'score_okay',
        title: '体幹の位置',
        recap: [
          '腹筋を活性化して背中を安定させる。',
          '腰部と臀部の筋肉に過剰な負担。',
          '腰痛のリスク。',
          '体幹が後方に傾きすぎている。',
        ],
        segmented_explanation: {
          coach_tips:
            '頭を空に向けながら、可能な限り体を伸ばす意識を持ちましょう。',
          consequences:
            '体幹が十分に前傾していないと、下背部の筋肉に過度な負荷がかかり、早期疲労や腰痛のリスクが高まります。これを補うために臀部の筋肉が過剰に働き、筋肉の緊張が生じる可能性があります。また、体幹が後方に傾くと重心が後方に移動し、脚の筋肉に過度な負担がかかります。',
          correction:
            '体幹をやや前傾させる必要があります。このために、腹筋を活性化して胸部を安定させ、腰部への負担を軽減してください。',
        },
      },
      range_good: [6, 12],
      range_okay: [2, 16],
    },
    {
      type: 'RightElbow',
      angle: 71,
      score_number: 0.334,
      asset_id: 'ArmOT',
      texts: {
        exercice: ['bi_stretch', 'trapeze_cervical_stretch'],
        explanation:
          '腕が過度に曲がりすぎると、上半身の可動域が制限され、バランスや協調性に影響を与える可能性があります。不適切な腕の動きは上半身に過剰な緊張を引き起こし、トレーニング中の疲労感を増加させることがあります。\n腕は前方だけでなく後方にも振ることで推進力の効率を高める必要があります。拳を握りすぎず、肩をリラックスさせることを意識してください。\n鏡の前で肩をリラックスさせながら腕の動きを練習するとよいでしょう。',
        position: '適度な屈曲',
        score: 'score_okay',
        title: '腕の位置',
        recap: [
          '肘が閉じすぎている。',
          '腕の振りを増やす。',
          '肩をリラックスし、拳を緩める。',
          '上半身の可動域が制限されている。',
        ],
        segmented_explanation: {
          coach_tips:
            '鏡の前で肩をリラックスさせながら腕の動きを練習するとよいでしょう。',
          consequences:
            '腕が過度に曲がりすぎると、上半身の可動域が制限され、バランスや協調性に影響を与える可能性があります。不適切な腕の動きは上半身に過剰な緊張を引き起こし、トレーニング中の疲労感を増加させることがあります。',
          correction:
            '腕は前方だけでなく後方にも振ることで推進力の効率を高める必要があります。拳を握りすぎず、肩をリラックスさせることを意識してください。',
        },
      },
      range_good: [75, 85],
      range_okay: [71, 89],
    },
    {
      type: 'forwardCycle',
      angle: 159,
      score_number: 0.7987755102040817,
      asset_id: 'ForwardCycleG',
      texts: {
        exercice: null,
        explanation:
          '先行脚が着地時に曲がりすぎている場合、効率が低下し、怪我のリスクが高まる可能性があります。\nこの姿勢を維持することを意識しながら練習を続けてください。\nランニング中に見えない糸が膝に繋がっていると想像し、その糸が上に引っ張られるイメージで膝を軽く持ち上げ、自然に降ろしてください。',
        position: '理想的な位置',
        score: 'score_good',
        title: '着地時の先行膝の角度',
        recap: [
          'この姿勢で練習を続ける。',
          '最適なランニング効率。',
          '先行膝の角度が理想的。',
        ],
        segmented_explanation: {
          coach_tips:
            'ランニング中に見えない糸が膝に繋がっていると想像し、その糸が上に引っ張られるイメージで膝を軽く持ち上げ、自然に降ろしてください。',
          consequences:
            '先行脚が着地時に曲がりすぎている場合、効率が低下し、怪我のリスクが高まる可能性があります。',
          correction:
            'この姿勢を維持することを意識しながら練習を続けてください。',
        },
      },
      range_good: [131, 180],
      range_okay: [122, 131],
    },
    {
      type: 'backCycle',
      angle: 120.71435499139415,
      score_number: 0.24444444444444446,
      asset_id: 'BackCycleB',
      texts: {
        exercice: [
          'ab_reinforcement',
          'ischio_reinforcement',
          'psoas_reinforcement',
        ],
        explanation:
          '脚の動きが制限されると、股関節の可動域に制限が生じ、推進効率に影響を与えます。この制限は膝や足首にも悪影響を及ぼし、怪我のリスクを増加させる可能性があります。短いストライドを採用すると、一定の速度を維持するために必要なエネルギー消費が大幅に増加し、早期疲労につながることがあります。\n脚や股関節の可動域に注意を払いながら動きを改善しましょう。スプリントや加速のトレーニングを取り入れ、後脚のよりダイナミックな動きを促進します。モビリティや固有受容感覚のエクササイズも役立ちます。\n後ろのかかとが風船に繋がれていると想像し、毎回のストライドでそれを引き上げるイメージを持ちましょう。',
        position: '低いヒールキック',
        score: 'score_bad',
        title: '着地時の後膝の角度',
        recap: [
          '速度を維持するためにエネルギーがより必要。',
          '動きの幅が制限されている。',
          'スプリントエクササイズで幅を改善。',
          '後ろ膝が開きすぎている。',
        ],
        segmented_explanation: {
          coach_tips:
            '後ろのかかとが風船に繋がれていると想像し、毎回のストライドでそれを引き上げるイメージを持ちましょう。',
          consequences:
            '脚の動きが制限されると、股関節の可動域に制限が生じ、推進効率に影響を与えます。この制限は膝や足首にも悪影響を及ぼし、怪我のリスクを増加させる可能性があります。短いストライドを採用すると、一定の速度を維持するために必要なエネルギー消費が大幅に増加し、早期疲労につながることがあります。',
          correction:
            '脚や股関節の可動域に注意を払いながら動きを改善しましょう。スプリントや加速のトレーニングを取り入れ、後脚のよりダイナミックな動きを促進します。モビリティや固有受容感覚のエクササイズも役立ちます。',
        },
      },
      range_good: [0, 90],
      range_okay: [90, 99],
    },
  ],
  ko: [
    {
      score_number: 0.4002,
      foot_strike_pattern: 'midfoot',
      asset_id: 'MidfootO',
      texts: {
        score: 'score_okay',
        title: '발 착지',
        position: '발바닥 착지',
        overstride_title: '체중 중심 앞에서의 발바닥 착지',
        overstride_explanation:
          '발이 체중 중심보다 더 앞쪽에 놓이고, 각도가 5°에서 10° 사이일 경우 근육이 더 많은 작업을 수행해야 합니다. 이로 인해 통증이나 부상이 발생할 수 있습니다. 근육이 이 과부하를 더 잘 지탱할 수 있도록 강화 운동을 수행하는 것이 중요합니다. 러닝 드릴도 필요하며, 이를 통해 보폭을 조정하고 체중 중심 아래로 점차적으로 복귀해야 합니다.',
        foot_strike_explanation:
          '발바닥 착지가 체중 중심 앞에서 발생하면, 뒤꿈치 착지보다 힘의 분배가 더 고르게 이루어지지만 짧은 브레이크 단계가 발생합니다. 후방 다리 근육이 지지 안정화를 위해 과도하게 동원됩니다.',
        phases: {
          impact:
            '발바닥이 무릎이 약간 구부러진 상태에서 지면과 접촉합니다. 체중 중심이 지지점 뒤에 위치하면서 발목의 안정화 근육과 결합 조직에 하중이 주로 전달됩니다.',
          amortisation:
            '비복근과 아킬레스건이 충격을 흡수하고 발목 관절을 안정화하는 데 특히 스트레스를 받습니다. 햄스트링은 엉덩이 신전을 관리하고 무릎 굽힘을 제어하는 데 도움을 줍니다.',
          propulsion:
            '발의 전방 위치로 인해 추진으로의 전환이 느려지지만, 종아리와 발 근육은 여전히 적절한 추진력을 제공합니다. 햄스트링은 엉덩이 신전을 제어하고 추진력을 생성하는 데 적극적으로 기여합니다.',
        },
        exercise: [],
      },
      type: 'RightAnkle',
      overstride: {
        mean_angle: 9.4,
        score: 'score_okay',
      },
      range_good: [-10, 5],
      range_okay: [5, 10],
    },
    {
      type: 'RightEar',
      score_number: 0.5775,
      angle: 4,
      asset_id: 'HeadOT',
      texts: {
        exercice: ['trapeze_cervical_stretch'],
        explanation:
          '달리는 동안 지나치게 하늘을 보는 자세는 목과 척추 근육에 긴장을 유발하고 피로와 불편함을 초래할 수 있습니다. 머리를 과도하게 올리면 호흡에 영향을 미쳐 산소 전달 효율이 감소할 수 있습니다.\n머리를 척추와 정렬된 중립 위치에 유지하세요. 머리, 목, 등의 올바른 정렬은 보다 효율적인 주행 자세를 제공합니다.\n수평선의 한 점에 시선을 고정하세요. 이는 적절한 머리 위치를 유지하는 데 도움을 줍니다.',
        position: '시선이 약간 하늘을 향함',
        score: 'score_okay',
        title: '머리 위치',
        recap: [
          '머리, 목, 등을 정렬하세요.',
          '시선이 너무 높음.',
          '피로 증가.',
          '근육 긴장 위험.',
        ],
        segmented_explanation: {
          coach_tips:
            '수평선의 한 점에 시선을 고정하세요. 이는 적절한 머리 위치를 유지하는 데 도움을 줍니다.',
          consequences:
            '달리는 동안 지나치게 하늘을 보는 자세는 목과 척추 근육에 긴장을 유발하고 피로와 불편함을 초래할 수 있습니다. 머리를 과도하게 올리면 호흡에 영향을 미쳐 산소 전달 효율이 감소할 수 있습니다.',
          correction:
            '머리를 척추와 정렬된 중립 위치에 유지하세요. 머리, 목, 등의 올바른 정렬은 보다 효율적인 주행 자세를 제공합니다.',
        },
      },
      range_good: [-8, 2],
      range_okay: [-12, 6],
    },
    {
      type: 'RightHip',
      score_number: 0.33,
      asset_id: 'TrunkOT',
      angle: 3,
      texts: {
        exercice: ['ab_reinforcement', 'dorsal_reinforcement'],
        explanation:
          '몸통이 앞으로 충분히 기울어지지 않으면 허리 근육에 과도한 부담을 주어 조기 피로와 허리 통증의 위험을 초래할 수 있습니다. 뒤로 기울어진 자세를 보완하기 위해 둔근이 과도하게 사용될 수 있으며, 이는 근육 긴장을 유발할 수 있습니다. 또한, 몸통이 뒤로 기울어지면 무게 중심이 뒤로 이동하여 다리 근육에 과부하를 주고, 러닝 효율성을 감소시키며 에너지 소비를 증가시킬 수 있습니다.\n몸통은 약간 앞으로 기울어져야 합니다. 이를 위해 복부 근육을 활성화하여 가슴을 안정시키고 허리 부위의 압박을 줄이세요.\n머리로 하늘에 닿으려고 하듯 최대한 몸을 길게 늘이는 것을 상상해 보세요.',
        position: '앞으로 충분히 기울어지지 않음',
        score: 'score_okay',
        title: '몸통 자세',
        recap: [
          '허리 근육을 안정시키기 위해 복부를 단단히 유지하세요.',
          '허리와 둔근에 과부하.',
          '허리 통증의 위험.',
          '몸통이 너무 뒤로 기울어짐.',
        ],
        segmented_explanation: {
          coach_tips:
            '머리로 하늘에 닿으려고 하듯 최대한 몸을 길게 늘이는 것을 상상해 보세요.',
          consequences:
            '몸통이 앞으로 충분히 기울어지지 않으면 허리 근육에 과도한 부담을 주어 조기 피로와 허리 통증의 위험을 초래할 수 있습니다. 뒤로 기울어진 자세를 보완하기 위해 둔근이 과도하게 사용될 수 있으며, 이는 근육 긴장을 유발할 수 있습니다. 또한, 몸통이 뒤로 기울어지면 무게 중심이 뒤로 이동하여 다리 근육에 과부하를 주고, 러닝 효율성을 감소시키며 에너지 소비를 증가시킬 수 있습니다.',
          correction:
            '몸통은 약간 앞으로 기울어져야 합니다. 이를 위해 복부 근육을 활성화하여 가슴을 안정시키고 허리 부위의 압박을 줄이세요.',
        },
      },
      range_good: [6, 12],
      range_okay: [2, 16],
    },
    {
      type: 'RightElbow',
      angle: 71,
      score_number: 0.334,
      asset_id: 'ArmOT',
      texts: {
        exercice: ['bi_stretch', 'trapeze_cervical_stretch'],
        explanation:
          '과도하게 구부러진 팔 움직임은 달리기 중 상체의 움직임 범위를 제한할 수 있습니다. 이는 균형과 협응에 영향을 미칠 수 있습니다. 부적절한 팔 움직임은 상체에 과도한 긴장을 초래하고 훈련 중 피로감을 증가시킬 수 있습니다. 팔은 앞으로만 흔들리는 것이 아니라 뒤로도 흔들려야 하며, 이는 추진 효율성을 높이는 데 기여합니다. 주먹을 꽉 쥐지 말고 어깨를 이완시키는 데 집중하세요. 거울 앞에서 팔 움직임을 연습하면서 어깨를 이완시키는 의도를 유지하세요.',
        position: '적당한 굽힘',
        score: 'score_okay',
        title: '팔의 위치',
        recap: [
          '팔꿈치가 너무 닫힘.',
          '팔 흔들림 증가.',
          '어깨를 이완하고 주먹을 느슨하게.',
          '상체 움직임 제한.',
        ],
        segmented_explanation: {
          coach_tips:
            '거울 앞에서 팔 움직임을 연습하면서 어깨를 이완시키는 의도를 유지하세요.',
          consequences:
            '과도하게 구부러진 팔 움직임은 달리기 중 상체의 움직임 범위를 제한할 수 있습니다. 이는 균형과 협응에 영향을 미칠 수 있습니다. 부적절한 팔 움직임은 상체에 과도한 긴장을 초래하고 훈련 중 피로감을 증가시킬 수 있습니다.',
          correction:
            '팔은 앞으로만 흔들리는 것이 아니라 뒤로도 흔들려야 하며, 이는 추진 효율성을 높이는 데 기여합니다. 주먹을 꽉 쥐지 말고 어깨를 이완시키는 데 집중하세요.',
        },
      },
      range_good: [75, 85],
      range_okay: [71, 89],
    },
    {
      type: 'forwardCycle',
      angle: 159,
      score_number: 0.7987755102040817,
      asset_id: 'ForwardCycleG',
      texts: {
        exercice: null,
        explanation:
          '앞다리가 너무 굽혀진 상태에서 착지하면 효율성이 떨어지고 부상의 위험이 증가할 수 있습니다. \n이 위치를 유지하며 연습을 계속하세요.\n달리기 중 무릎에 투명한 끈이 묶여 있다고 상상하며 이 끈이 위로 살짝 당겨지는 것을 느끼며 무릎을 약간 들어 올리세요. 그런 다음 자연스럽게 내려놓으세요.',
        position: '이상적인 위치',
        score: 'score_good',
        title: '발 착지 시 앞무릎 각도',
        recap: [
          '이 위치를 계속 연습하세요.',
          '최적의 달리기 효율성.',
          '앞무릎 각도가 이상적임.',
        ],
        segmented_explanation: {
          coach_tips:
            '달리기 중 무릎에 투명한 끈이 묶여 있다고 상상하며 이 끈이 위로 살짝 당겨지는 것을 느끼며 무릎을 약간 들어 올리세요. 그런 다음 자연스럽게 내려놓으세요.',
          consequences:
            '앞다리가 너무 굽혀진 상태에서 착지하면 효율성이 떨어지고 부상의 위험이 증가할 수 있습니다.',
          correction: '이 위치를 유지하며 연습을 계속하세요.',
        },
      },
      range_good: [131, 180],
      range_okay: [122, 131],
    },
    {
      type: 'backCycle',
      angle: 120.71435499139415,
      score_number: 0.24444444444444446,
      asset_id: 'BackCycleB',
      texts: {
        exercice: [
          'ab_reinforcement',
          'ischio_reinforcement',
          'psoas_reinforcement',
        ],
        explanation:
          '제한된 다리 움직임은 엉덩이의 움직임 범위를 제한하여 추진 효율성을 저하시킬 수 있습니다. 이러한 제한은 무릎과 발목에 부정적인 영향을 미치고 부상의 위험을 증가시킬 수 있습니다. 짧은 보폭은 일정한 속도를 유지하는 데 더 많은 에너지를 요구하여 조기 피로를 초래할 수 있습니다. 다리와 엉덩이 움직임의 범위를 신경 쓰는 것이 더 나은 기술을 갖추는 데 도움이 됩니다. 스프린트나 가속 운동을 포함하여 뒤쪽 다리의 더 역동적인 움직임을 유도하세요. 또한 이동성과 고유수용성 운동을 포함하는 것이 유익할 것입니다. 뒷꿈치에 풍선을 묶은 것처럼 상상하며 매 stride마다 들어 올리세요.',
        position: '낮은 뒷발 킥',
        score: 'score_bad',
        title: '발 착지 시 뒷무릎 각도',
        recap: [
          '속도 유지에 더 많은 에너지 필요.',
          '움직임의 범위 제한.',
          '범위를 향상시키기 위해 스프린트 운동 필요.',
          '뒤쪽 무릎이 너무 열림.',
        ],
        segmented_explanation: {
          coach_tips:
            '뒷꿈치에 풍선을 묶은 것처럼 상상하며 매 stride마다 들어 올리세요.',
          consequences:
            '제한된 다리 움직임은 엉덩이의 움직임 범위를 제한하여 추진 효율성을 저하시킬 수 있습니다. 이러한 제한은 무릎과 발목에 부정적인 영향을 미치고 부상의 위험을 증가시킬 수 있습니다. 짧은 보폭은 일정한 속도를 유지하는 데 더 많은 에너지를 요구하여 조기 피로를 초래할 수 있습니다.',
          correction:
            '다리와 엉덩이 움직임의 범위를 신경 쓰는 것이 더 나은 기술을 갖추는 데 도움이 됩니다. 스프린트나 가속 운동을 포함하여 뒤쪽 다리의 더 역동적인 움직임을 유도하세요.',
        },
      },
      range_good: [0, 90],
      range_okay: [90, 99],
    },
  ],
};

export const progressSegments = [
  {
    item: 'RightEar',
    score_number: 0.8,
    texts: {
      score: 'score_good',
    },
  },
  {
    item: 'RightHip',
    score_number: 0.895,
    texts: {
      score: 'score_good',
    },
  },
  {
    item: 'RightElbow',
    score_number: 0.64,
    texts: {
      score: 'score_okay',
      title: 'Position des bras',
    },
  },
  {
    item: 'forwardCycle',
    score_number: 0.92,
    texts: {
      score: 'score_good',
    },
  },
  {
    item: 'backCycle',

    score_number: 0.62,

    texts: {
      score: 'score_okay',
    },
  },
];

export const jointAngles = {
  side: 'both',
  left_ankle: [
    {
      x: 0,
      y: -9.51,
    },
    {
      x: 1,
      y: -10.83,
    },
    {
      x: 2,
      y: -12.14,
    },
    {
      x: 3,
      y: -13.66,
    },
    {
      x: 4,
      y: -15.36,
    },
    {
      x: 5,
      y: -17.07,
    },
    {
      x: 6,
      y: -18.79,
    },
    {
      x: 7,
      y: -20.5,
    },
    {
      x: 8,
      y: -22.05,
    },
    {
      x: 9,
      y: -23.47,
    },
    {
      x: 10,
      y: -24.85,
    },
    {
      x: 11,
      y: -25.85,
    },
    {
      x: 12,
      y: -26.86,
    },
    {
      x: 13,
      y: -27.64,
    },
    {
      x: 14,
      y: -28.3,
    },
    {
      x: 15,
      y: -28.93,
    },
    {
      x: 16,
      y: -29.42,
    },
    {
      x: 17,
      y: -29.9,
    },
    {
      x: 18,
      y: -30.38,
    },
    {
      x: 19,
      y: -30.86,
    },
    {
      x: 20,
      y: -31.38,
    },
    {
      x: 21,
      y: -32,
    },
    {
      x: 22,
      y: -32.63,
    },
    {
      x: 23,
      y: -33.41,
    },
    {
      x: 24,
      y: -34.26,
    },
    {
      x: 25,
      y: -35.16,
    },
    {
      x: 26,
      y: -36.25,
    },
    {
      x: 27,
      y: -37.33,
    },
    {
      x: 28,
      y: -38.56,
    },
    {
      x: 29,
      y: -39.83,
    },
    {
      x: 30,
      y: -41.13,
    },
    {
      x: 31,
      y: -42.49,
    },
    {
      x: 32,
      y: -43.85,
    },
    {
      x: 33,
      y: -45.17,
    },
    {
      x: 34,
      y: -46.48,
    },
    {
      x: 35,
      y: -47.72,
    },
    {
      x: 36,
      y: -48.83,
    },
    {
      x: 37,
      y: -49.95,
    },
    {
      x: 38,
      y: -50.77,
    },
    {
      x: 39,
      y: -51.56,
    },
    {
      x: 40,
      y: -52.16,
    },
    {
      x: 41,
      y: -52.5,
    },
    {
      x: 42,
      y: -52.83,
    },
    {
      x: 43,
      y: -52.67,
    },
    {
      x: 44,
      y: -52.47,
    },
    {
      x: 45,
      y: -52,
    },
    {
      x: 46,
      y: -51.22,
    },
    {
      x: 47,
      y: -50.44,
    },
    {
      x: 48,
      y: -49.13,
    },
    {
      x: 49,
      y: -47.81,
    },
    {
      x: 50,
      y: -46.28,
    },
    {
      x: 51,
      y: -44.57,
    },
    {
      x: 52,
      y: -42.85,
    },
    {
      x: 53,
      y: -40.98,
    },
    {
      x: 54,
      y: -39.12,
    },
    {
      x: 55,
      y: -37.35,
    },
    {
      x: 56,
      y: -35.64,
    },
    {
      x: 57,
      y: -33.96,
    },
    {
      x: 58,
      y: -32.67,
    },
    {
      x: 59,
      y: -31.38,
    },
    {
      x: 60,
      y: -30.45,
    },
    {
      x: 61,
      y: -29.76,
    },
    {
      x: 62,
      y: -29.16,
    },
    {
      x: 63,
      y: -29.17,
    },
    {
      x: 64,
      y: -29.18,
    },
    {
      x: 65,
      y: -29.66,
    },
    {
      x: 66,
      y: -30.39,
    },
    {
      x: 67,
      y: -31.22,
    },
    {
      x: 68,
      y: -32.54,
    },
    {
      x: 69,
      y: -33.85,
    },
    {
      x: 70,
      y: -35.38,
    },
    {
      x: 71,
      y: -36.99,
    },
    {
      x: 72,
      y: -38.57,
    },
    {
      x: 73,
      y: -40.05,
    },
    {
      x: 74,
      y: -41.53,
    },
    {
      x: 75,
      y: -42.6,
    },
    {
      x: 76,
      y: -43.53,
    },
    {
      x: 77,
      y: -44.22,
    },
    {
      x: 78,
      y: -44.3,
    },
    {
      x: 79,
      y: -44.38,
    },
    {
      x: 80,
      y: -43.67,
    },
    {
      x: 81,
      y: -42.76,
    },
    {
      x: 82,
      y: -41.56,
    },
    {
      x: 83,
      y: -39.74,
    },
    {
      x: 84,
      y: -37.92,
    },
    {
      x: 85,
      y: -35.57,
    },
    {
      x: 86,
      y: -33.14,
    },
    {
      x: 87,
      y: -30.66,
    },
    {
      x: 88,
      y: -28.11,
    },
    {
      x: 89,
      y: -25.55,
    },
    {
      x: 90,
      y: -23.33,
    },
    {
      x: 91,
      y: -21.15,
    },
    {
      x: 92,
      y: -19.27,
    },
    {
      x: 93,
      y: -17.77,
    },
    {
      x: 94,
      y: -16.28,
    },
    {
      x: 95,
      y: -15.55,
    },
    {
      x: 96,
      y: -14.85,
    },
    {
      x: 97,
      y: -14.52,
    },
    {
      x: 98,
      y: -14.6,
    },
    {
      x: 99,
      y: -14.67,
    },
  ],
  left_elbow: [
    {
      x: 0,
      y: -86.34,
    },
    {
      x: 1,
      y: -86.05,
    },
    {
      x: 2,
      y: -85.76,
    },
    {
      x: 3,
      y: -85.51,
    },
    {
      x: 4,
      y: -85.3,
    },
    {
      x: 5,
      y: -85.09,
    },
    {
      x: 6,
      y: -85.08,
    },
    {
      x: 7,
      y: -85.06,
    },
    {
      x: 8,
      y: -85.22,
    },
    {
      x: 9,
      y: -85.51,
    },
    {
      x: 10,
      y: -85.85,
    },
    {
      x: 11,
      y: -86.54,
    },
    {
      x: 12,
      y: -87.24,
    },
    {
      x: 13,
      y: -88.24,
    },
    {
      x: 14,
      y: -89.41,
    },
    {
      x: 15,
      y: -90.67,
    },
    {
      x: 16,
      y: -92.38,
    },
    {
      x: 17,
      y: -94.09,
    },
    {
      x: 18,
      y: -96.14,
    },
    {
      x: 19,
      y: -98.37,
    },
    {
      x: 20,
      y: -100.68,
    },
    {
      x: 21,
      y: -103.33,
    },
    {
      x: 22,
      y: -105.98,
    },
    {
      x: 23,
      y: -108.8,
    },
    {
      x: 24,
      y: -111.67,
    },
    {
      x: 25,
      y: -114.53,
    },
    {
      x: 26,
      y: -117.36,
    },
    {
      x: 27,
      y: -120.19,
    },
    {
      x: 28,
      y: -122.79,
    },
    {
      x: 29,
      y: -125.32,
    },
    {
      x: 30,
      y: -127.71,
    },
    {
      x: 31,
      y: -129.76,
    },
    {
      x: 32,
      y: -131.82,
    },
    {
      x: 33,
      y: -133.45,
    },
    {
      x: 34,
      y: -134.98,
    },
    {
      x: 35,
      y: -136.33,
    },
    {
      x: 36,
      y: -137.34,
    },
    {
      x: 37,
      y: -138.36,
    },
    {
      x: 38,
      y: -138.95,
    },
    {
      x: 39,
      y: -139.49,
    },
    {
      x: 40,
      y: -139.83,
    },
    {
      x: 41,
      y: -139.9,
    },
    {
      x: 42,
      y: -139.97,
    },
    {
      x: 43,
      y: -139.59,
    },
    {
      x: 44,
      y: -139.17,
    },
    {
      x: 45,
      y: -138.52,
    },
    {
      x: 46,
      y: -137.57,
    },
    {
      x: 47,
      y: -136.62,
    },
    {
      x: 48,
      y: -135.05,
    },
    {
      x: 49,
      y: -133.46,
    },
    {
      x: 50,
      y: -131.49,
    },
    {
      x: 51,
      y: -129.13,
    },
    {
      x: 52,
      y: -126.76,
    },
    {
      x: 53,
      y: -123.52,
    },
    {
      x: 54,
      y: -120.28,
    },
    {
      x: 55,
      y: -116.56,
    },
    {
      x: 56,
      y: -112.45,
    },
    {
      x: 57,
      y: -108.31,
    },
    {
      x: 58,
      y: -103.68,
    },
    {
      x: 59,
      y: -99.04,
    },
    {
      x: 60,
      y: -94.5,
    },
    {
      x: 61,
      y: -90.02,
    },
    {
      x: 62,
      y: -85.65,
    },
    {
      x: 63,
      y: -81.99,
    },
    {
      x: 64,
      y: -78.33,
    },
    {
      x: 65,
      y: -75.41,
    },
    {
      x: 66,
      y: -72.89,
    },
    {
      x: 67,
      y: -70.56,
    },
    {
      x: 68,
      y: -69.16,
    },
    {
      x: 69,
      y: -67.75,
    },
    {
      x: 70,
      y: -67.02,
    },
    {
      x: 71,
      y: -66.58,
    },
    {
      x: 72,
      y: -66.32,
    },
    {
      x: 73,
      y: -66.68,
    },
    {
      x: 74,
      y: -67.03,
    },
    {
      x: 75,
      y: -67.86,
    },
    {
      x: 76,
      y: -68.84,
    },
    {
      x: 77,
      y: -69.94,
    },
    {
      x: 78,
      y: -71.38,
    },
    {
      x: 79,
      y: -72.82,
    },
    {
      x: 80,
      y: -74.49,
    },
    {
      x: 81,
      y: -76.22,
    },
    {
      x: 82,
      y: -77.98,
    },
    {
      x: 83,
      y: -79.81,
    },
    {
      x: 84,
      y: -81.64,
    },
    {
      x: 85,
      y: -83.4,
    },
    {
      x: 86,
      y: -85.16,
    },
    {
      x: 87,
      y: -86.83,
    },
    {
      x: 88,
      y: -88.35,
    },
    {
      x: 89,
      y: -89.88,
    },
    {
      x: 90,
      y: -91.09,
    },
    {
      x: 91,
      y: -92.27,
    },
    {
      x: 92,
      y: -93.28,
    },
    {
      x: 93,
      y: -94.04,
    },
    {
      x: 94,
      y: -94.81,
    },
    {
      x: 95,
      y: -95.16,
    },
    {
      x: 96,
      y: -95.48,
    },
    {
      x: 97,
      y: -95.62,
    },
    {
      x: 98,
      y: -95.53,
    },
    {
      x: 99,
      y: -95.44,
    },
  ],
  left_hip: [
    {
      x: 0,
      y: 11.95,
    },
    {
      x: 1,
      y: 11.84,
    },
    {
      x: 2,
      y: 11.73,
    },
    {
      x: 3,
      y: 11.62,
    },
    {
      x: 4,
      y: 11.5,
    },
    {
      x: 5,
      y: 11.38,
    },
    {
      x: 6,
      y: 11.21,
    },
    {
      x: 7,
      y: 11.04,
    },
    {
      x: 8,
      y: 10.81,
    },
    {
      x: 9,
      y: 10.55,
    },
    {
      x: 10,
      y: 10.27,
    },
    {
      x: 11,
      y: 9.88,
    },
    {
      x: 12,
      y: 9.48,
    },
    {
      x: 13,
      y: 9,
    },
    {
      x: 14,
      y: 8.45,
    },
    {
      x: 15,
      y: 7.88,
    },
    {
      x: 16,
      y: 7.17,
    },
    {
      x: 17,
      y: 6.46,
    },
    {
      x: 18,
      y: 5.65,
    },
    {
      x: 19,
      y: 4.78,
    },
    {
      x: 20,
      y: 3.89,
    },
    {
      x: 21,
      y: 2.9,
    },
    {
      x: 22,
      y: 1.91,
    },
    {
      x: 23,
      y: 0.85,
    },
    {
      x: 24,
      y: -0.23,
    },
    {
      x: 25,
      y: -1.31,
    },
    {
      x: 26,
      y: -2.42,
    },
    {
      x: 27,
      y: -3.53,
    },
    {
      x: 28,
      y: -4.62,
    },
    {
      x: 29,
      y: -5.7,
    },
    {
      x: 30,
      y: -6.76,
    },
    {
      x: 31,
      y: -7.76,
    },
    {
      x: 32,
      y: -8.75,
    },
    {
      x: 33,
      y: -9.63,
    },
    {
      x: 34,
      y: -10.48,
    },
    {
      x: 35,
      y: -11.26,
    },
    {
      x: 36,
      y: -11.92,
    },
    {
      x: 37,
      y: -12.58,
    },
    {
      x: 38,
      y: -13.04,
    },
    {
      x: 39,
      y: -13.47,
    },
    {
      x: 40,
      y: -13.8,
    },
    {
      x: 41,
      y: -13.98,
    },
    {
      x: 42,
      y: -14.15,
    },
    {
      x: 43,
      y: -14.09,
    },
    {
      x: 44,
      y: -14,
    },
    {
      x: 45,
      y: -13.8,
    },
    {
      x: 46,
      y: -13.46,
    },
    {
      x: 47,
      y: -13.11,
    },
    {
      x: 48,
      y: -12.54,
    },
    {
      x: 49,
      y: -11.96,
    },
    {
      x: 50,
      y: -11.28,
    },
    {
      x: 51,
      y: -10.49,
    },
    {
      x: 52,
      y: -9.71,
    },
    {
      x: 53,
      y: -8.77,
    },
    {
      x: 54,
      y: -7.83,
    },
    {
      x: 55,
      y: -6.82,
    },
    {
      x: 56,
      y: -5.77,
    },
    {
      x: 57,
      y: -4.71,
    },
    {
      x: 58,
      y: -3.59,
    },
    {
      x: 59,
      y: -2.46,
    },
    {
      x: 60,
      y: -1.32,
    },
    {
      x: 61,
      y: -0.17,
    },
    {
      x: 62,
      y: 0.99,
    },
    {
      x: 63,
      y: 2.14,
    },
    {
      x: 64,
      y: 3.29,
    },
    {
      x: 65,
      y: 4.42,
    },
    {
      x: 66,
      y: 5.54,
    },
    {
      x: 67,
      y: 6.64,
    },
    {
      x: 68,
      y: 7.68,
    },
    {
      x: 69,
      y: 8.73,
    },
    {
      x: 70,
      y: 9.69,
    },
    {
      x: 71,
      y: 10.63,
    },
    {
      x: 72,
      y: 11.54,
    },
    {
      x: 73,
      y: 12.33,
    },
    {
      x: 74,
      y: 13.13,
    },
    {
      x: 75,
      y: 13.79,
    },
    {
      x: 76,
      y: 14.41,
    },
    {
      x: 77,
      y: 14.98,
    },
    {
      x: 78,
      y: 15.41,
    },
    {
      x: 79,
      y: 15.85,
    },
    {
      x: 80,
      y: 16.12,
    },
    {
      x: 81,
      y: 16.36,
    },
    {
      x: 82,
      y: 16.55,
    },
    {
      x: 83,
      y: 16.61,
    },
    {
      x: 84,
      y: 16.68,
    },
    {
      x: 85,
      y: 16.62,
    },
    {
      x: 86,
      y: 16.55,
    },
    {
      x: 87,
      y: 16.44,
    },
    {
      x: 88,
      y: 16.27,
    },
    {
      x: 89,
      y: 16.1,
    },
    {
      x: 90,
      y: 15.9,
    },
    {
      x: 91,
      y: 15.68,
    },
    {
      x: 92,
      y: 15.47,
    },
    {
      x: 93,
      y: 15.25,
    },
    {
      x: 94,
      y: 15.03,
    },
    {
      x: 95,
      y: 14.83,
    },
    {
      x: 96,
      y: 14.64,
    },
    {
      x: 97,
      y: 14.45,
    },
    {
      x: 98,
      y: 14.29,
    },
    {
      x: 99,
      y: 14.13,
    },
  ],
  left_knee: [
    {
      x: 0,
      y: 10.03,
    },
    {
      x: 1,
      y: 10.7,
    },
    {
      x: 2,
      y: 11.37,
    },
    {
      x: 3,
      y: 12.19,
    },
    {
      x: 4,
      y: 13.14,
    },
    {
      x: 5,
      y: 14.09,
    },
    {
      x: 6,
      y: 15.2,
    },
    {
      x: 7,
      y: 16.31,
    },
    {
      x: 8,
      y: 17.42,
    },
    {
      x: 9,
      y: 18.53,
    },
    {
      x: 10,
      y: 19.63,
    },
    {
      x: 11,
      y: 20.61,
    },
    {
      x: 12,
      y: 21.59,
    },
    {
      x: 13,
      y: 22.39,
    },
    {
      x: 14,
      y: 23.1,
    },
    {
      x: 15,
      y: 23.75,
    },
    {
      x: 16,
      y: 24.1,
    },
    {
      x: 17,
      y: 24.44,
    },
    {
      x: 18,
      y: 24.53,
    },
    {
      x: 19,
      y: 24.5,
    },
    {
      x: 20,
      y: 24.39,
    },
    {
      x: 21,
      y: 24,
    },
    {
      x: 22,
      y: 23.62,
    },
    {
      x: 23,
      y: 23.04,
    },
    {
      x: 24,
      y: 22.39,
    },
    {
      x: 25,
      y: 21.71,
    },
    {
      x: 26,
      y: 20.92,
    },
    {
      x: 27,
      y: 20.14,
    },
    {
      x: 28,
      y: 19.35,
    },
    {
      x: 29,
      y: 18.58,
    },
    {
      x: 30,
      y: 17.84,
    },
    {
      x: 31,
      y: 17.22,
    },
    {
      x: 32,
      y: 16.6,
    },
    {
      x: 33,
      y: 16.22,
    },
    {
      x: 34,
      y: 15.89,
    },
    {
      x: 35,
      y: 15.71,
    },
    {
      x: 36,
      y: 15.81,
    },
    {
      x: 37,
      y: 15.9,
    },
    {
      x: 38,
      y: 16.46,
    },
    {
      x: 39,
      y: 17.08,
    },
    {
      x: 40,
      y: 17.96,
    },
    {
      x: 41,
      y: 19.2,
    },
    {
      x: 42,
      y: 20.44,
    },
    {
      x: 43,
      y: 22.3,
    },
    {
      x: 44,
      y: 24.21,
    },
    {
      x: 45,
      y: 26.41,
    },
    {
      x: 46,
      y: 28.98,
    },
    {
      x: 47,
      y: 31.54,
    },
    {
      x: 48,
      y: 34.65,
    },
    {
      x: 49,
      y: 37.77,
    },
    {
      x: 50,
      y: 41.05,
    },
    {
      x: 51,
      y: 44.49,
    },
    {
      x: 52,
      y: 47.93,
    },
    {
      x: 53,
      y: 51.35,
    },
    {
      x: 54,
      y: 54.78,
    },
    {
      x: 55,
      y: 57.99,
    },
    {
      x: 56,
      y: 61.02,
    },
    {
      x: 57,
      y: 63.99,
    },
    {
      x: 58,
      y: 66.31,
    },
    {
      x: 59,
      y: 68.62,
    },
    {
      x: 60,
      y: 70.37,
    },
    {
      x: 61,
      y: 71.74,
    },
    {
      x: 62,
      y: 72.98,
    },
    {
      x: 63,
      y: 73.28,
    },
    {
      x: 64,
      y: 73.58,
    },
    {
      x: 65,
      y: 73.18,
    },
    {
      x: 66,
      y: 72.39,
    },
    {
      x: 67,
      y: 71.42,
    },
    {
      x: 68,
      y: 69.65,
    },
    {
      x: 69,
      y: 67.87,
    },
    {
      x: 70,
      y: 65.57,
    },
    {
      x: 71,
      y: 63.05,
    },
    {
      x: 72,
      y: 60.45,
    },
    {
      x: 73,
      y: 57.55,
    },
    {
      x: 74,
      y: 54.65,
    },
    {
      x: 75,
      y: 51.7,
    },
    {
      x: 76,
      y: 48.75,
    },
    {
      x: 77,
      y: 45.83,
    },
    {
      x: 78,
      y: 43.04,
    },
    {
      x: 79,
      y: 40.24,
    },
    {
      x: 80,
      y: 37.64,
    },
    {
      x: 81,
      y: 35.09,
    },
    {
      x: 82,
      y: 32.63,
    },
    {
      x: 83,
      y: 30.35,
    },
    {
      x: 84,
      y: 28.07,
    },
    {
      x: 85,
      y: 26.03,
    },
    {
      x: 86,
      y: 24.03,
    },
    {
      x: 87,
      y: 22.14,
    },
    {
      x: 88,
      y: 20.44,
    },
    {
      x: 89,
      y: 18.74,
    },
    {
      x: 90,
      y: 17.34,
    },
    {
      x: 91,
      y: 15.97,
    },
    {
      x: 92,
      y: 14.76,
    },
    {
      x: 93,
      y: 13.76,
    },
    {
      x: 94,
      y: 12.75,
    },
    {
      x: 95,
      y: 12.13,
    },
    {
      x: 96,
      y: 11.53,
    },
    {
      x: 97,
      y: 11.11,
    },
    {
      x: 98,
      y: 10.92,
    },
    {
      x: 99,
      y: 10.72,
    },
  ],
  left_shoulder: [
    {
      x: 0,
      y: 31.67,
    },
    {
      x: 1,
      y: 30.29,
    },
    {
      x: 2,
      y: 28.9,
    },
    {
      x: 3,
      y: 27.47,
    },
    {
      x: 4,
      y: 25.99,
    },
    {
      x: 5,
      y: 24.52,
    },
    {
      x: 6,
      y: 23.03,
    },
    {
      x: 7,
      y: 21.53,
    },
    {
      x: 8,
      y: 20.06,
    },
    {
      x: 9,
      y: 18.6,
    },
    {
      x: 10,
      y: 17.14,
    },
    {
      x: 11,
      y: 15.74,
    },
    {
      x: 12,
      y: 14.33,
    },
    {
      x: 13,
      y: 12.98,
    },
    {
      x: 14,
      y: 11.65,
    },
    {
      x: 15,
      y: 10.34,
    },
    {
      x: 16,
      y: 9.11,
    },
    {
      x: 17,
      y: 7.89,
    },
    {
      x: 18,
      y: 6.74,
    },
    {
      x: 19,
      y: 5.62,
    },
    {
      x: 20,
      y: 4.54,
    },
    {
      x: 21,
      y: 3.55,
    },
    {
      x: 22,
      y: 2.57,
    },
    {
      x: 23,
      y: 1.69,
    },
    {
      x: 24,
      y: 0.86,
    },
    {
      x: 25,
      y: 0.07,
    },
    {
      x: 26,
      y: -0.61,
    },
    {
      x: 27,
      y: -1.28,
    },
    {
      x: 28,
      y: -1.82,
    },
    {
      x: 29,
      y: -2.32,
    },
    {
      x: 30,
      y: -2.76,
    },
    {
      x: 31,
      y: -3.08,
    },
    {
      x: 32,
      y: -3.4,
    },
    {
      x: 33,
      y: -3.57,
    },
    {
      x: 34,
      y: -3.71,
    },
    {
      x: 35,
      y: -3.79,
    },
    {
      x: 36,
      y: -3.77,
    },
    {
      x: 37,
      y: -3.74,
    },
    {
      x: 38,
      y: -3.59,
    },
    {
      x: 39,
      y: -3.42,
    },
    {
      x: 40,
      y: -3.2,
    },
    {
      x: 41,
      y: -2.91,
    },
    {
      x: 42,
      y: -2.62,
    },
    {
      x: 43,
      y: -2.24,
    },
    {
      x: 44,
      y: -1.86,
    },
    {
      x: 45,
      y: -1.45,
    },
    {
      x: 46,
      y: -1,
    },
    {
      x: 47,
      y: -0.55,
    },
    {
      x: 48,
      y: -0.05,
    },
    {
      x: 49,
      y: 0.44,
    },
    {
      x: 50,
      y: 0.96,
    },
    {
      x: 51,
      y: 1.49,
    },
    {
      x: 52,
      y: 2.02,
    },
    {
      x: 53,
      y: 2.59,
    },
    {
      x: 54,
      y: 3.15,
    },
    {
      x: 55,
      y: 3.74,
    },
    {
      x: 56,
      y: 4.36,
    },
    {
      x: 57,
      y: 4.97,
    },
    {
      x: 58,
      y: 5.65,
    },
    {
      x: 59,
      y: 6.32,
    },
    {
      x: 60,
      y: 7.05,
    },
    {
      x: 61,
      y: 7.81,
    },
    {
      x: 62,
      y: 8.59,
    },
    {
      x: 63,
      y: 9.47,
    },
    {
      x: 64,
      y: 10.34,
    },
    {
      x: 65,
      y: 11.31,
    },
    {
      x: 66,
      y: 12.33,
    },
    {
      x: 67,
      y: 13.37,
    },
    {
      x: 68,
      y: 14.54,
    },
    {
      x: 69,
      y: 15.71,
    },
    {
      x: 70,
      y: 17,
    },
    {
      x: 71,
      y: 18.33,
    },
    {
      x: 72,
      y: 19.7,
    },
    {
      x: 73,
      y: 21.18,
    },
    {
      x: 74,
      y: 22.65,
    },
    {
      x: 75,
      y: 24.21,
    },
    {
      x: 76,
      y: 25.8,
    },
    {
      x: 77,
      y: 27.39,
    },
    {
      x: 78,
      y: 29.01,
    },
    {
      x: 79,
      y: 30.63,
    },
    {
      x: 80,
      y: 32.19,
    },
    {
      x: 81,
      y: 33.75,
    },
    {
      x: 82,
      y: 35.24,
    },
    {
      x: 83,
      y: 36.61,
    },
    {
      x: 84,
      y: 37.98,
    },
    {
      x: 85,
      y: 39.1,
    },
    {
      x: 86,
      y: 40.17,
    },
    {
      x: 87,
      y: 41.1,
    },
    {
      x: 88,
      y: 41.79,
    },
    {
      x: 89,
      y: 42.48,
    },
    {
      x: 90,
      y: 42.79,
    },
    {
      x: 91,
      y: 43.06,
    },
    {
      x: 92,
      y: 43.14,
    },
    {
      x: 93,
      y: 42.98,
    },
    {
      x: 94,
      y: 42.82,
    },
    {
      x: 95,
      y: 42.3,
    },
    {
      x: 96,
      y: 41.76,
    },
    {
      x: 97,
      y: 41.07,
    },
    {
      x: 98,
      y: 40.22,
    },
    {
      x: 99,
      y: 39.37,
    },
  ],
  right_ankle: [
    {
      x: 0,
      y: -10.99,
    },
    {
      x: 1,
      y: -12.26,
    },
    {
      x: 2,
      y: -13.53,
    },
    {
      x: 3,
      y: -14.89,
    },
    {
      x: 4,
      y: -16.32,
    },
    {
      x: 5,
      y: -17.75,
    },
    {
      x: 6,
      y: -19.04,
    },
    {
      x: 7,
      y: -20.33,
    },
    {
      x: 8,
      y: -21.42,
    },
    {
      x: 9,
      y: -22.36,
    },
    {
      x: 10,
      y: -23.23,
    },
    {
      x: 11,
      y: -23.81,
    },
    {
      x: 12,
      y: -24.36,
    },
    {
      x: 13,
      y: -24.71,
    },
    {
      x: 14,
      y: -24.99,
    },
    {
      x: 15,
      y: -25.21,
    },
    {
      x: 16,
      y: -25.39,
    },
    {
      x: 17,
      y: -25.57,
    },
    {
      x: 18,
      y: -25.77,
    },
    {
      x: 19,
      y: -26.04,
    },
    {
      x: 20,
      y: -26.35,
    },
    {
      x: 21,
      y: -26.81,
    },
    {
      x: 22,
      y: -27.34,
    },
    {
      x: 23,
      y: -28.03,
    },
    {
      x: 24,
      y: -28.84,
    },
    {
      x: 25,
      y: -29.81,
    },
    {
      x: 26,
      y: -30.93,
    },
    {
      x: 27,
      y: -32.17,
    },
    {
      x: 28,
      y: -33.56,
    },
    {
      x: 29,
      y: -35.05,
    },
    {
      x: 30,
      y: -36.7,
    },
    {
      x: 31,
      y: -38.38,
    },
    {
      x: 32,
      y: -40.16,
    },
    {
      x: 33,
      y: -41.94,
    },
    {
      x: 34,
      y: -43.74,
    },
    {
      x: 35,
      y: -45.53,
    },
    {
      x: 36,
      y: -47.24,
    },
    {
      x: 37,
      y: -48.87,
    },
    {
      x: 38,
      y: -50.37,
    },
    {
      x: 39,
      y: -51.69,
    },
    {
      x: 40,
      y: -52.84,
    },
    {
      x: 41,
      y: -53.8,
    },
    {
      x: 42,
      y: -54.44,
    },
    {
      x: 43,
      y: -54.87,
    },
    {
      x: 44,
      y: -54.94,
    },
    {
      x: 45,
      y: -54.74,
    },
    {
      x: 46,
      y: -54.29,
    },
    {
      x: 47,
      y: -53.39,
    },
    {
      x: 48,
      y: -52.3,
    },
    {
      x: 49,
      y: -50.81,
    },
    {
      x: 50,
      y: -49.13,
    },
    {
      x: 51,
      y: -47.29,
    },
    {
      x: 52,
      y: -45.19,
    },
    {
      x: 53,
      y: -43.11,
    },
    {
      x: 54,
      y: -40.95,
    },
    {
      x: 55,
      y: -38.86,
    },
    {
      x: 56,
      y: -36.89,
    },
    {
      x: 57,
      y: -35.07,
    },
    {
      x: 58,
      y: -33.48,
    },
    {
      x: 59,
      y: -32.21,
    },
    {
      x: 60,
      y: -31.17,
    },
    {
      x: 61,
      y: -30.44,
    },
    {
      x: 62,
      y: -30.07,
    },
    {
      x: 63,
      y: -30.01,
    },
    {
      x: 64,
      y: -30.32,
    },
    {
      x: 65,
      y: -30.9,
    },
    {
      x: 66,
      y: -31.79,
    },
    {
      x: 67,
      y: -32.99,
    },
    {
      x: 68,
      y: -34.37,
    },
    {
      x: 69,
      y: -35.95,
    },
    {
      x: 70,
      y: -37.58,
    },
    {
      x: 71,
      y: -39.27,
    },
    {
      x: 72,
      y: -40.93,
    },
    {
      x: 73,
      y: -42.38,
    },
    {
      x: 74,
      y: -43.73,
    },
    {
      x: 75,
      y: -44.71,
    },
    {
      x: 76,
      y: -45.38,
    },
    {
      x: 77,
      y: -45.73,
    },
    {
      x: 78,
      y: -45.46,
    },
    {
      x: 79,
      y: -45,
    },
    {
      x: 80,
      y: -43.84,
    },
    {
      x: 81,
      y: -42.31,
    },
    {
      x: 82,
      y: -40.43,
    },
    {
      x: 83,
      y: -37.9,
    },
    {
      x: 84,
      y: -35.27,
    },
    {
      x: 85,
      y: -32.27,
    },
    {
      x: 86,
      y: -29.12,
    },
    {
      x: 87,
      y: -25.96,
    },
    {
      x: 88,
      y: -22.89,
    },
    {
      x: 89,
      y: -19.84,
    },
    {
      x: 90,
      y: -17.29,
    },
    {
      x: 91,
      y: -14.86,
    },
    {
      x: 92,
      y: -12.79,
    },
    {
      x: 93,
      y: -11.26,
    },
    {
      x: 94,
      y: -9.74,
    },
    {
      x: 95,
      y: -9.06,
    },
    {
      x: 96,
      y: -8.44,
    },
    {
      x: 97,
      y: -8.22,
    },
    {
      x: 98,
      y: -8.45,
    },
    {
      x: 99,
      y: -8.67,
    },
  ],
  right_elbow: [
    {
      x: 0,
      y: -70.39,
    },
    {
      x: 1,
      y: -70.41,
    },
    {
      x: 2,
      y: -70.42,
    },
    {
      x: 3,
      y: -70.53,
    },
    {
      x: 4,
      y: -70.73,
    },
    {
      x: 5,
      y: -70.94,
    },
    {
      x: 6,
      y: -71.37,
    },
    {
      x: 7,
      y: -71.82,
    },
    {
      x: 8,
      y: -72.42,
    },
    {
      x: 9,
      y: -73.17,
    },
    {
      x: 10,
      y: -73.95,
    },
    {
      x: 11,
      y: -75,
    },
    {
      x: 12,
      y: -76.09,
    },
    {
      x: 13,
      y: -77.35,
    },
    {
      x: 14,
      y: -78.78,
    },
    {
      x: 15,
      y: -80.26,
    },
    {
      x: 16,
      y: -81.99,
    },
    {
      x: 17,
      y: -83.75,
    },
    {
      x: 18,
      y: -85.68,
    },
    {
      x: 19,
      y: -87.72,
    },
    {
      x: 20,
      y: -89.84,
    },
    {
      x: 21,
      y: -92.08,
    },
    {
      x: 22,
      y: -94.36,
    },
    {
      x: 23,
      y: -96.73,
    },
    {
      x: 24,
      y: -99.1,
    },
    {
      x: 25,
      y: -101.57,
    },
    {
      x: 26,
      y: -103.99,
    },
    {
      x: 27,
      y: -106.46,
    },
    {
      x: 28,
      y: -108.91,
    },
    {
      x: 29,
      y: -111.28,
    },
    {
      x: 30,
      y: -113.7,
    },
    {
      x: 31,
      y: -115.98,
    },
    {
      x: 32,
      y: -118.25,
    },
    {
      x: 33,
      y: -120.4,
    },
    {
      x: 34,
      y: -122.4,
    },
    {
      x: 35,
      y: -124.31,
    },
    {
      x: 36,
      y: -126.05,
    },
    {
      x: 37,
      y: -127.62,
    },
    {
      x: 38,
      y: -129,
    },
    {
      x: 39,
      y: -130.12,
    },
    {
      x: 40,
      y: -131.03,
    },
    {
      x: 41,
      y: -131.72,
    },
    {
      x: 42,
      y: -132.03,
    },
    {
      x: 43,
      y: -132.1,
    },
    {
      x: 44,
      y: -131.72,
    },
    {
      x: 45,
      y: -131.03,
    },
    {
      x: 46,
      y: -130,
    },
    {
      x: 47,
      y: -128.29,
    },
    {
      x: 48,
      y: -126.31,
    },
    {
      x: 49,
      y: -123.43,
    },
    {
      x: 50,
      y: -120.12,
    },
    {
      x: 51,
      y: -116.26,
    },
    {
      x: 52,
      y: -111.28,
    },
    {
      x: 53,
      y: -106.21,
    },
    {
      x: 54,
      y: -100.24,
    },
    {
      x: 55,
      y: -94.14,
    },
    {
      x: 56,
      y: -88.32,
    },
    {
      x: 57,
      y: -82.9,
    },
    {
      x: 58,
      y: -77.7,
    },
    {
      x: 59,
      y: -73.79,
    },
    {
      x: 60,
      y: -70.18,
    },
    {
      x: 61,
      y: -67.19,
    },
    {
      x: 62,
      y: -64.91,
    },
    {
      x: 63,
      y: -62.92,
    },
    {
      x: 64,
      y: -61.45,
    },
    {
      x: 65,
      y: -60.28,
    },
    {
      x: 66,
      y: -59.36,
    },
    {
      x: 67,
      y: -58.77,
    },
    {
      x: 68,
      y: -58.38,
    },
    {
      x: 69,
      y: -58.15,
    },
    {
      x: 70,
      y: -58.12,
    },
    {
      x: 71,
      y: -58.22,
    },
    {
      x: 72,
      y: -58.47,
    },
    {
      x: 73,
      y: -58.86,
    },
    {
      x: 74,
      y: -59.31,
    },
    {
      x: 75,
      y: -59.91,
    },
    {
      x: 76,
      y: -60.58,
    },
    {
      x: 77,
      y: -61.32,
    },
    {
      x: 78,
      y: -62.17,
    },
    {
      x: 79,
      y: -63.05,
    },
    {
      x: 80,
      y: -64,
    },
    {
      x: 81,
      y: -64.99,
    },
    {
      x: 82,
      y: -65.99,
    },
    {
      x: 83,
      y: -67.03,
    },
    {
      x: 84,
      y: -68.07,
    },
    {
      x: 85,
      y: -69.09,
    },
    {
      x: 86,
      y: -70.11,
    },
    {
      x: 87,
      y: -71.07,
    },
    {
      x: 88,
      y: -71.98,
    },
    {
      x: 89,
      y: -72.88,
    },
    {
      x: 90,
      y: -73.63,
    },
    {
      x: 91,
      y: -74.35,
    },
    {
      x: 92,
      y: -74.97,
    },
    {
      x: 93,
      y: -75.47,
    },
    {
      x: 94,
      y: -75.95,
    },
    {
      x: 95,
      y: -76.22,
    },
    {
      x: 96,
      y: -76.48,
    },
    {
      x: 97,
      y: -76.64,
    },
    {
      x: 98,
      y: -76.68,
    },
    {
      x: 99,
      y: -76.73,
    },
  ],
  right_hip: [
    {
      x: 0,
      y: 15.08,
    },
    {
      x: 1,
      y: 14.84,
    },
    {
      x: 2,
      y: 14.6,
    },
    {
      x: 3,
      y: 14.35,
    },
    {
      x: 4,
      y: 14.1,
    },
    {
      x: 5,
      y: 13.85,
    },
    {
      x: 6,
      y: 13.55,
    },
    {
      x: 7,
      y: 13.26,
    },
    {
      x: 8,
      y: 12.91,
    },
    {
      x: 9,
      y: 12.53,
    },
    {
      x: 10,
      y: 12.13,
    },
    {
      x: 11,
      y: 11.63,
    },
    {
      x: 12,
      y: 11.13,
    },
    {
      x: 13,
      y: 10.53,
    },
    {
      x: 14,
      y: 9.88,
    },
    {
      x: 15,
      y: 9.19,
    },
    {
      x: 16,
      y: 8.39,
    },
    {
      x: 17,
      y: 7.57,
    },
    {
      x: 18,
      y: 6.66,
    },
    {
      x: 19,
      y: 5.68,
    },
    {
      x: 20,
      y: 4.67,
    },
    {
      x: 21,
      y: 3.57,
    },
    {
      x: 22,
      y: 2.45,
    },
    {
      x: 23,
      y: 1.27,
    },
    {
      x: 24,
      y: 0.07,
    },
    {
      x: 25,
      y: -1.16,
    },
    {
      x: 26,
      y: -2.41,
    },
    {
      x: 27,
      y: -3.66,
    },
    {
      x: 28,
      y: -4.9,
    },
    {
      x: 29,
      y: -6.11,
    },
    {
      x: 30,
      y: -7.3,
    },
    {
      x: 31,
      y: -8.44,
    },
    {
      x: 32,
      y: -9.53,
    },
    {
      x: 33,
      y: -10.54,
    },
    {
      x: 34,
      y: -11.47,
    },
    {
      x: 35,
      y: -12.31,
    },
    {
      x: 36,
      y: -13.06,
    },
    {
      x: 37,
      y: -13.69,
    },
    {
      x: 38,
      y: -14.22,
    },
    {
      x: 39,
      y: -14.61,
    },
    {
      x: 40,
      y: -14.89,
    },
    {
      x: 41,
      y: -15.06,
    },
    {
      x: 42,
      y: -15.08,
    },
    {
      x: 43,
      y: -14.99,
    },
    {
      x: 44,
      y: -14.77,
    },
    {
      x: 45,
      y: -14.44,
    },
    {
      x: 46,
      y: -14.02,
    },
    {
      x: 47,
      y: -13.46,
    },
    {
      x: 48,
      y: -12.82,
    },
    {
      x: 49,
      y: -12.07,
    },
    {
      x: 50,
      y: -11.25,
    },
    {
      x: 51,
      y: -10.37,
    },
    {
      x: 52,
      y: -9.4,
    },
    {
      x: 53,
      y: -8.39,
    },
    {
      x: 54,
      y: -7.31,
    },
    {
      x: 55,
      y: -6.2,
    },
    {
      x: 56,
      y: -5.05,
    },
    {
      x: 57,
      y: -3.86,
    },
    {
      x: 58,
      y: -2.66,
    },
    {
      x: 59,
      y: -1.43,
    },
    {
      x: 60,
      y: -0.2,
    },
    {
      x: 61,
      y: 1.04,
    },
    {
      x: 62,
      y: 2.29,
    },
    {
      x: 63,
      y: 3.53,
    },
    {
      x: 64,
      y: 4.76,
    },
    {
      x: 65,
      y: 5.98,
    },
    {
      x: 66,
      y: 7.18,
    },
    {
      x: 67,
      y: 8.35,
    },
    {
      x: 68,
      y: 9.48,
    },
    {
      x: 69,
      y: 10.57,
    },
    {
      x: 70,
      y: 11.62,
    },
    {
      x: 71,
      y: 12.61,
    },
    {
      x: 72,
      y: 13.55,
    },
    {
      x: 73,
      y: 14.4,
    },
    {
      x: 74,
      y: 15.21,
    },
    {
      x: 75,
      y: 15.92,
    },
    {
      x: 76,
      y: 16.55,
    },
    {
      x: 77,
      y: 17.1,
    },
    {
      x: 78,
      y: 17.54,
    },
    {
      x: 79,
      y: 17.93,
    },
    {
      x: 80,
      y: 18.2,
    },
    {
      x: 81,
      y: 18.4,
    },
    {
      x: 82,
      y: 18.52,
    },
    {
      x: 83,
      y: 18.53,
    },
    {
      x: 84,
      y: 18.51,
    },
    {
      x: 85,
      y: 18.38,
    },
    {
      x: 86,
      y: 18.22,
    },
    {
      x: 87,
      y: 18.01,
    },
    {
      x: 88,
      y: 17.73,
    },
    {
      x: 89,
      y: 17.45,
    },
    {
      x: 90,
      y: 17.12,
    },
    {
      x: 91,
      y: 16.78,
    },
    {
      x: 92,
      y: 16.43,
    },
    {
      x: 93,
      y: 16.08,
    },
    {
      x: 94,
      y: 15.72,
    },
    {
      x: 95,
      y: 15.38,
    },
    {
      x: 96,
      y: 15.05,
    },
    {
      x: 97,
      y: 14.73,
    },
    {
      x: 98,
      y: 14.43,
    },
    {
      x: 99,
      y: 14.13,
    },
  ],
  right_knee: [
    {
      x: 0,
      y: 12.59,
    },
    {
      x: 1,
      y: 13.12,
    },
    {
      x: 2,
      y: 13.65,
    },
    {
      x: 3,
      y: 14.35,
    },
    {
      x: 4,
      y: 15.19,
    },
    {
      x: 5,
      y: 16.04,
    },
    {
      x: 6,
      y: 17.06,
    },
    {
      x: 7,
      y: 18.08,
    },
    {
      x: 8,
      y: 19.13,
    },
    {
      x: 9,
      y: 20.18,
    },
    {
      x: 10,
      y: 21.22,
    },
    {
      x: 11,
      y: 22.14,
    },
    {
      x: 12,
      y: 23.06,
    },
    {
      x: 13,
      y: 23.81,
    },
    {
      x: 14,
      y: 24.45,
    },
    {
      x: 15,
      y: 25.01,
    },
    {
      x: 16,
      y: 25.3,
    },
    {
      x: 17,
      y: 25.55,
    },
    {
      x: 18,
      y: 25.56,
    },
    {
      x: 19,
      y: 25.42,
    },
    {
      x: 20,
      y: 25.17,
    },
    {
      x: 21,
      y: 24.69,
    },
    {
      x: 22,
      y: 24.15,
    },
    {
      x: 23,
      y: 23.46,
    },
    {
      x: 24,
      y: 22.67,
    },
    {
      x: 25,
      y: 21.8,
    },
    {
      x: 26,
      y: 20.87,
    },
    {
      x: 27,
      y: 19.91,
    },
    {
      x: 28,
      y: 18.94,
    },
    {
      x: 29,
      y: 18,
    },
    {
      x: 30,
      y: 17.08,
    },
    {
      x: 31,
      y: 16.27,
    },
    {
      x: 32,
      y: 15.52,
    },
    {
      x: 33,
      y: 14.91,
    },
    {
      x: 34,
      y: 14.47,
    },
    {
      x: 35,
      y: 14.19,
    },
    {
      x: 36,
      y: 14.11,
    },
    {
      x: 37,
      y: 14.25,
    },
    {
      x: 38,
      y: 14.62,
    },
    {
      x: 39,
      y: 15.29,
    },
    {
      x: 40,
      y: 16.21,
    },
    {
      x: 41,
      y: 17.38,
    },
    {
      x: 42,
      y: 18.93,
    },
    {
      x: 43,
      y: 20.74,
    },
    {
      x: 44,
      y: 22.94,
    },
    {
      x: 45,
      y: 25.42,
    },
    {
      x: 46,
      y: 28.15,
    },
    {
      x: 47,
      y: 31.3,
    },
    {
      x: 48,
      y: 34.63,
    },
    {
      x: 49,
      y: 38.28,
    },
    {
      x: 50,
      y: 42.07,
    },
    {
      x: 51,
      y: 45.95,
    },
    {
      x: 52,
      y: 49.97,
    },
    {
      x: 53,
      y: 53.9,
    },
    {
      x: 54,
      y: 57.74,
    },
    {
      x: 55,
      y: 61.42,
    },
    {
      x: 56,
      y: 64.85,
    },
    {
      x: 57,
      y: 67.96,
    },
    {
      x: 58,
      y: 70.73,
    },
    {
      x: 59,
      y: 72.98,
    },
    {
      x: 60,
      y: 74.87,
    },
    {
      x: 61,
      y: 76.31,
    },
    {
      x: 62,
      y: 77.22,
    },
    {
      x: 63,
      y: 77.67,
    },
    {
      x: 64,
      y: 77.58,
    },
    {
      x: 65,
      y: 77.07,
    },
    {
      x: 66,
      y: 76.1,
    },
    {
      x: 67,
      y: 74.65,
    },
    {
      x: 68,
      y: 72.81,
    },
    {
      x: 69,
      y: 70.65,
    },
    {
      x: 70,
      y: 68.21,
    },
    {
      x: 71,
      y: 65.52,
    },
    {
      x: 72,
      y: 62.64,
    },
    {
      x: 73,
      y: 59.69,
    },
    {
      x: 74,
      y: 56.67,
    },
    {
      x: 75,
      y: 53.65,
    },
    {
      x: 76,
      y: 50.67,
    },
    {
      x: 77,
      y: 47.73,
    },
    {
      x: 78,
      y: 44.9,
    },
    {
      x: 79,
      y: 42.12,
    },
    {
      x: 80,
      y: 39.47,
    },
    {
      x: 81,
      y: 36.89,
    },
    {
      x: 82,
      y: 34.4,
    },
    {
      x: 83,
      y: 32.06,
    },
    {
      x: 84,
      y: 29.74,
    },
    {
      x: 85,
      y: 27.59,
    },
    {
      x: 86,
      y: 25.49,
    },
    {
      x: 87,
      y: 23.49,
    },
    {
      x: 88,
      y: 21.66,
    },
    {
      x: 89,
      y: 19.85,
    },
    {
      x: 90,
      y: 18.28,
    },
    {
      x: 91,
      y: 16.76,
    },
    {
      x: 92,
      y: 15.39,
    },
    {
      x: 93,
      y: 14.23,
    },
    {
      x: 94,
      y: 13.08,
    },
    {
      x: 95,
      y: 12.3,
    },
    {
      x: 96,
      y: 11.55,
    },
    {
      x: 97,
      y: 11.02,
    },
    {
      x: 98,
      y: 10.71,
    },
    {
      x: 99,
      y: 10.41,
    },
  ],
  right_shoulder: [
    {
      x: 0,
      y: 33.68,
    },
    {
      x: 1,
      y: 32.35,
    },
    {
      x: 2,
      y: 31.03,
    },
    {
      x: 3,
      y: 29.65,
    },
    {
      x: 4,
      y: 28.22,
    },
    {
      x: 5,
      y: 26.79,
    },
    {
      x: 6,
      y: 25.31,
    },
    {
      x: 7,
      y: 23.83,
    },
    {
      x: 8,
      y: 22.34,
    },
    {
      x: 9,
      y: 20.85,
    },
    {
      x: 10,
      y: 19.36,
    },
    {
      x: 11,
      y: 17.9,
    },
    {
      x: 12,
      y: 16.43,
    },
    {
      x: 13,
      y: 15.01,
    },
    {
      x: 14,
      y: 13.6,
    },
    {
      x: 15,
      y: 12.22,
    },
    {
      x: 16,
      y: 10.89,
    },
    {
      x: 17,
      y: 9.58,
    },
    {
      x: 18,
      y: 8.33,
    },
    {
      x: 19,
      y: 7.13,
    },
    {
      x: 20,
      y: 5.97,
    },
    {
      x: 21,
      y: 4.89,
    },
    {
      x: 22,
      y: 3.83,
    },
    {
      x: 23,
      y: 2.85,
    },
    {
      x: 24,
      y: 1.93,
    },
    {
      x: 25,
      y: 1.06,
    },
    {
      x: 26,
      y: 0.28,
    },
    {
      x: 27,
      y: -0.47,
    },
    {
      x: 28,
      y: -1.15,
    },
    {
      x: 29,
      y: -1.76,
    },
    {
      x: 30,
      y: -2.33,
    },
    {
      x: 31,
      y: -2.82,
    },
    {
      x: 32,
      y: -3.27,
    },
    {
      x: 33,
      y: -3.66,
    },
    {
      x: 34,
      y: -3.99,
    },
    {
      x: 35,
      y: -4.28,
    },
    {
      x: 36,
      y: -4.51,
    },
    {
      x: 37,
      y: -4.69,
    },
    {
      x: 38,
      y: -4.83,
    },
    {
      x: 39,
      y: -4.91,
    },
    {
      x: 40,
      y: -4.94,
    },
    {
      x: 41,
      y: -4.93,
    },
    {
      x: 42,
      y: -4.87,
    },
    {
      x: 43,
      y: -4.77,
    },
    {
      x: 44,
      y: -4.61,
    },
    {
      x: 45,
      y: -4.41,
    },
    {
      x: 46,
      y: -4.17,
    },
    {
      x: 47,
      y: -3.87,
    },
    {
      x: 48,
      y: -3.54,
    },
    {
      x: 49,
      y: -3.14,
    },
    {
      x: 50,
      y: -2.71,
    },
    {
      x: 51,
      y: -2.23,
    },
    {
      x: 52,
      y: -1.69,
    },
    {
      x: 53,
      y: -1.13,
    },
    {
      x: 54,
      y: -0.49,
    },
    {
      x: 55,
      y: 0.18,
    },
    {
      x: 56,
      y: 0.88,
    },
    {
      x: 57,
      y: 1.66,
    },
    {
      x: 58,
      y: 2.44,
    },
    {
      x: 59,
      y: 3.3,
    },
    {
      x: 60,
      y: 4.18,
    },
    {
      x: 61,
      y: 5.09,
    },
    {
      x: 62,
      y: 6.05,
    },
    {
      x: 63,
      y: 7.03,
    },
    {
      x: 64,
      y: 8.05,
    },
    {
      x: 65,
      y: 9.09,
    },
    {
      x: 66,
      y: 10.15,
    },
    {
      x: 67,
      y: 11.25,
    },
    {
      x: 68,
      y: 12.37,
    },
    {
      x: 69,
      y: 13.49,
    },
    {
      x: 70,
      y: 14.64,
    },
    {
      x: 71,
      y: 15.8,
    },
    {
      x: 72,
      y: 16.96,
    },
    {
      x: 73,
      y: 18.14,
    },
    {
      x: 74,
      y: 19.31,
    },
    {
      x: 75,
      y: 20.49,
    },
    {
      x: 76,
      y: 21.65,
    },
    {
      x: 77,
      y: 22.8,
    },
    {
      x: 78,
      y: 23.92,
    },
    {
      x: 79,
      y: 25.04,
    },
    {
      x: 80,
      y: 26.1,
    },
    {
      x: 81,
      y: 27.13,
    },
    {
      x: 82,
      y: 28.11,
    },
    {
      x: 83,
      y: 29.01,
    },
    {
      x: 84,
      y: 29.89,
    },
    {
      x: 85,
      y: 30.64,
    },
    {
      x: 86,
      y: 31.34,
    },
    {
      x: 87,
      y: 31.95,
    },
    {
      x: 88,
      y: 32.41,
    },
    {
      x: 89,
      y: 32.85,
    },
    {
      x: 90,
      y: 33.06,
    },
    {
      x: 91,
      y: 33.24,
    },
    {
      x: 92,
      y: 33.28,
    },
    {
      x: 93,
      y: 33.14,
    },
    {
      x: 94,
      y: 33,
    },
    {
      x: 95,
      y: 32.58,
    },
    {
      x: 96,
      y: 32.13,
    },
    {
      x: 97,
      y: 31.54,
    },
    {
      x: 98,
      y: 30.8,
    },
    {
      x: 99,
      y: 30.06,
    },
  ],
};
