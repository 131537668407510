import TextComponent from 'components/atoms/TextComponent';
import {BODY_1, BODY_2, BOLD} from 'constant/constants';
import React from 'react';
import {score_colors} from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';

function BodyContainerContentRecapDisplay({item, multipleLines = false}) {
  if (!item) return null;
  let score = item?.score;
  return (
    <div style={styles.sliderContainer}>
      <div style={styles.sliderCard}>
        {item.recap ? (
          <div>
            {item.recap.map((recapItem, recapIndex) => (
              <div
                style={{
                  ...styles.recapContainer,
                  ...(multipleLines ? {} : {alignItems: 'center'}),
                }}
                key={recapIndex}>
                <div
                  style={{
                    ...styles.recapDotBackground,
                    backgroundColor: score_colors[score].scoreBackground,
                    ...(multipleLines ? {marginTop: 4} : {}),
                  }}>
                  <div
                    style={{
                      ...styles.recapDot,
                      backgroundColor: score_colors[score].stroke,
                    }}
                  />
                </div>
                <TextComponent
                  key={recapIndex}
                  text={recapItem}
                  sx={styles.recapText}
                  variant={BODY_2}
                />
              </div>
            ))}
          </div>
        ) : (
          <div>
            <TextComponent
              text={item.text}
              variant={BODY_2}
              sx={{textAlign: 'left', marginBottom: '2vh'}}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const styles = {
  recapContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 5,
    marginTop: 5,
  },
  recapDot: {
    borderRadius: '50%',
    height: 5,
    width: 5,
  },
  recapDotBackground: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    height: 12,
    justifyContent: 'center',
    width: 12,
  },
  recapText: {
    marginLeft: 1,
    textAlign: 'left',
  },
  sliderCard: {
    padding: 15,
    borderRadius: 1,
    width: '90%',
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1vh',
  },
  text: {
    marginBottom: 2,
    textAlign: 'left',
    fontWeight: BOLD,
  },
};

export default BodyContainerContentRecapDisplay;
