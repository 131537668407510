import {firestore} from 'utils/firebaseInit';
import {collection, doc, onSnapshot} from 'firebase/firestore';
import {useEffect, useState} from 'react';
import {store} from 'store';

const ANALYSIS_COLLECTION = 'analysis';
const PROJECT_COLLECTION = 'projects';
const PROJECT_SUB_COLLECTION = 'analysisDetails';

export function getAnalysisChanges(id, callback) {
  try {
    const unsub = onSnapshot(
      doc(collection(firestore, ANALYSIS_COLLECTION), id),
      doc => {
        if (!doc?.data()) return () => unsub();
        callback(doc?.data());
      },
    );
    return () => unsub();
  } catch (error) {
    console.log(error);
  }
}

//create a hook that will listen to the changes in the analysis collection according to the ID
export function useAnalysisListener(id, locked = false, pending = false) {
  const [analysis, setAnalysis] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const user = store?.getState()?.user;
  useEffect(() => {
    try {
      if (id === null) return;
      if (!pending && !locked) return;

      setLoading(true);
      const subcollectionRef = collection(
        firestore,
        PROJECT_COLLECTION,
        user?.userID,
        PROJECT_SUB_COLLECTION,
      );
      const docRef = doc(subcollectionRef, id);
      const unsubscribe = onSnapshot(
        docRef,
        docSnapshot => {
          if (docSnapshot?.exists()) {
            setAnalysis(docSnapshot?.data());
          } else {
            setError('Document does not exist');
          }
          setLoading(false);
        },
        error => {
          console.error(
            'Error while listening to subcollection document:',
            error,
          );
          setError(error.message);
          setLoading(false);
        },
      );

      // Cleanup function to unsubscribe from the snapshot listener when the component unmounts
      return () => unsubscribe();
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  return {analysis, loading, error};
}
