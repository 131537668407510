export const EXEMPLE_ANALYSIS = {
  de: '59aa3df5-cb8e-4e74-b524-ffb64b284634',
  en: '731e1450-c506-4f2f-97cc-ad7e052b5510',
  fr: '76859356-c717-454b-8b53-07cf6a36a2ad',
  zh: 'fdaf693f-bca3-4862-8d86-251a741105a4',
  pt: '4bb2f575-da21-4e53-be4e-e50e7c361494',
  it: '997d2762-4c78-4e54-b850-3d9041adfa7c',
  es: '1a8a0ab7-48a9-45e3-934b-63699973ec4b',
  ja: '858e96dd-bec6-4d5c-89f5-e5ee43adfbe2',
  ko: '8ecacfd5-7b97-4ef9-b84b-430c6adae744',
};
