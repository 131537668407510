import React, { useEffect, useState } from 'react';

import { ReactComponent as ShareIcon } from 'assets/svg/shareIcon.svg';
import { ReactComponent as PdfIcon } from 'assets/svg/pdf.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import ShareModalContent from './ShareModalContent';
import PDFSelectionList from 'screen/analysis/AnalysisViewDetailsScreen/components/PDFSelectionList';
import DeleteAnalysisModalContent from './DeleteAnalysisModalContent';
import SuccessCard from './SuccessCard';
import OptionButton from './OptionButton';

import './styles/AnalysisOptions.css';
import { translate } from "services/remoteConfig";
import { fetchJsonData } from "utils/analysis";

function AnalysisOptionsContent({
  analysis,
  modalRef,
  setOpen,
  modalContent,
  setModalContent,
}) {

  const analysis_type = analysis?.analysis_type;
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { jsonData } = await fetchJsonData(analysis);
      setJsonData(jsonData);
    };

    fetchData();
  }, [analysis]);

  const buttonOptions = [
    {
      icon: ShareIcon,
      text: translate('ShareAnalysisByMail'),
      component: <ShareModalContent analysis={analysis} shareRef={modalRef} />,
      type: 'basic',
      permission: analysis?.data?.locked !== true,
    },
    {
      icon: PdfIcon,
      text: translate('ExportToPDF'),
      component: (
        <PDFSelectionList
          jsonData={jsonData}
          setOpen={setOpen}
          analysisType={analysis_type}
        />
      ),
      type: 'basic',
      permission: analysis?.data?.locked !== true,
    },
    {
      icon: TrashIcon,
      text: translate('DeleteAnalysis'),
      component: <DeleteAnalysisModalContent analysis={analysis} modalRef={modalRef} />,
      type: 'delete',
      permission: true,
    },
  ];


  return (
    <div>
      {modalContent === null ? (
        <div style={styles.containerStyle}>
          {analysis_type && (
            <div style={styles.succesCard}>
              <SuccessCard analysisFromStore={analysis} disabled />
            </div>)}
          <div style={styles.buttonList}>
            <div className="button-container">
              {buttonOptions?.map(
                ({ icon: Icon, text, component, type, permission }, index) =>
                  permission && (
                    <OptionButton
                      key={index}
                      Icon={Icon}
                      text={text}
                      type={type}
                      onClick={() => setModalContent(component)}
                    />
                  )
              )}
            </div>
          </div>
        </div>
      ) : (
        <>{modalContent}</>
      )}
    </div>

  );
}

const styles = {
  buttonList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  containerStyle: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  succesCard: {
    height: '100%',
    width: '25vw',
  },
};

export default AnalysisOptionsContent;
