import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  listAnalysis: [],
  videoSelected: {},
  listAnalysisData: [],
  correctLocalStorageJsonVersion: {},
  bLoadingList: true,
};

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    resetAnalysisStore: (state, action) => {
      state.listAnalysis = [];
      state.videoSelected = {};
      state.listAnalysisData = [];
      state.correctLocalStorageJsonVersion = {};
      state.bLoadingList = true;
    },
    setListAnalysis: (state, action) => {
      const listAnalysisCopy = action?.payload?.analysis;
      if (listAnalysisCopy?.indexOf('123456') > -1) {
        listAnalysisCopy[listAnalysisCopy?.indexOf('123456')] =
          'fb97807a-17c2-4304-8d33-ce876ebd5254';
      }
      state.listAnalysis = listAnalysisCopy;
    },
    setVideoSelected: (state, action) => {
      state.videoSelected = action?.payload;
    },

    updateListAnalysisData: (state, action) => {
      state.listAnalysisData = action?.payload;
    },

    updateListAnalysisDataItem: (state, action) => {
      const listAnalysisDataCopy = [...state.listAnalysisData];
      const index = listAnalysisDataCopy.findIndex(
        item =>
          item?.data?.uploadVideoId === action?.payload?.data.uploadVideoId,
      );
      if (index > -1) {
        listAnalysisDataCopy[index] = action?.payload;
        state.listAnalysisData = listAnalysisDataCopy;
      }
    },

    addItemToListAnalysisData: (state, action) => {
      const {analysis, isNewAnalysis} = action?.payload;
      const listAnalysisDataCopy = [...state.listAnalysisData];

      if (isNewAnalysis) {
        listAnalysisDataCopy.unshift(analysis);
      } else {
        listAnalysisDataCopy.push(analysis);
      }

      state.listAnalysisData = listAnalysisDataCopy;
    },
    removeItemFromListAnalysisData: (state, action) => {
      const listAnalysisDataCopy = [...state.listAnalysisData];
      const index = listAnalysisDataCopy.findIndex(
        item => item.data.uploadVideoId === action?.payload?.data.uploadVideoId,
      );
      if (index > -1) {
        listAnalysisDataCopy.splice(index, 1);
        state.listAnalysisData = listAnalysisDataCopy;
      }
    },

    updateCorrectLocalStorageJsonVersion(state, action) {
      state.correctLocalStorageJsonVersion.sideViewAnalysis =
        action?.payload.sideViewAnalysis;
      state.correctLocalStorageJsonVersion.backViewAnalysis =
        action?.payload.backViewAnalysis;
    },

    setLoadingList: (state, action) => {
      state.bLoadingList = action?.payload;
    },
  },
});

export const {
  setListAnalysis,
  setVideoSelected,
  updateListAnalysisData,
  removeItemFromListAnalysisData,
  addItemToListAnalysisData,
  updateListAnalysisDataItem,
  resetAnalysisStore,
  updateCorrectLocalStorageJsonVersion,
  setLoadingList,
} = analysisSlice.actions;

export const analysisListState = state => state.analysis.listAnalysis;
export const analysisListData = state => state.analysis.listAnalysisData;
export const correctLocalStorageJsonVersion = state =>
  state.analysis.correctLocalStorageJsonVersion;
export const loadingList = state => state.analysis.bLoadingList;

export default analysisSlice.reducer;
