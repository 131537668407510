import React, { useRef, useState } from 'react';

import Lottie from 'lottie-react';
import html2canvas from 'html2canvas';

import TextInput from 'components/atoms/TextInput';
import TextComponent from 'components/atoms/TextComponent';
import PDFJointAnglesSelection from './PDFJointAnglesSelection';
import PDFCategoryData from './PDFCategoryData';
import GeneratePDF from 'screen/analysis/PdfExport/GeneratePDF';

import { mpTrack } from 'services/mixpanel';
import { translate } from 'services/remoteConfig';

import { ANALYSISTYPE_PRONATION, BOLD, DOWNLOADED, FAILED, GENERATION, H4 } from 'constant/constants';
import { getAnalysisItems } from 'constant/pdfConstants';

import successLottie from 'assets/animations/success.json';
import './styles/PDFSelectionList.css';

import { colors } from 'themes';
import { determineInvalidElementToExport } from 'utils/utils';
import LoadingComponent from 'components/atoms/LoadingComponent';


export default function PDFSelectionList({ jsonData, setOpen, analysisType }) {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(null);
    const PDFItems = getAnalysisItems(analysisType);


    const initializeSelection = () => {
        const initialSelection = {};

        PDFItems?.forEach(({ category, items }) => {
            if (!initialSelection[category]) {
                initialSelection[category] = [];
            }

            Object.values(items)?.forEach(item => {
                if (determineInvalidElementToExport(jsonData, category, item)) return;

                initialSelection[category]?.push(item);
            });
        });
        return initialSelection;
    };


    const [selectedButtons, setSelectedButtons] = useState(initializeSelection());
    const [inputText, setInputText] = useState('');

    const itemsToDisplay = {
        display_score:
            analysisType === ANALYSISTYPE_PRONATION
                ? false
                : selectedButtons?.Other?.includes('Analysis score'),
        segments:
            analysisType === ANALYSISTYPE_PRONATION
                ? selectedButtons?.Stance
                : selectedButtons?.Segments,
        metrics: selectedButtons?.Metrics || [],
        joint_angle: selectedButtons?.JointAngles || [],
        others:
            selectedButtons?.Other?.filter(item => item !== 'Analysis score') || [],
        note: inputText,
    };



    const handleInputChange = (event) => {
        mpTrack('Web App PDF Add Notes');
        setInputText(event?.target?.value);
    };


    if ((analysisType === 'running' && jsonData?.json_version < 4) || (analysisType === 'pronation' && jsonData?.json_version < 1)) {
        return (
            <div style={styles.oldAnalysis}>
                <TextComponent text={translate('OldAnalysisPDF')} variant={H4} weight={BOLD} sx={{ textAlign: 'center' }} />
            </div>
        )
    }


    if (uploading === FAILED) {
        return (
            <div className="container" style={{ justifyContent: 'center' }} >
                <TextComponent text={translate('PDFFailed')} variant={H4} weight={BOLD} sx={{ ...styles.messageSend, ...{ marginBottom: '0vh' } }} />
            </div >
        )
    }
    if (uploading === GENERATION) {
        return (
            <div className="container">
                <TextComponent text={translate('PDFGeneration')} variant={H4} weight={BOLD} sx={styles.messageSend} />
                <LoadingComponent size={100} />
            </div >
        )
    }


    if (uploading === DOWNLOADED)
        return (
            <div className="container">
                <TextComponent text={translate('ExportedPDF')} variant={H4} weight={BOLD} sx={styles.messageSend} />
                <Lottie
                    animationData={successLottie}
                    style={styles.lottie}
                    onLoopComplete={() => {
                        setSuccess(false);
                        setOpen(false)
                    }}
                />
            </div>
        )

    else
        return (
            <div className="container">

                <>
                    <TextComponent text={translate('SelectElementToExport')} sx={{ marginBottom: '2vh', fontSize: '2vw' }} />
                    <PDFCategoryData selectedButtons={selectedButtons} setSelectedButtons={setSelectedButtons} jsonData={jsonData} analysisType={analysisType} />
                    <TextInput label={translate('AddNotes')} type={'longtext'} onChange={handleInputChange} />
                </>

                <GeneratePDF videoId={jsonData?.video_id} itemsToDisplay={itemsToDisplay}
                    setSuccess={setSuccess}
                    setLoading={setLoading}
                    setUploading={setUploading}
                />
            </div>
        );
}

const styles = {
    lottie: {
        width: '20vw',
        height: '20vh',
        marginBottom: '10vh'
    },
    messageSend: {
        color: colors.WHITE,
        textAlign: 'center',
        marginBottom: '15vh'
    },
    oldAnalysis: { height: '40vh', display: 'flex', alignItems: 'center', width: '95%' },
    success: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10vh'
    },
};