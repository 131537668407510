import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextComponent from 'components/atoms/TextComponent';
import GradientScoreSystem from 'screen/analysis/AnalysisListCard/components/GradientScoreSystem';
import { BOLD, PRONATION } from 'constant/constants';
import './LeaderBoard.css';
import { colors } from 'themes';
import { ReactComponent as Trophy } from 'assets/svg/trophy.svg';
import { useUsageChanges } from 'hooks/useUsageChanges';
import { useItemChanges } from 'hooks/useItemsChanges';
import { useProjectChanges } from 'hooks/useProjectChanges';
import Lottie from 'lottie-react';
import fireworks from "assets/animations/fireworks.json";
import { USER_LOCALE } from 'services/remoteConfig';


function Leaderboard() {
    useUsageChanges();
    useItemChanges();

    const { projects, analysisDetails } = useProjectChanges();

    const [rank, setRank] = useState([]);

    const userItemSlice = useSelector(state => state?.userItems);

    const [lastAnalysis, setLastAnalysis] = useState(null)

    useEffect(() => {
        const lastAnalysisId = projects?.analysis[projects?.analysis?.length - 1];
        const lastAnalysisData = analysisDetails?.find(
            data => data?.data?.uploadVideoId === lastAnalysisId
        );

        if (lastAnalysisData?.status === 'success') {
            setLastAnalysis(lastAnalysisData);
        }

    }, [projects?.analysis?.length, analysisDetails]);


    useEffect(() => {
        if (analysisDetails?.length === 0 || userItemSlice?.lstItems?.length === 0) return;

        const analysisByAthlete = sortAnalysisByAthlete(
            analysisDetails.filter(
                obj => obj?.analysis_type !== PRONATION && obj?.exemple !== true && obj?.status === 'success'
            )
        );
        const bestScoreByAthlete = getBestScoreByAthlete(analysisByAthlete);

        setRank(bestScoreByAthlete?.sort((a, b) => b?.bestScore - a?.bestScore));

        const animationDurationPerElement = 2;
        const animationDuration = rank?.length * animationDurationPerElement;
        const elementHeightPercentage = 10;
        const totalHeightPercentage = rank?.length * elementHeightPercentage;
        const toValue = -totalHeightPercentage - (rank?.slice(3)?.length * 4);

        document.documentElement.style.setProperty('--animation-duration', `${animationDuration}s`);
        document.documentElement.style.setProperty('--to-value', `${toValue}%`);
    }, [analysisDetails, rank?.length]);

    function sortAnalysisByAthlete(data) {
        return userItemSlice?.lstItems?.map(item => {
            const analysis = data.filter(
                obj => obj?.data?.tag?.id === item?.id && obj?.analysis_type !== PRONATION
            );
            return {
                id: item?.id,
                analysis,
                name: item?.name
            };
        });
    }

    function getBestScoreByAthlete(array) {
        return array
            ?.map(item => {
                const allScores = item?.analysis
                    ?.map(obj => (obj?.analysis_content?.total_score * 100)?.toFixed(0))
                    ?.filter(score => score !== null && score !== undefined && !isNaN(score));


                const bestScore = allScores?.length > 0 ? Math.max(...allScores) : null;
                return { bestScore, name: item?.name };
            })
            ?.filter(item => item?.bestScore !== null && item?.name !== undefined);
    }


    function getPlaceText(index) {
        if (index + 1 === 1) return USER_LOCALE === 'fr' ? 'ère' : 'st';
        if (index + 1 === 2) return USER_LOCALE === 'fr' ? 'ème' : 'nd';
        if (index + 1 === 3) return USER_LOCALE === 'fr' ? 'ème' : 'rd';
        return USER_LOCALE === 'fr' ? 'ème' : 'th';
    }

    if (!rank || !analysisDetails) return null;

    return (
        <div style={styles.container}>
            <div style={styles.subContainer}>
                <div style={styles.leftContainer}>
                    <img
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/ochy-dev-bf051.appspot.com/o/brand-logo%2Fochy_challenge.png?alt=media&token=cf363a4c-96aa-424f-8832-e15e547f35d7'
                        }
                        alt="ochyLogo"
                        style={{ height: '40%', width: '100%', objectFit: 'contain' }}
                    />
                    <TextComponent
                        text={USER_LOCALE === 'fr' ? 'Analyse ta biomécanique de course !' : 'Analyse your running biomechanics !'}
                        sx={{ fontSize: '2vw', color: 'white', textAlign: 'center' }}
                    />
                    <div style={styles.lastAnalysis}>
                        {(rank?.findIndex(
                            (data) =>
                                data?.name === lastAnalysis?.data?.tag?.name
                        ) +
                            1 ===
                            1 ? (
                            <Lottie
                                animationData={fireworks}
                                style={styles.fireworks}
                            />) : null
                        )
                        }

                        <div
                            style={{
                                width: '100%',
                                height: '70%',
                                display: 'flex',
                                marginTop: '1vh',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >

                            <div
                                style={{
                                    width: '95%',
                                    marginTop: '2vh',
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                }}
                            >
                                <TextComponent
                                    text={
                                        rank?.findIndex(
                                            (data) =>
                                                data?.name === lastAnalysis?.data?.tag?.name
                                        ) +
                                        1 +
                                        '. ' +
                                        lastAnalysis?.data?.tag?.name
                                    }
                                    sx={{
                                        fontSize: '1.5vw',
                                        color: 'white',
                                        textAlign: 'center',
                                    }}
                                    weight={BOLD}
                                />
                                <GradientScoreSystem
                                    scoreNumber={(lastAnalysis?.analysis_content?.total_score * 100)?.toFixed(0)}
                                />
                            </div>
                        </div>
                        <TextComponent
                            text={
                                lastAnalysis?.data?.tag?.name +
                                (USER_LOCALE === 'fr' ? ' prend la  ' : ' takes ') +
                                (rank?.findIndex((data) => data?.name === lastAnalysis?.data?.tag?.name) +
                                    1) +
                                getPlaceText(rank?.findIndex((data) => data?.name === lastAnalysis?.data?.tag?.name)) +
                                ' place !'
                            }
                            sx={{
                                fontSize: '1vw',
                                color: 'white',
                                textAlign: 'center',
                                width: '90%',
                            }}
                        />

                    </div>

                </div>

                <div style={styles.rank}>
                    <div style={styles.podium}>
                        <div style={styles.secondPlace}>
                            <div
                                style={{ ...styles.bubble, ...{ backgroundColor: '#868686' } }}
                            >
                                2
                            </div>
                            <TextComponent
                                text={rank[1]?.name}
                                sx={{
                                    fontSize: '2vw',
                                    color: '#868686',
                                    textAlign: 'center'

                                }}
                                weight={BOLD}
                            />
                            <GradientScoreSystem scoreNumber={rank[1]?.bestScore} />
                        </div>
                        <div style={styles.firstPlace}>
                            <Trophy
                                style={{
                                    width: '50%',
                                    height: '50%',
                                    position: 'absolute',
                                    top: -65
                                }}
                            />

                            <TextComponent
                                text={rank[0]?.name}
                                sx={{
                                    fontSize: '2vw',
                                    color: '#daa520',
                                    textAlign: 'center',
                                }}
                                weight={BOLD}
                            />
                            <GradientScoreSystem scoreNumber={rank[0]?.bestScore} />
                        </div>
                        <div style={styles.thirdPlace}>
                            <div
                                style={{ ...styles.bubble, ...{ backgroundColor: '#977b3f' } }}
                            >
                                3
                            </div>
                            <TextComponent
                                text={rank[2]?.name}
                                sx={{
                                    fontSize: '2vw', color: '#977b3f', textAlign: 'center'
                                }}
                                weight={BOLD}
                            />
                            <GradientScoreSystem scoreNumber={rank[2]?.bestScore} />
                        </div>
                    </div>
                    <div style={styles.restOfRank}>
                        {rank?.slice(3)?.length > 6 ? (
                            <div className="scroll-element primary">
                                {rank?.slice(3).map((item, index) => (
                                    <div style={styles.rankLine} key={index}>
                                        <TextComponent
                                            text={index + 4 + '. ' + item?.name}
                                            sx={{ marginRight: '1vw', fontSize: '2.5vw' }}
                                        />
                                        <GradientScoreSystem scoreNumber={item?.bestScore} />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            rank?.slice(3).map((item, index) => (
                                <div style={styles.rankLine} key={index}>
                                    <TextComponent text={(index + 4) + '. ' + item?.name} sx={{ marginRight: '1vw', fontSize: '2.5vw' }} />
                                    <GradientScoreSystem scoreNumber={item?.bestScore} />
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    bubble: {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        color: 'white',
        textAlign: 'center',
        lineHeight: '30px',
        fontSize: '20px',
        fontWeight: 'bold'
    },
    container: {
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        backgroundColor: 'black',
        width: '100%'
    },
    fireworks: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '100%'
    },
    podium: {
        width: '90%',
        height: '35%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: '2vh'
    },
    lastAnalysis: {
        marginTop: '7vh',
        width: '60%',
        backgroundColor: colors.SECONDARY,
        borderRadius: 15,
        height: '25%',
        flexDirection: 'column',
        alignItems: 'center',
        display: 'flex',
        position: 'relative',

    },
    firstPlace: {
        width: '40%',
        height: '85%',
        backgroundColor: colors.APP_BACKGROUND,
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    secondPlace: {
        width: '40%',
        height: '70%',
        backgroundColor: colors.APP_BACKGROUND,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        flexDirection: 'column',
        marginRight: '-1vw'
    },
    thirdPlace: {
        width: '40%',
        height: '63%',
        backgroundColor: colors.APP_BACKGROUND,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        flexDirection: 'column',
        marginLeft: '-1vw'
    },
    restOfRank: {
        position: 'relative',
        height: '70%',
        width: '100%',
        marginTop: '2vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'scroll',
        scrollbarWidth: 'none' /* Pour Firefox */,
        '-ms-overflow-style': 'none' /* Pour Internet Explorer et Edge */,
        '&::-webkit-scrollbar': {
            display: 'none' /* Pour Chrome, Safari, et Opera */
        }
    },
    rankLine: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1vh',
        width: '90%'
    },
    subContainer: {
        width: '100%',
        height: '94%',
        display: 'flex',
        marginRight: '1vw',
        marginLeft: '1vw',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    qrCode: {
        width: '25%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        borderRadius: 10,
        marginLeft: '2vw'
    },
    leftContainer: {
        width: '40%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    bottomContainer: {
        width: '100%',
        borderRadius: 10,
        height: '80%',
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        marginTop: '3vh',
        justifyContent: 'space-between',
        backgroundColor: 'black'
    },
    rank: {
        width: '60%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10
    }
};

export default Leaderboard;
