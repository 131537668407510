import {
  collection,
  getDocs,
  where,
  query,
  doc,
  getDoc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore';
import {auth, firestore} from 'utils/firebaseInit';
import {createUserInDB} from './authApi';

const USER_COLLECTION = 'users';

export async function getUserInfoFromId(id) {
  if (auth) {
    try {
      const docRef = doc(firestore, USER_COLLECTION, id);
      const docSnap = await getDoc(docRef);
      if (docSnap?.exists()) {
        return docSnap?.data();
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document! inGetUserInfoFromId');
        return;
      }
    } catch (error) {
      console.log('error in getUserInfoFromId', error);
    }
  }
}

export async function doesUserExist(id) {
  try {
    const docRef = doc(firestore, USER_COLLECTION, id);
    const docSnap = await getDoc(docRef);
    if (docSnap?.exists()) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log('error in doesUserExist', error);
  }
}

export async function getMultipleUserInfo(listId) {
  if (auth) {
    try {
      const resultArray = [];
      for (const userId in listId) {
        if (listId[userId] === undefined) {
          return;
        }
        const userInfoFomId = await getUserInfoFromId(listId[userId]);
        if (userInfoFomId === undefined) return;
        userInfoFomId.id = listId[userId];
        resultArray.push(userInfoFomId);
      }
      return resultArray;
    } catch (error) {
      console.log('error in getMultipleUserInfo', error);
    }
  }
}

export async function getAthleteIdByEmail(email) {
  try {
    const ref = query(
      collection(firestore, USER_COLLECTION),
      where('emailAddress', '==', email),
    );
    const querySnapshot = await getDocs(ref);
    let data = {};
    querySnapshot.forEach(doc => {
      data = doc?.id;
    });
    return data;
  } catch (error) {
    return {
      error: 'Error',
    };
  }
}

export async function deleteProfile() {
  const userRef = auth.currentUser;
  if (userRef) {
    const db = firestore;
    try {
      await auth.currentUser.delete();
      await deleteDoc(doc(db, 'users', userRef.uid));
      return 'success';
    } catch (error) {
      return error;
    }
  }
}

export async function deleteUserInfos() {
  const userRef = auth.currentUser;
  if (userRef) {
    const db = firestore;
    try {
      await deleteDoc(doc(db, 'users', userRef.uid));
      return 'success';
    } catch (error) {
      return error;
    }
  }
}

export async function updateProfile(updatedProfile, uid = null, merge = true) {
  const userRef = auth.currentUser;
  const userUid = uid || userRef.uid;
  if (userRef) {
    try {
      const collectionRef = collection(firestore, 'users');
      const ref = doc(collectionRef, userUid);
      setDoc(ref, updatedProfile, {merge: merge});
      return ref;
    } catch (error) {
      console.log('error', error);
      return error;
    }
  }
}

export async function getUidFromEmail(email) {
  try {
    const ref = query(
      collection(firestore, USER_COLLECTION),
      where('emailAddress', '==', email),
    );
    //return uid that is the name of the document
    const querySnapshot = await getDocs(ref);
    let data = {};
    querySnapshot.forEach(doc => {
      data = doc?.id;
    });
    if (Object.keys(data)?.length === 0)
      return {
        error: 'Error',
      };
    return data;
  } catch (error) {
    console.log('error in getUidFromEmail', error);
    return {
      error: 'Error',
    };
  }
}
