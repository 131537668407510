import {useEffect, useState} from 'react';
import {collection, doc, getDocs, onSnapshot} from 'firebase/firestore';
import {useDispatch} from 'react-redux';
import {setListAnalysis} from 'slices/analysisSlice';
import {firestore} from 'utils/firebaseInit';
import {store} from 'store';

const PROJECT_COLLECTION = 'projects';

export function useProjectChanges() {
  const dispatch = useDispatch();
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState(null);
  const [analysisDetails, setAnalysisDetails] = useState([]);
  const user = store?.getState()?.user;

  useEffect(() => {
    if (!user || user.userID === 'Unknown') {
      setProjects(null);
      setProjectsLoading(false);
    }

    const unsubscribe = onSnapshot(
      doc(collection(firestore, PROJECT_COLLECTION), user?.userID),
      docSnapshot => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          dispatch(setListAnalysis(data?.analysis || []));
          setProjects(data);

          const analysisDetailsRef = collection(
            docSnapshot.ref,
            'analysisDetails',
          );

          const unsubscribeDetails = onSnapshot(
            analysisDetailsRef,
            querySnapshot => {
              const details = querySnapshot.docs.map(doc => doc.data());
              setAnalysisDetails(details);
            },
          );

          setProjectsLoading(false);

          return () => {
            unsubscribeDetails();
          };
        } else {
          console.log('No project data found for user.');
          setProjectsLoading(false);
        }
      },
      err => {
        setError(err);
        setProjectsLoading(false);
      },
    );

    return () => {
      unsubscribe();
    };
  }, [dispatch, user]);

  return {projects, analysisDetails, projectsLoading, error};
}
