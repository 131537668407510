import TextComponent from "components/atoms/TextComponent";
import { BODY_1 } from "constant/constants";
import React from "react";
import { score_colors } from "screen/analysis/AnalysisListCard/constants/analysisListConstants";
import { translate } from "services/remoteConfig";

function ScoreTextWithBackground({ scoreNumber }) {
  if (!scoreNumber) return null;

  function getText() {
    if (isNaN(scoreNumber)) {
      switch (scoreNumber) {
        case "score_bad":
          return translate("Bad");
        case "score_okay":
          return translate("Okay");
        default:
          return translate("Good");
      }
    } else {
      if (scoreNumber < 0.33) return translate("Bad")
      else if (scoreNumber < 0.66) return translate("Okay")
      else return translate("Good")
    }
  }
  return (
    <div
      style={{
        height: 30,
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: isNaN(scoreNumber)
          ? score_colors[scoreNumber].scoreBackground
          : scoreNumber < 0.33
            ? score_colors["score_bad"].scoreBackground
            : scoreNumber < 0.66
              ? score_colors["score_okay"].scoreBackground
              : score_colors["score_good"].scoreBackground,
        borderRadius: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TextComponent
        text={
          getText()
        }
        variant={BODY_1}
        sx={{
          color: isNaN(scoreNumber)
            ? score_colors[scoreNumber].stroke
            : scoreNumber < 0.33
              ? score_colors["score_bad"].stroke
              : scoreNumber < 0.66
                ? score_colors["score_okay"].stroke
                : score_colors["score_good"].stroke,
          margin: 0,
        }}
      />
    </div>
  );
}

export default ScoreTextWithBackground;
