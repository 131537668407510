import TextComponent from "components/atoms/TextComponent";
import { BOLD, H5 } from "constant/constants";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAccountUid, selectVideoId, setCurrentVideoId } from "slices/adminSlice";
import { colors } from "themes";
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import { deleteAnalysisFromProjects } from "services/projectApi";
import './styles/BackOfficeProject.css';
import './styles/BackOffice.css';
import { analysisListData } from "slices/analysisSlice";

function BackOfficeProject({ project, modalRef, setUserInfo }) {
    const userId = useSelector(selectAccountUid)
    const currentVideoId = useSelector(selectVideoId);

    const dispatch = useDispatch();

    async function handleSubmit(id) {
        if (!id) return;
        dispatch(setCurrentVideoId(id));
    }

    const handleDelete = async (id) => {
        modalRef.current.open();
        modalRef?.current?.setContent(
            <>
                <TextComponent
                    text='Are you sure you want to delete this analysis?'
                    variant={H5}
                    weight={BOLD}
                    sx={{ color: colors.WHITE, textAlign: 'center', marginBottom: '2vh' }}
                />
                <div className={`button button-delete`} onClick={() => {
                    deleteAnalysisFromProjects(id, userId);
                    dispatch(setCurrentVideoId(null));
                    const updatedProjects = project?.filter((element) => element !== id);
                    setUserInfo((prevUserInfo) => ({
                        ...prevUserInfo,

                        projects: {
                            ...prevUserInfo?.projects,
                            projects: updatedProjects
                        }
                    }));
                    modalRef.current.setClose();
                }
                }>
                    <TrashIcon className="button-icon" fill="white" />
                    <TextComponent text={'Confirm delete'} sx={{ color: colors.WHITE }} />
                </div>
            </>
        )
    }

    return (
        <div className="category-container">
            {project && (<TextComponent
                text={'Project : ' + project?.length + ' Analysis'}
                variant={H5}
                weight={BOLD}
                sx={{ color: colors.WHITE, textAlign: 'center', marginBottom: '2vh' }} />)}

            <div style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                marginBottom: '2vh'
            }}>

                {project && [...project]?.reverse()?.map((id, index) => (
                    <div
                        key={index}
                        onClick={() => handleSubmit(id)}
                        className={`analysis-card ${id === currentVideoId ? 'selected' : ''}`}
                    >
                        <TextComponent text={id} sx={{ color: colors.WHITE }} />

                        <div
                            className='delete-icon'
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(id);
                            }}
                        >
                            <TrashIcon height={'3vh'} width={'3vh'} fill="white" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BackOfficeProject;
