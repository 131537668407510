import {getAuth} from 'firebase/auth';
import {doc, getDoc, setDoc} from 'firebase/firestore';
import {firestore} from 'utils/firebaseInit';
import {translate} from './remoteConfig';

const PROMO_COLLECTION = 'promotionCode';
const USAGE_COLLECTION = 'usages';

export async function checkCodeExists(promoCodeId) {
  const currentUser = getAuth().currentUser;
  if (currentUser) {
    try {
      const docRef = doc(firestore, PROMO_COLLECTION, promoCodeId);
      const docSnap = await getDoc(docRef);
      if (docSnap?.exists()) {
        const data = docSnap.data();
        //check if the promo code is valid (not expired)
        const today = new Date();
        const expire = data?.expired.toDate();
        if (data?.starting?.toDate() > today) {
          return translate('NoCode');
        }
        if (expire?.getTime() > today?.getTime()) {
          const usageRef = doc(firestore, USAGE_COLLECTION, currentUser.uid);
          const usageSnap = await getDoc(usageRef);
          if (usageSnap?.exists()) {
            const usageList = usageSnap.data();
            if (
              usageList?.promoCode?.find(
                element => element?.promoCode === promoCodeId,
              )
            ) {
              return translate('AlreadyUsed');
            }
          }
          if (data?.number > 0) {
            return true;
          }
        }
        return translate('Expired');
      } else {
        return translate('NoCode');
      }
    } catch (error) {
      console.log('Error in promoCode api : ', error);
    }
  }
}

export async function consumePromoCodeAndAddInUsage(promoCodeId) {
  const currentUser = getAuth().currentUser;
  if (currentUser) {
    try {
      const usageRef = doc(firestore, USAGE_COLLECTION, currentUser.uid);
      const usageSnap = await getDoc(usageRef);
      if (usageSnap?.exists()) {
        const usageData = usageSnap.data();
        if (usageData === undefined) {
          return null;
        }
        let date = new Date()?.toISOString();
        let data = {
          promoCode: promoCodeId,
          date: date,
        };
        if (usageData?.promoCode === undefined) {
          usageData.promoCode = [data];
        } else {
          usageData.promoCode.push(data);
        }
        await setDoc(usageRef, usageData, {merge: true});
      }

      const docRef = doc(firestore, PROMO_COLLECTION, promoCodeId);
      const docSnap = await getDoc(docRef);
      if (docSnap?.exists()) {
        const data = docSnap.data();
        data.number = data.number - 1;
        await setDoc(docRef, data);
        return {success: true, message: '', data: data};
      }
    } catch (error) {
      console.log(error);
      return translate('ErrorPromoCode');
    }
  }
}

export async function getPromoCodePartner(promoCodeId) {
  try {
    const docRef = doc(firestore, PROMO_COLLECTION, promoCodeId);
    const docSnap = await getDoc(docRef);
    if (docSnap?.exists()) {
      const data = docSnap.data();
      return data?.partnerName;
    }
  } catch (error) {
    console.log('Error in promoCode api : ', error);
  }
}
