import ButtonComponent from "components/atoms/ButtonComponent";
import TextComponent from "components/atoms/TextComponent";
import TextInput from "components/atoms/TextInput";
import { BOLD, H4, H5, ORANGE } from "constant/constants";
import React, { useEffect, useRef, useState } from "react";
import { colors } from "themes";
import { updateProfile } from "services/userApi";
import { getUsage, updateUsages } from "services/usageApi";
import BackOfficeUsage from "./BackOfficeUsage";
import BackOfficeProject from "./BackOfficeProject";
import { useDispatch, useSelector } from "react-redux";
import { resetAdminStore, selectAccountUid } from "slices/adminSlice";
import BackOfficeAnalysis from "./BackOfficeAnalysis";
import ModalComponent from "components/molecules/ModalComponent";
import BackOfficeUser from "./BackOfficeUser";
import SidePan from "components/molecules/SidePan";
import DocDisplay from "./DocDisplay";
import { ReactComponent as PenIcon } from 'assets/svg/pen.svg';
import AddPlanToUsage from "./component/AddPlanToUsage";
import AddFieldInDb from "./component/AddFieldInDb";
import EditModal from "./component/EditModal";
import DeleteModal from "./component/DeleteModal";
import ResetUsageModal from "./component/ResetUsageModal";

function BackOffice() {
    const [userInfo, setUserInfo] = useState(null);
    const modalRef = useRef();
    const sidePanRef = useRef();
    const [sidePanContent, setSidePanContent] = useState(userInfo?.user);

    const [editDb, setEditDb] = useState(false);
    const dispatch = useDispatch();
    const accountUid = useSelector(selectAccountUid);
    const userSlice = useSelector(state => state?.user);


    useEffect(() => {
        const updateData = async () => {
            if (editDb) {
                switch (sidePanContent?.type) {
                    case 'user':
                        await updateProfile(userInfo?.user, accountUid, false);
                        setSidePanContent({ ...sidePanContent, data: userInfo?.user });
                        break;
                    case 'usage':
                        await updateUsages(userInfo?.usage, accountUid);
                        setSidePanContent({ ...sidePanContent, data: userInfo?.usage });
                        break;
                    default:
                        break;
                }
                setEditDb(false);
            }
        };

        updateData();
    }, [editDb]);


    async function handleDelete(key) {
        modalRef.current.open();
        modalRef?.current?.setContent(
            <DeleteModal textKey={key} setState={setUserInfo} setEditDb={setEditDb} modalRef={modalRef} type={sidePanContent?.type} />
        );
    }

    async function handleEdit(path, key, value) {
        modalRef.current.setEditField(value)
        modalRef.current.open();
        modalRef?.current?.setContent(
            <EditModal textKey={key} path={path} type={sidePanContent?.type} value={value} modalRef={modalRef} setState={setUserInfo} setEditDb={setEditDb} />
        );
    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', marginTop: '1vh' }}>
            <div style={{ alignSelf: 'center', backgroundColor: colors.BLACK, width: '98%', height: '96%', borderRadius: '10px' }}>
                <div style={{ cursor: 'pointer' }} onClick={() => dispatch(resetAdminStore())}>
                    <TextComponent
                        text="BackOffice"
                        variant={H4}
                        weight={BOLD}
                        sx={{ color: colors.WHITE, textAlign: 'center', marginTop: '2vh' }}
                    />
                </div>


                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '4vh', width: '100%', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <BackOfficeUser userInfo={userInfo} setUserInfo={setUserInfo} setSidePanContent={setSidePanContent} sidePanRef={sidePanRef} />
                        {userInfo && (
                            <>
                                <BackOfficeUsage usage={userInfo?.usage} modalRef={modalRef} setUserInfo={setUserInfo} setSidePanContent={setSidePanContent} sidePanRef={sidePanRef} userType={userInfo?.user?.type} />
                                <BackOfficeProject project={userInfo?.projects?.projects} modalRef={modalRef} setUserInfo={setUserInfo} />
                            </>
                        )}
                    </div>
                    <BackOfficeAnalysis analysisDetails={userInfo?.projects?.analysisDetails} modalRef={modalRef} setUserInfo={setUserInfo} />


                </div>
            </div>

            <ModalComponent ref={modalRef} />


            {userInfo && (<SidePan
                ref={sidePanRef}
                onButtonPressed={(e) => {
                    e.stopPropagation();
                    sidePanRef?.current?.close();
                }}>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '20%',
                        padding: '2vh',
                        flexDirection: 'column',
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginBottom: '4vh' }}>
                        {userSlice?.dev === true && (<ButtonComponent
                            title={'Add a field'}
                            type={ORANGE}
                            sx={{ backgroundColor: colors.SECONDARY, alignSelf: 'center' }}
                            onClick={() => {
                                modalRef.current.open();
                                modalRef.current.setContent(
                                    <AddFieldInDb setUserInfo={setUserInfo} setEditDb={setEditDb} setSidePanContent={setSidePanContent} modalRef={modalRef} sidePanContent={sidePanContent} />
                                );
                            }}
                        />)}
                        {sidePanContent?.type === 'usage' && (
                            <>
                                <ButtonComponent
                                    title={'Add a plan'}
                                    type={ORANGE}
                                    sx={{ backgroundColor: colors.SECONDARY, alignSelf: 'center' }}
                                    onClick={() => {
                                        modalRef.current.open();
                                        modalRef.current.setContent(<AddPlanToUsage userUsage={userInfo?.usage} modalRef={modalRef} setUserInfo={setUserInfo} setSidePanContent={setSidePanContent} setEditDb={setEditDb} userType={userInfo?.user?.type} />

                                        );
                                    }}
                                />
                                <ButtonComponent
                                    title={'Reset usage'}
                                    type={ORANGE}
                                    sx={{ backgroundColor: colors.SECONDARY, alignSelf: 'center' }}
                                    onClick={() => {
                                        modalRef.current.open();
                                        modalRef.current.setContent(<ResetUsageModal setEditDb={setEditDb} modalRef={modalRef} setUserInfo={setUserInfo} />

                                        );
                                    }}
                                />
                            </>
                        )}
                    </div>
                    {sidePanContent &&
                        <DocDisplay data={Object.entries(sidePanContent?.data)
                            .sort((a, b) => a[0].localeCompare(b[0]))
                            .reduce((acc, [key, value]) => {
                                acc[key] = value;
                                return acc;
                            }, {})}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                        />}

                </div>
            </SidePan>)}
        </div >
    );
}


export default BackOffice;
