export const tos = {
  en: `Terms Of Use
    These Terms of Use apply to all websites that are owned, operated, and maintained by or for S.A.S Ochy, a France corporation, and its affiliates (collectively, “we,” “us,” or “our”) including [ochy.io], and other websites on which these Terms of Use are linked (“Sites”) as well as your use of the services we provide, our mobile application, and other transactions and engagements you might have with us including interactions via social media (collectively, “Services”).
    
    1. General Provisions
    THESE TERMS OF USE TOGETHER WITH THE PRIVACY POLICY, AND ANY SUPPLEMENTAL TERMS, CONDITIONS, OR RULES POSTED TO A SPECIFIC AREA OF THE SITES AND SERVICES (COLLECTIVELY, “TERMS”) SET FORTH THE LEGALLY BINDING TERMS GOVERNING YOUR USE OF THE SITE AND SERVICES.
    
    By entering the Sites or using the Services, you acknowledge and agree to all terms, conditions, and rules stated in these Terms. You are not permitted to use the Sites or the Services if you do not agree to be legally bound by these Terms. Please read these Terms carefully. The Sites and Services are intended for use only by citizens and legal permanent residents of France residing within the France 13 years of age or older. If you are not a member of the intended audience, you are prohibited from accessing the Sites or using the Services.
    
    We may, in our sole discretion, modify the Terms from time to time and we reserve the right to make changes at any time, without notice or obligation, to the Sites or Services. If a change is material, as determined by us in our sole discretion, we will provide at least fourteen (14) days’ notice prior to the effective date of the change via a post on the Sites and/or email to you. By not terminating your relationship with us prior to the effective date of the change, you acknowledge and agree that you will be bound by the updated Terms and/or Sites or Services. We suggest periodically visiting this page of the Sites to review these Terms.
    
    You represent and warrant that you are the person you claim to be in your interactions with us, the Sites, and Services. You will use the Sites and Services in compliance with all applicable laws and regulations and you will not use them to defraud, harm, or impersonate another person.
    
    1.1 Password and Account Security
    You may create your own login information by completing the online or in-app registration process. In doing so, you must provide us with accurate and complete registration information and update it if this information changes. You agree that you will not register under the name of another person, choose a username that is vulgar, profane or otherwise offensive, choose a username that impersonates or suggests representation of another person or entity, or choose a username that includes a solicitation
    
    If you create a login, we will assign you, or allow you to select, a password. You must keep your password confidential, and you may not permit any other person to use your password. You will be responsible for all use of your password, including, without limitation, any use by any authorized or unauthorized third party. You must notify us immediately if you believe your password may be used by any unauthorized person or entity. For security purposes, we recommend you change your password often. Under no circumstance should you respond to a request for your password. Our employees will never ask for your password. You must notify us immediately if you receive such a request. We reserve the right to suspend or terminate your use of the Sites or Services if we believe that your password is being used without permission or otherwise in a manner that may disrupt the Sites or Services.
    
    1.2 Device Requirements
    To utilize our app on your smartphone or other device, your device must meet the specific system requirements outlined on in the Apple App Store or Google Play Store descriptions of the app
    
    2. User Guidelines
    You agree that you will not use the Sites or Services to transmit communications that defame, abuse, harass, stalk, threaten, or otherwise violate the rights of any other person. You also agree that you will not use the Sites or Services to transmit communications containing material that is unlawful, harassing, defamatory, abusive, indecent, threatening, harmful, vulgar, obscene, pornographic, or discriminatory. You will not use the Sites or Services to commit fraud, engage in criminal activity, or interfere with the use of the Sites or Services by others. You will not tamper with, interfere with, or otherwise hinder anyone’s use of the Sites or Services. We reserve the right, but are under no obligation, to review your communications and without notice terminate your access to and use of the Sites or Services if we determine, based on our sole discretion, that you violated the foregoing user guidelines. Further, you will not reproduce, decompile, reverse engineer, or otherwise engage in any activity with the intent or effect of recreating or transferring to yourself or any third-party all or a portion of the Sites or Services.
    
    3. User Data
    We will maintain certain data that you transmit as part of the Services for purposes of managing the Services and providing the Services to you. We do not warrant that we will retain your data for any period of time or at all. You should retain copies and backups of all data you provide to us. You agree that we will have no liability to you for any loss or corruption of data you provide to us or that we store associated with your use of the Services.
    
    4. Termination
    We may suspend or terminate your use of the Sites or Services at any time for any reason and without notice to you. Upon termination, we may, but are under no obligation to, delete any and all information stored by us related to your use of the Sites or Services. If we terminate your use of the Sites or Services due to a violation of these Terms by you, as determined by us in our sole discretion, you will not be entitled to a refund of any fees paid by you to us.
    
    4.1 Disclaimer of Warranties
    The Sites and Services are provided to you on an "AS IS" and “AS AVAILABLE” basis, and AT YOUR OWN RISK TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW. WE DISCLAIM, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE AND ALL WARRANTIES REGARDING SECURITY, CURRENCY, CORRECTNESS, QUALITY, ACCURACY, COMPLETENESS, RELIABILITY, PERFORMANCE, TIMELINESS, OR CONTINUED AVAILABILITY WITH RESPECT TO THE SITE AND SERVICES. We expressly disclaim, to the fullest extent permitted by applicable law, any warranties with respect to any downtime, delays or errors in the transmission or delivery of any communications, information, materials, or services through the Sites or Services. To the extent a jurisdiction does not allow the exclusion of certain warranties, some of the above exclusions may not apply. You understand and agree that the action or inaction of third parties is outside of our control and that we do not guarantee any third party will take any action or refrain from taking any action.
    
    We do not warrant, endorse, guarantee, or assume responsibility for any third-party product or service recommended, advertised, or offered for sale on or through the Sites or Services or any website linked to the Sites or Services.
    
    4.2 Our Intellectual Property
    All copyrights, trademarks, trade dress, other intellectual property and materials, including images, text, illustrations, formats, logos, designs, icons, photographs, programs, music clips, video clips, and written and other materials on or part of the Sites and Services (collectively, the “IP”) are owned, controlled or licensed by us and are protected by France and international trademark and copyright laws. The absence of our name or logo does not constitute a waiver of our trademark or other intellectual property rights relating to such name or logo. All other product names, company names, marks, logos, and symbols appearing on the Sites or Services may be the trademarks and the property of their respective owners. Software used as part of the Sites and Services is our property or our suppliers’ property and is protected by France and international copyright laws.
    
    Subject to your strict compliance with these Terms, we grant you a limited, non-exclusive, revocable, non-assignable, personal, and non-transferable license to access, view, download, and print the materials displayed on the Sites and Services for your use and access only; provided, however, that you (i) retain all copyright, trademark or other proprietary designations or watermarks contained on all IP; (ii) do not modify or alter the IP in any way; and (iii) do not provide or make available the IP to any third party in a commercial manner. Other than this limited license, no license, right, title, or interest in any IP is transferred to you as a result of your use of the Sites or Services or your accessing, viewing, downloading, or printing of the IP. You may not reproduce (except as noted above), publish, transmit, distribute, display, modify, create derivative works from, reverse engineer, sell or participate in any sale of, or exploit in any way, in whole or in part, any of the IP, the Sites, Services, or any related software.
    
    4.3 Force Majeure
    We will be excused from failures or delays in delivery or performance of the Sites and/or Services, if such failure or delay is attributable to causes our reasonable control such as weather, acts of God, natural disaster, war, terrorist attack, disease, epidemic/pandemic, criminal activity, riot, civil unrest, strike, or utility failure.
    
    4.4 Feedback
    We always welcome and appreciate your feedback and suggestions. You understand that we cannot necessarily respond to or implement feedback or suggestions. You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information provided by you to us are yours personally and not owned by a commercial entity. You grant us a worldwide, unrestricted, royalty-free license to distribute, publish, modify, edit, or otherwise use your submissions for any purpose, commercial or otherwise, without any acknowledgement of or compensation to you.
    
    4.5 Miscellaneous
    The Terms constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of the Terms will not operate as a waiver of such right or provision. If any provision of the Terms is determined to be unlawful, void, or unenforceable, the parties intend that the offending provision be modified to the minimum extent necessary to be lawful and enforceable. Portions of the Terms which by their nature would survive termination of your use of the Sites or Services (e.g., disclaimer of warranties, limitation of liability, indemnification) will be deemed to survive. Headings are provided as a convenience and should not be used as interpretive aids. The Terms do not create any joint venture, partnership, employment, or agency relationship between the parties. You agree that ambiguities in the Terms will not be construed against us by attribution of drafting. We may assign any of our rights or obligations to others at any time without notice to you. You may not assign any of your rights or obligations to others without our prior written consent.`,
  fr: `Conditions D'utilisation
    Ces conditions d'utilisation s'appliquent à tous les sites web qui sont détenus, exploités et maintenus par ou pour S.A.S Ochy, une société française, et ses sociétés affiliées (collectivement, "nous" ou "notre"), y compris [ochy.io], et d'autres sites web sur lesquels ces conditions d'utilisation sont liées ("Sites") ainsi que votre utilisation des services que nous fournissons, notre application mobile, et d'autres transactions et engagements que vous pourriez avoir avec nous, y compris les interactions via les médias sociaux (collectivement, "Services").
    
    1. Dispositions Générales
    LES PRÉSENTES CONDITIONS D'UTILISATION AINSI QUE LA POLITIQUE DE CONFIDENTIALITÉ ET LES CONDITIONS OU RÈGLES SUPPLÉMENTAIRES AFFICHÉES DANS UNE ZONE SPÉCIFIQUE DES SITES ET SERVICES (COLLECTIVEMENT, LES "CONDITIONS") DÉFINISSENT LES CONDITIONS JURIDIQUEMENT CONTRAIGNANTES RÉGISSANT VOTRE UTILISATION DU SITE ET DES SERVICES.
    
    En accédant aux sites ou en utilisant les services, vous reconnaissez et acceptez tous les termes, conditions et règles énoncés dans les présentes conditions. Vous n'êtes pas autorisé à utiliser les sites ou les services si vous n'acceptez pas d'être légalement lié par les présentes conditions. Veuillez lire attentivement ces conditions. Les Sites et les Services sont destinés à être utilisés uniquement par les citoyens et les résidents permanents légaux de France résidant en France, âgés de 13 ans ou plus. Si vous ne faites pas partie du public visé, il vous est interdit d'accéder aux sites ou d'utiliser les services.
    
    Nous pouvons, à notre seule discrétion, modifier les Conditions de temps à autre et nous nous réservons le droit d'apporter des changements à tout moment, sans préavis ni obligation, aux Sites ou aux Services. Si une modification est importante, comme nous le déterminons à notre seule discrétion, nous vous donnerons un préavis d'au moins quatorze (14) jours avant la date d'entrée en vigueur de la modification par le biais d'un affichage sur les sites et/ou d'un courrier électronique. En ne mettant pas fin à votre relation avec nous avant la date d'entrée en vigueur de la modification, vous reconnaissez et acceptez d'être lié par les conditions et/ou les sites ou services mis à jour. Nous vous suggérons de visiter périodiquement cette page des Sites pour revoir ces Conditions.
    
    Vous déclarez et garantissez que vous êtes la personne que vous prétendez être dans vos interactions avec nous, les Sites et les Services. Vous utiliserez les Sites et les Services en conformité avec toutes les lois et réglementations applicables et vous ne les utiliserez pas pour frauder, nuire ou usurper l'identité d'une autre personne.
    
    1.1 Sécurité des mots de passe et des comptes
    Vous pouvez créer vos propres informations de connexion en complétant le processus d'enregistrement en ligne ou dans l'application. Ce faisant, vous devez nous fournir des informations d'enregistrement précises et complètes et les mettre à jour si ces informations changent. Vous acceptez de ne pas vous inscrire sous le nom d'une autre personne, de ne pas choisir un nom d'utilisateur vulgaire, blasphématoire ou autrement offensant, de ne pas choisir un nom d'utilisateur qui usurpe l'identité d'une autre personne ou entité ou qui suggère sa représentation, ou de ne pas choisir un nom d'utilisateur qui inclut une sollicitation.
    
    Si vous créez une connexion, nous vous attribuerons, ou vous autoriserons à choisir, un mot de passe. Vous devez garder votre mot de passe confidentiel, et vous ne pouvez autoriser aucune autre personne à l'utiliser. Vous serez responsable de toute utilisation de votre mot de passe, y compris, sans s'y limiter, de toute utilisation par un tiers autorisé ou non. Vous devez nous informer immédiatement si vous pensez que votre mot de passe peut être utilisé par une personne ou une entité non autorisée. Pour des raisons de sécurité, nous vous recommandons de changer souvent votre mot de passe. En aucun cas, vous ne devez répondre à une demande de votre mot de passe. Nos employés ne vous demanderont jamais votre mot de passe. Vous devez nous informer immédiatement si vous recevez une telle demande. Nous nous réservons le droit de suspendre ou de mettre fin à votre utilisation des sites ou des services si nous pensons que votre mot de passe est utilisé sans autorisation ou d'une manière susceptible de perturber les sites ou les services.
    
    1.2 Exigences relatives aux appareils
    Pour utiliser notre application sur votre smartphone ou autre appareil, votre appareil doit répondre aux exigences spécifiques du système décrites dans les descriptions de l'application sur l'App Store d'Apple ou le Play Store de Google.
    
    2. Directives Pour Les Utilisateurs
    Vous acceptez de ne pas utiliser les sites ou les services pour transmettre des communications qui diffament, abusent, harcèlent, traquent, menacent ou violent autrement les droits d'une autre personne. Vous acceptez également de ne pas utiliser les sites ou services pour transmettre des communications contenant du matériel illégal, harcelant, diffamatoire, abusif, indécent, menaçant, nuisible, vulgaire, obscène, pornographique ou discriminatoire. Vous n'utiliserez pas les sites ou les services pour commettre une fraude, vous livrer à une activité criminelle ou interférer avec l'utilisation des sites ou des services par d'autres personnes. Vous n'altérerez pas, n'interférerez pas et n'entraverez pas de quelque manière que ce soit l'utilisation des sites ou des services par quiconque. Nous nous réservons le droit, mais n'avons aucune obligation, d'examiner vos communications et de mettre fin sans préavis à votre accès et à votre utilisation des sites ou des services si nous déterminons, à notre seule discrétion, que vous avez enfreint les directives d'utilisation susmentionnées. En outre, vous ne reproduirez pas, ne décompilerez pas, ne ferez pas d'ingénierie inverse et ne vous livrerez pas à une activité ayant pour but ou pour effet de recréer ou de transférer à vous-même ou à un tiers tout ou partie des sites ou des services.
    
    3. Données De L'utilisateur
    Nous conserverons certaines données que vous transmettez dans le cadre des Services à des fins de gestion des Services et pour vous fournir les Services. Nous ne garantissons pas que nous conserverons vos données pendant une période donnée ou pas du tout. Vous devez conserver des copies et des sauvegardes de toutes les données que vous nous fournissez. Vous acceptez que nous n'ayons aucune responsabilité envers vous pour toute perte ou corruption des données que vous nous fournissez ou que nous stockons en relation avec votre utilisation des Services.
    
    4. Terminaison
    Nous pouvons suspendre ou mettre fin à votre utilisation des sites ou des services à tout moment, pour quelque raison que ce soit et sans préavis. En cas de résiliation, nous pouvons, sans y être obligés, supprimer toutes les informations stockées par nos soins et liées à votre utilisation des sites ou des services. Si nous mettons fin à votre utilisation des sites ou des services en raison d'une violation de ces conditions de votre part, comme nous le déterminons à notre seule discrétion, vous n'aurez pas droit à un remboursement des frais que vous nous avez payés.
    
    4.1 Exclusion des garanties
    Les Sites et Services vous sont fournis " EN L'ÉTAT " et " COMME DISPONIBLE ", et À VOS PROPRES RISQUES DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE. NOUS DÉCLINONS, DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE, TOUTE GARANTIE, QU'ELLE SOIT EXPRESSE OU IMPLICITE, Y COMPRIS, SANS S'Y LIMITER, TOUTE GARANTIE IMPLICITE DE TITRE, DE QUALITÉ MARCHANDE, D'ABSENCE DE CONTREFAÇON ET D'ADÉQUATION À UN USAGE PARTICULIER ET TOUTE GARANTIE CONCERNANT LA SÉCURITÉ, L'ACTUALITÉ, L'EXACTITUDE, LA QUALITÉ, LA PRÉCISION, L'EXHAUSTIVITÉ, LA FIABILITÉ, LES PERFORMANCES, L'OPPORTUNITÉ OU LA DISPONIBILITÉ CONTINUE DU SITE ET DES SERVICES. Nous déclinons expressément, dans toute la mesure permise par la loi applicable, toute garantie concernant les temps d'arrêt, les retards ou les erreurs dans la transmission ou la livraison de toute communication, information, matériel ou service par le biais des sites ou des services. Dans la mesure où une juridiction ne permet pas l'exclusion de certaines garanties, certaines des exclusions ci-dessus peuvent ne pas s'appliquer. Vous comprenez et acceptez que l'action ou l'inaction de tiers est hors de notre contrôle et que nous ne garantissons pas qu'un tiers prendra une mesure ou s'abstiendra de prendre une mesure.
    
    Nous ne garantissons pas, n'approuvons pas, ne garantissons pas et n'assumons pas la responsabilité de tout produit ou service tiers recommandé, annoncé ou proposé à la vente sur ou par le biais des sites ou services ou de tout site Web lié aux sites ou services.
    
    4.2 Notre propriété intellectuelle
    Tous les droits d'auteur, marques, habillages commerciaux, autres propriétés intellectuelles et matériels, y compris les images, textes, illustrations, formats, logos, dessins, icônes, photographies, programmes, clips musicaux, clips vidéo, et matériels écrits et autres sur ou faisant partie des Sites et Services (collectivement, la " PI ") sont détenus, contrôlés ou autorisés par nous et sont protégés par les lois françaises et internationales sur les marques et les droits d'auteur. L'absence de notre nom ou de notre logo ne constitue pas une renonciation à notre marque ou à nos autres droits de propriété intellectuelle relatifs à ce nom ou à ce logo. Tous les autres noms de produits, noms de sociétés, marques, logos et symboles apparaissant sur les Sites ou les Services peuvent être des marques commerciales et la propriété de leurs propriétaires respectifs. Les logiciels utilisés dans le cadre des Sites et Services sont notre propriété ou celle de nos fournisseurs et sont protégés par les lois françaises et internationales sur le droit d'auteur.
    
    Sous réserve de votre strict respect des présentes conditions, nous vous accordons une licence limitée, non exclusive, révocable, incessible, personnelle et non transférable vous permettant d'accéder, de visualiser, de télécharger et d'imprimer le matériel affiché sur les sites et les services pour votre usage et votre accès uniquement ; à condition, toutefois, que vous (i) conserviez tous les droits d'auteur, marques ou autres désignations de propriété ou filigranes contenus dans toute la propriété intellectuelle ; (ii) ne modifiiez pas ou n'altériez pas la propriété intellectuelle de quelque manière que ce soit ; et (iii) ne fournissiez pas ou ne mettiez pas à disposition la propriété intellectuelle à un tiers d'une manière commerciale. À l'exception de cette licence limitée, aucune licence, aucun droit, titre ou intérêt dans la propriété intellectuelle ne vous est transféré à la suite de votre utilisation des sites ou des services ou de votre accès, visualisation, téléchargement ou impression de la propriété intellectuelle. Vous n'êtes pas autorisé à reproduire (à l'exception de ce qui est indiqué ci-dessus), à publier, à transmettre, à distribuer, à afficher, à modifier, à créer des œuvres dérivées, à faire de l'ingénierie inverse, à vendre ou à participer à la vente de, ou à exploiter de quelque façon que ce soit, en tout ou en partie, la propriété intellectuelle, les sites, les services ou tout logiciel connexe.
    
    4.3 Force Majeure
    Nous serons dispensés des défaillances ou des retards dans la livraison ou l'exécution des sites et/ou des services, si ces défaillances ou ces retards sont attribuables à des causes sur lesquelles nous avons un contrôle raisonnable, telles que les conditions météorologiques, les catastrophes naturelles, la guerre, les attaques terroristes, les maladies, les épidémies/pandémies, les activités criminelles, les émeutes, les troubles civils, les grèves ou les pannes de services publics.
    
    4.4 Feedback
    Nous accueillons et apprécions toujours vos commentaires et suggestions. Vous comprenez que nous ne pouvons pas nécessairement répondre à ces commentaires ou suggestions ou les mettre en œuvre. Vous reconnaissez et acceptez que toutes les questions, commentaires, suggestions, idées, réactions ou autres informations que vous nous fournissez vous appartiennent personnellement et ne sont pas la propriété d'une entité commerciale. Vous nous accordez une licence mondiale, illimitée et libre de redevance pour distribuer, publier, modifier, éditer ou utiliser de toute autre manière vos soumissions à quelque fin que ce soit, commerciale ou autre, sans reconnaissance ni compensation de votre part.
    
    4,5 Divers
    Les Conditions constituent l'intégralité de l'accord et de l'entente entre vous et nous. Le fait que nous n'exercions pas ou n'appliquions pas un droit ou une disposition des Conditions ne constitue pas une renonciation à ce droit ou à cette disposition. Si une disposition des Conditions est jugée illégale, nulle ou inapplicable, les parties entendent que la disposition incriminée soit modifiée dans la mesure minimale nécessaire pour être légale et applicable. Les parties des Conditions qui, de par leur nature, survivraient à la fin de votre utilisation des Sites ou des Services (par exemple, l'exclusion de garantie, la limitation de responsabilité, l'indemnisation) seront considérées comme survivantes. Les titres sont fournis à titre de commodité et ne doivent pas être utilisés comme aides à l'interprétation. Les Conditions ne créent aucune relation de coentreprise, de partenariat, d'emploi ou d'agence entre les parties. Vous acceptez que les ambiguïtés des Conditions ne soient pas interprétées contre nous par attribution de rédaction. Nous pouvons céder n'importe lequel de nos droits ou obligations à des tiers à tout moment sans vous en informer. Vous ne pouvez céder aucun de vos droits ou obligations à des tiers sans notre consentement écrit préalable.`,
  de: `Nutzungsbedingungen
  
  Diese Nutzungsbedingungen gelten für alle Websites, die Eigentum der S.A.S Ochy sind, von dieser betrieben oder für diese gewartet werden, einer französischen Gesellschaft, und deren Tochtergesellschaften (kollektiv „wir“, „uns“ oder „unser“), einschließlich [ochy.io], und für andere Websites, auf die sich diese Nutzungsbedingungen beziehen („Websites“) sowie für Ihre Nutzung der von uns angebotenen Dienste, unsere mobile Anwendung und andere Transaktionen und Interaktionen, die Sie mit uns haben könnten, einschließlich der Interaktionen über soziale Medien (kollektiv „Dienste“).
  
  1. Allgemeine Bestimmungen
  
  DIESE NUTZUNGSBEDINGUNGEN ZUSAMMEN MIT DER DATENSCHUTZERKLÄRUNG SOWIE JEDEN ZUSÄTZLICHEN BEDINGUNGEN, VORSCHRIFTEN ODER REGELN, DIE FÜR EINEN BESTIMMTEN BEREICH DER WEBSITES UND DIENSTE VERÖFFENTLICHT WERDEN (KOLLEKTIV „BEDINGUNGEN“), LEGEN DIE RECHTLICH VERBINDLICHEN BEDINGUNGEN FEST, DIE DIE NUTZUNG DER WEBSITE UND DER DIENSTE REGELN.
  
  Durch den Zugriff auf die Websites oder die Nutzung der Dienste erkennen Sie alle in diesen Bedingungen festgelegten Bestimmungen, Vorschriften und Regeln an und stimmen diesen zu. Es ist Ihnen nicht gestattet, die Websites oder die Dienste zu nutzen, wenn Sie nicht damit einverstanden sind, rechtlich an diese Bedingungen gebunden zu sein. Bitte lesen Sie diese Bedingungen sorgfältig durch. Die Websites und Dienste sind ausschließlich für die Nutzung durch Staatsbürger und rechtmäßige, dauerhafte Einwohner Frankreichs ab einem Alter von 13 Jahren gedacht. Wenn Sie nicht zu dieser Zielgruppe gehören, ist Ihnen der Zugriff auf die Websites oder die Nutzung der Dienste untersagt.
  
  Wir können nach eigenem Ermessen die Bedingungen von Zeit zu Zeit ändern und behalten uns das Recht vor, jederzeit Änderungen an den Websites oder Diensten vorzunehmen, ohne Ankündigung oder Verpflichtung. Falls eine Änderung wesentlich ist, wie von uns nach eigenem Ermessen festgelegt, werden wir mindestens vierzehn (14) Tage vor dem Inkrafttreten der Änderung eine Benachrichtigung auf den Websites veröffentlichen und/oder Ihnen eine E-Mail senden. Wenn Sie Ihr Verhältnis zu uns nicht vor dem Inkrafttreten der Änderung beenden, erkennen Sie an und stimmen zu, dass Sie an die aktualisierten Bedingungen und/oder Websites oder Dienste gebunden sind. Wir empfehlen, diese Seite der Websites regelmäßig zu besuchen, um die Bedingungen zu überprüfen.
  
  Sie erklären und garantieren, dass Sie die Person sind, die Sie in Ihren Interaktionen mit uns, den Websites und Diensten vorgeben zu sein. Sie werden die Websites und Dienste in Übereinstimmung mit allen geltenden Gesetzen und Vorschriften nutzen und sie nicht dazu verwenden, um zu betrügen, Schaden zu verursachen oder sich als eine andere Person auszugeben.
  
  1.1 Passwort- und Kontosicherheit
  
  Sie können Ihre eigenen Anmeldedaten erstellen, indem Sie den Online- oder In-App-Registrierungsprozess abschließen. Dabei müssen Sie uns genaue und vollständige Registrierungsinformationen zur Verfügung stellen und diese bei Änderungen aktualisieren. Sie erklären sich damit einverstanden, sich nicht im Namen einer anderen Person zu registrieren, keinen vulgären, obszönen oder anderweitig anstößigen Benutzernamen zu wählen, keinen Benutzernamen zu wählen, der eine andere Person oder Entität imitiert oder repräsentiert, und keinen Benutzernamen zu wählen, der eine Aufforderung enthält.
  
  Wenn Sie ein Login erstellen, werden wir Ihnen ein Passwort zuweisen oder Ihnen die Auswahl eines Passworts gestatten. Sie müssen Ihr Passwort vertraulich behandeln und dürfen keinem anderen die Nutzung Ihres Passworts gestatten. Sie sind verantwortlich für die gesamte Nutzung Ihres Passworts, einschließlich, aber nicht beschränkt auf, die Nutzung durch autorisierte oder nicht autorisierte Dritte. Sie müssen uns unverzüglich benachrichtigen, wenn Sie der Ansicht sind, dass Ihr Passwort von einer unbefugten Person oder Entität verwendet wird. Aus Sicherheitsgründen empfehlen wir, Ihr Passwort regelmäßig zu ändern. Unter keinen Umständen sollten Sie auf eine Anfrage nach Ihrem Passwort reagieren. Unsere Mitarbeiter werden niemals nach Ihrem Passwort fragen. Sie müssen uns sofort benachrichtigen, wenn Sie eine solche Anfrage erhalten. Wir behalten uns das Recht vor, Ihre Nutzung der Websites oder Dienste auszusetzen oder zu beenden, wenn wir der Ansicht sind, dass Ihr Passwort ohne Erlaubnis verwendet wird oder auf andere Weise die Websites oder Dienste stört.
  
  1.2 Geräteanforderungen
  
  Um unsere App auf Ihrem Smartphone oder einem anderen Gerät zu nutzen, muss Ihr Gerät die spezifischen Systemanforderungen erfüllen, die in den Beschreibungen der App im Apple App Store oder Google Play Store aufgeführt sind.
  
  2. Benutzerleitlinien
  
  Sie stimmen zu, die Websites oder Dienste nicht dazu zu nutzen, um Mitteilungen zu übermitteln, die diffamierend, beleidigend, belästigend, nachstellend, bedrohend sind oder die Rechte einer anderen Person in anderer Weise verletzen. Sie stimmen außerdem zu, die Websites oder Dienste nicht dazu zu nutzen, um Mitteilungen zu übermitteln, die rechtswidrig, belästigend, diffamierend, beleidigend, unanständig, bedrohlich, schädlich, vulgär, obszön, pornografisch oder diskriminierend sind. Sie werden die Websites oder Dienste nicht dazu verwenden, um Betrug zu begehen, an kriminellen Aktivitäten teilzunehmen oder die Nutzung der Websites oder Dienste durch andere zu beeinträchtigen. Sie werden nicht manipulieren, eingreifen oder anderweitig den Gebrauch der Websites oder Dienste durch andere behindern. Wir behalten uns das Recht vor, aber sind nicht dazu verpflichtet, Ihre Mitteilungen zu überprüfen und ohne Vorankündigung Ihren Zugriff auf und Ihre Nutzung der Websites oder Dienste zu beenden, wenn wir nach eigenem Ermessen feststellen, dass Sie die oben genannten Benutzerleitlinien verletzt haben. Außerdem werden Sie weder reproduzieren, dekompilieren, rückentwickeln noch an Aktivitäten teilnehmen, die darauf abzielen oder bewirken, dass Sie oder ein Dritter einen Teil oder das gesamte Material der Websites oder Dienste nachbilden oder übertragen.
  
  3. Benutzerdaten
  
  Wir werden bestimmte Daten, die Sie als Teil der Dienste übermitteln, zum Zwecke der Verwaltung der Dienste und der Bereitstellung der Dienste für Sie speichern. Wir garantieren nicht, dass wir Ihre Daten für einen bestimmten Zeitraum oder überhaupt speichern werden. Sie sollten Kopien und Backups aller Daten, die Sie uns zur Verfügung stellen, behalten. Sie erklären sich damit einverstanden, dass wir keine Haftung für Verluste oder Beschädigungen der von Ihnen zur Verfügung gestellten Daten übernehmen oder für Daten, die wir im Zusammenhang mit Ihrer Nutzung der Dienste speichern.
  
  4. Kündigung
  
  Wir können Ihre Nutzung der Websites oder Dienste jederzeit und aus beliebigen Gründen ohne Vorankündigung aussetzen oder beenden. Nach Beendigung können wir, sind jedoch nicht verpflichtet, alle von uns gespeicherten Informationen im Zusammenhang mit Ihrer Nutzung der Websites oder Dienste löschen. Wenn wir Ihre Nutzung der Websites oder Dienste aufgrund eines Verstoßes gegen diese Bedingungen durch Sie beenden, wie von uns nach eigenem Ermessen festgestellt, haben Sie keinen Anspruch auf eine Rückerstattung etwaiger Gebühren, die Sie an uns gezahlt haben.
  
  4.1 Haftungsausschluss
  
  Die Websites und Dienste werden Ihnen „WIE BESEHEN“ und „WIE VERFÜGBAR“ zur Verfügung gestellt, UND AUF IHR EIGENES RISIKO IM VOLLEN GESETZLICH ZULÄSSIGEN UMFANG. WIR LEHNEN, IM VOLLEN GESETZLICH ZULÄSSIGEN UMFANG, ALLE GARANTIEN AB, OB AUSDRÜCKLICH ODER STILLSCHWEIGEND, EINSCHLIESSLICH, OHNE EINSCHRÄNKUNG, ALLE STILLSCHWEIGENDEN GARANTIEN BEZÜGLICH EIGENTUM, MARKTFÄHIGKEIT, NICHTVERLETZUNG UND EIGNUNG FÜR EINEN BESTIMMTEN ZWECK UND ALLE GARANTIEN IN BEZUG AUF SICHERHEIT, AKTUALITÄT, KORREKTHEIT, QUALITÄT, GENAUIGKEIT, VOLLSTÄNDIGKEIT, ZUVERLÄSSIGKEIT, LEISTUNG, PÜNKTLICHKEIT ODER KONTINUIERLICHE VERFÜGBARKEIT IN BEZUG AUF DIE WEBSITES UND DIENSTE. Wir lehnen ausdrücklich, im vollen gesetzlich zulässigen Umfang, jegliche Haftung für Ausfallzeiten, Verzögerungen oder Fehler bei der Übertragung oder Lieferung von Mitteilungen, Informationen, Materialien oder Diensten über die Websites oder Dienste ab. Soweit eine Rechtsordnung den Ausschluss bestimmter Garantien nicht zulässt, gelten einige der oben genannten Ausschlüsse möglicherweise nicht. Sie verstehen und stimmen zu, dass das Handeln oder Nicht-Handeln von Dritten außerhalb unserer Kontrolle liegt und dass wir nicht garantieren, dass Dritte bestimmte Handlungen vornehmen oder davon absehen werden.
  
  Wir garantieren, unterstützen, sichern oder übernehmen keine Verantwortung für Produkte oder Dienste Dritter, die empfohlen, beworben oder zum Verkauf über die Websites oder Dienste oder eine damit verlinkte Website angeboten werden.`,
  zh: `使用条款
  
  本使用条款适用于由S.A.S Ochy（法国公司）及其附属公司（统称为“我们”）拥有、运营和维护的所有网站，包括[ochy.io]以及链接至本使用条款的其他网站（“网站”），以及您对我们提供的服务、我们的移动应用程序以及您与我们之间的其他交易和互动，包括通过社交媒体的互动（统称为“服务”）。
  
  1. 一般规定
  
  本使用条款连同隐私政策，以及发布在网站和服务特定区域的任何附加条款、条件或规则（统称为“条款”），构成约束您使用本网站和服务的具有法律约束力的条款。
  
  通过进入网站或使用服务，您承认并同意本条款中规定的所有条款、条件和规则。如果您不同意受本条款的法律约束，您将不得使用网站或服务。请仔细阅读这些条款。网站和服务仅供法国境内年满13岁的法国公民和合法永久居民使用。如果您不属于目标受众，禁止您访问网站或使用服务。
  
  我们可自行决定不时修改本条款，并保留在任何时候对网站或服务进行更改的权利，恕不另行通知或承担任何义务。如果某项更改被我们视为重大更改，我们将在更改生效日期之前至少十四（14）天在网站上发布通知和/或向您发送电子邮件。您在更改生效日期前未终止与我们的关系，即表示您承认并同意受更新后的条款和/或网站或服务的约束。我们建议您定期访问本网站的此页面以查看这些条款。
  
  您声明并保证，您在与我们、网站和服务互动时，所表明的身份是真实的。您将按照所有适用的法律法规使用网站和服务，并且不会利用其进行欺诈、伤害或冒充他人。
  
  1.1 密码和账户安全
  
  您可以通过完成在线或应用内的注册流程来创建您的登录信息。在此过程中，您必须向我们提供准确完整的注册信息，并在信息发生更改时进行更新。您同意不会以他人名义注册，不会选择粗俗、亵渎或其他令人反感的用户名，不会选择模仿或暗示代表他人或实体的用户名，也不会选择包含引诱内容的用户名。
  
  如果您创建登录信息，我们将分配给您一个密码，或允许您自行选择密码。您必须对您的密码保密，并且不得允许任何其他人使用您的密码。您将对您的密码使用负责，包括未经授权的第三方使用。若您认为您的密码可能被未经授权的人员或实体使用，必须立即通知我们。为确保安全，我们建议您定期更改密码。在任何情况下，您都不应回应要求您提供密码的请求。我们的员工绝不会询问您的密码。如果您收到此类请求，请立即通知我们。如果我们认为您的密码在未经授权的情况下被使用，或以其他方式可能破坏网站或服务的方式被使用，我们保留暂停或终止您使用网站或服务的权利。
  
  1.2 设备要求
  
  要在您的智能手机或其他设备上使用我们的应用程序，您的设备必须符合Apple App Store或Google Play Store上应用程序描述中所列的特定系统要求。
  
  2. 用户指南
  
  您同意不使用网站或服务来传播诽谤、滥用、骚扰、跟踪、威胁或以其他方式侵犯他人权利的通讯内容。您还同意不使用网站或服务来传播包含非法、骚扰、诽谤、滥用、不雅、威胁、伤害、粗俗、淫秽、色情或歧视性材料的通讯内容。您不得使用网站或服务进行欺诈、参与犯罪活动或干扰他人对网站或服务的使用。您不得干涉、妨碍或以其他方式阻碍他人对网站或服务的使用。我们保留（但没有义务）审查您的通讯内容的权利，并在未通知您的情况下终止您对网站或服务的访问和使用，如果我们认为您违反了上述用户指南。此外，您不得复制、反编译、逆向工程或以任何方式进行旨在或实际重建或将网站或服务的全部或部分转移给自己或第三方的活动。
  
  3. 用户数据
  
  我们将保留您作为服务的一部分而传输的某些数据，以用于管理服务并向您提供服务。我们不保证会在任何时间段内或根本不会保留您的数据。您应保留您提供给我们的所有数据的副本和备份。您同意我们对您提供给我们的数据或与您使用服务相关的我们存储的数据的任何丢失或损坏不承担任何责任。
  
  4. 终止
  
  我们可随时因任何原因暂停或终止您对网站或服务的使用，且无需通知。在终止后，我们可以（但没有义务）删除我们存储的与您使用网站或服务相关的任何信息。如果我们由于您违反这些条款而终止您对网站或服务的使用，您将无权获得任何已向我们支付的费用的退款，具体由我们自行决定。
  
  4.1 免责声明
  
  网站和服务按“原样”和“可用”提供给您，并在适用法律允许的最大范围内，风险由您自行承担。我们在适用法律允许的最大范围内，不作任何明示或暗示的担保，包括但不限于所有权、适销性、非侵权和适用于特定目的的任何暗示担保，以及关于网站和服务的安全性、及时性、正确性、质量、准确性、完整性、可靠性、性能、及时性或持续可用性的任何担保。我们明确声明，在适用法律允许的最大范围内，对于通过网站或服务传输或交付的任何通讯、信息、材料或服务的任何停机、延迟或错误，不承担任何担保。在某些司法管辖区不允许排除某些担保的范围内，上述某些排除条款可能不适用。您理解并同意，第三方的行为或不行为不在我们的控制范围内，我们不保证任何第三方将采取或不采取任何行动。
  
  我们不对通过网站或服务或链接到网站或服务的任何网站推荐、宣传或提供出售的任何第三方产品或服务提供担保、支持、保证或承担责任。`,
  es: `Términos de Uso
  
  Estos Términos de Uso se aplican a todos los sitios web que son propiedad, operados y mantenidos por o para S.A.S Ochy, una empresa francesa, y sus afiliadas (colectivamente, “nosotros”, “nos” o “nuestro”), incluyendo [ochy.io], y otros sitios web en los que se enlazan estos Términos de Uso (“Sitios”), así como al uso de los servicios que ofrecemos, nuestra aplicación móvil y otras transacciones e interacciones que puedas tener con nosotros, incluyendo interacciones a través de redes sociales (colectivamente, “Servicios”).
  
  1. Disposiciones Generales
  
  ESTOS TÉRMINOS DE USO, JUNTO CON LA POLÍTICA DE PRIVACIDAD Y CUALQUIER TÉRMINO, CONDICIÓN O REGLA SUPLEMENTARIA PUBLICADA EN UNA ÁREA ESPECÍFICA DE LOS SITIOS Y SERVICIOS (COLECTIVAMENTE, “TÉRMINOS”), ESTABLECEN LOS TÉRMINOS LEGALMENTE VINCULANTES QUE RIGEN EL USO DEL SITIO Y LOS SERVICIOS.
  
  Al acceder a los Sitios o utilizar los Servicios, reconoces y aceptas todos los términos, condiciones y reglas establecidos en estos Términos. No está permitido que utilices los Sitios o los Servicios si no aceptas quedar legalmente vinculado por estos Términos. Por favor, lee estos Términos detenidamente. Los Sitios y Servicios están destinados exclusivamente a ciudadanos y residentes legales permanentes de Francia que residan en Francia y tengan al menos 13 años de edad. Si no formas parte del público al que están dirigidos, se te prohíbe acceder a los Sitios o utilizar los Servicios.
  
  Podemos, a nuestro exclusivo criterio, modificar los Términos de vez en cuando y nos reservamos el derecho de hacer cambios en cualquier momento, sin previo aviso ni obligación, a los Sitios o Servicios. Si un cambio es sustancial, según lo determinemos a nuestra entera discreción, proporcionaremos al menos catorce (14) días de aviso antes de la fecha efectiva del cambio, a través de una publicación en los Sitios y/o un correo electrónico dirigido a ti. Si no terminas tu relación con nosotros antes de la fecha efectiva del cambio, reconoces y aceptas que quedarás vinculado por los Términos actualizados y/o los Sitios o Servicios. Te sugerimos que visites periódicamente esta página de los Sitios para revisar estos Términos.
  
  Declaras y garantizas que eres la persona que afirmas ser en tus interacciones con nosotros, los Sitios y los Servicios. Utilizarás los Sitios y Servicios en cumplimiento de todas las leyes y regulaciones aplicables y no los utilizarás para defraudar, causar daño o suplantar a otra persona.
  
  1.1 Seguridad de la Contraseña y Cuenta
  
  Puedes crear tu propia información de acceso completando el proceso de registro en línea o en la aplicación. Al hacerlo, debes proporcionarnos información de registro precisa y completa y actualizarla si esta información cambia. Aceptas no registrarte con el nombre de otra persona, no elegir un nombre de usuario vulgar, profano o de otra manera ofensivo, no elegir un nombre de usuario que imite o sugiera la representación de otra persona o entidad, ni elegir un nombre de usuario que incluya una solicitud.
  
  Si creas un inicio de sesión, te asignaremos o permitiremos que selecciones una contraseña. Debes mantener tu contraseña confidencial y no puedes permitir que ninguna otra persona la utilice. Serás responsable de todo el uso de tu contraseña, incluyendo, sin limitación, cualquier uso por parte de terceros autorizados o no autorizados. Debes notificarnos de inmediato si crees que tu contraseña puede ser utilizada por alguna persona o entidad no autorizada. Por motivos de seguridad, te recomendamos que cambies tu contraseña con frecuencia. Bajo ninguna circunstancia deberías responder a una solicitud de tu contraseña. Nuestros empleados nunca te pedirán tu contraseña. Debes notificarnos de inmediato si recibes tal solicitud. Nos reservamos el derecho de suspender o terminar tu uso de los Sitios o Servicios si creemos que tu contraseña está siendo utilizada sin permiso o de alguna otra manera que pueda interrumpir los Sitios o Servicios.
  
  1.2 Requisitos del Dispositivo
  
  Para utilizar nuestra aplicación en tu smartphone u otro dispositivo, este debe cumplir con los requisitos de sistema específicos detallados en las descripciones de la aplicación en la Apple App Store o Google Play Store.
  
  2. Directrices para el Usuario
  
  Aceptas no usar los Sitios o Servicios para transmitir comunicaciones que difamen, abusen, acosen, persigan, amenacen o violen de alguna manera los derechos de cualquier otra persona. También aceptas no usar los Sitios o Servicios para transmitir comunicaciones que contengan material ilegal, ofensivo, difamatorio, abusivo, indecente, amenazante, dañino, vulgar, obsceno, pornográfico o discriminatorio. No utilizarás los Sitios o Servicios para cometer fraude, participar en actividades criminales o interferir con el uso de los Sitios o Servicios por parte de otros. No interferirás, manipularás o perjudicarás de alguna manera el uso de los Sitios o Servicios por parte de cualquier persona. Nos reservamos el derecho, pero no estamos obligados, a revisar tus comunicaciones y, sin previo aviso, cancelar tu acceso y uso de los Sitios o Servicios si determinamos, a nuestra entera discreción, que violaste las directrices mencionadas para el usuario. Además, no reproducirás, descompilarás, realizarás ingeniería inversa ni participarás en ninguna actividad con la intención o el efecto de recrear o transferir a ti mismo o a cualquier tercero una parte o la totalidad de los Sitios o Servicios.
  
  3. Datos del Usuario
  
  Mantendremos ciertos datos que transmites como parte de los Servicios con el propósito de gestionar los Servicios y proporcionártelos. No garantizamos que retendremos tus datos durante ningún periodo de tiempo o en absoluto. Debes mantener copias y respaldos de todos los datos que nos proporciones. Aceptas que no tendremos responsabilidad alguna por cualquier pérdida o corrupción de los datos que nos proporciones o que almacenemos en relación con tu uso de los Servicios.
  
  4. Terminación
  
  Podemos suspender o terminar tu uso de los Sitios o Servicios en cualquier momento por cualquier motivo y sin previo aviso. Al terminar, podemos, pero no estamos obligados, eliminar toda la información almacenada por nosotros relacionada con tu uso de los Sitios o Servicios. Si terminamos tu uso de los Sitios o Servicios debido a una violación de estos Términos por tu parte, según lo determinemos a nuestra entera discreción, no tendrás derecho a un reembolso de ninguna tarifa pagada por ti a nosotros.
  
  4.1 Renuncia de Garantías
  
  Los Sitios y Servicios se te proporcionan "TAL COMO SON" y "SEGÚN DISPONIBILIDAD", Y A TU PROPIO RIESGO, EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY APLICABLE. RENUNCIAMOS, EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY APLICABLE, A TODAS LAS GARANTÍAS, YA SEAN EXPRESAS O IMPLÍCITAS, INCLUYENDO, SIN LIMITACIÓN, CUALQUIER GARANTÍA IMPLÍCITA DE TÍTULO, COMERCIABILIDAD, NO INFRACCIÓN Y ADECUACIÓN A UN PROPÓSITO PARTICULAR Y TODAS LAS GARANTÍAS RELATIVAS A LA SEGURIDAD, ACTUALIDAD, EXACTITUD, CALIDAD, PRECISIÓN, INTEGRIDAD, FIABILIDAD, RENDIMIENTO, PUNTUALIDAD O DISPONIBILIDAD CONTINUADA CON RESPECTO A LOS SITIOS Y SERVICIOS. Renunciamos expresamente, en la máxima medida permitida por la ley aplicable, a cualquier garantía con respecto a cualquier tiempo de inactividad, demoras o errores en la transmisión o entrega de cualquier comunicación, información, material o servicio a través de los Sitios o Servicios. En la medida en que una jurisdicción no permita la exclusión de ciertas garantías, algunas de las exclusiones anteriores pueden no aplicarse. Entiendes y aceptas que la acción o inacción de terceros está fuera de nuestro control y que no garantizamos que ningún tercero tomará alguna acción o se abstendrá de tomar alguna acción.
  
  No garantizamos, respaldamos, aseguramos ni asumimos responsabilidad por ningún producto o servicio de terceros recomendado, anunciado o ofrecido para la venta a través de los Sitios o Servicios o de cualquier sitio web vinculado a los Sitios o Servicios.`,
  it: `Termini di Utilizzo
  
  Questi Termini di Utilizzo si applicano a tutti i siti web che sono di proprietà, gestiti e mantenuti da o per S.A.S Ochy, una società francese, e le sue affiliate (collettivamente, “noi”, “ci” o “nostro”), compreso [ochy.io], e altri siti web ai quali sono collegati questi Termini di Utilizzo (“Siti”) così come il tuo utilizzo dei servizi che forniamo, la nostra applicazione mobile e altre transazioni e interazioni che potresti avere con noi, inclusi i contatti tramite social media (collettivamente, “Servizi”).
  
  1. Disposizioni Generali
  
  QUESTI TERMINI DI UTILIZZO INSIEME ALLA POLITICA SULLA PRIVACY E QUALSIASI TERMINE, CONDIZIONE O REGOLA SUPPLEMENTARE PUBBLICATO IN UNA SPECIFICA AREA DEI SITI E DEI SERVIZI (COLLETTIVAMENTE, “TERMINI”) DEFINISCONO I TERMINI LEGALMENTE VINCOLANTI CHE DISCIPLINANO L’UTILIZZO DEL SITO E DEI SERVIZI.
  
  Accedendo ai Siti o utilizzando i Servizi, riconosci e accetti tutti i termini, le condizioni e le regole stabilite in questi Termini. Non ti è permesso utilizzare i Siti o i Servizi se non accetti di essere legalmente vincolato da questi Termini. Leggi attentamente questi Termini. I Siti e i Servizi sono destinati all'uso esclusivo di cittadini e residenti legali permanenti della Francia di età pari o superiore a 13 anni. Se non fai parte del pubblico previsto, ti è vietato accedere ai Siti o utilizzare i Servizi.
  
  Possiamo, a nostra esclusiva discrezione, modificare i Termini di volta in volta e ci riserviamo il diritto di apportare modifiche in qualsiasi momento, senza preavviso o obbligo, ai Siti o ai Servizi. Se una modifica è significativa, come determinato da noi a nostra esclusiva discrezione, forniremo un preavviso di almeno quattordici (14) giorni prima della data effettiva della modifica tramite un post sui Siti e/o un'e-mail a te. Non interrompendo il tuo rapporto con noi prima della data effettiva della modifica, riconosci e accetti che sarai vincolato dai Termini aggiornati e/o dai Siti o Servizi. Ti suggeriamo di visitare periodicamente questa pagina dei Siti per rivedere questi Termini.
  
  Dichiari e garantisci di essere la persona che affermi di essere nelle tue interazioni con noi, i Siti e i Servizi. Utilizzerai i Siti e i Servizi in conformità con tutte le leggi e i regolamenti applicabili e non li utilizzerai per frodare, danneggiare o impersonare un'altra persona.
  
  1.1 Sicurezza della Password e dell’Account
  
  Puoi creare le tue informazioni di accesso completando il processo di registrazione online o in-app. In tal modo, devi fornirci informazioni di registrazione accurate e complete e aggiornarle se queste informazioni cambiano. Accetti di non registrarti con il nome di un'altra persona, scegliere un nome utente volgare, profano o altrimenti offensivo, scegliere un nome utente che impersoni o suggerisca la rappresentanza di un'altra persona o entità, o scegliere un nome utente che includa una richiesta.
  
  Se crei un login, ti assegneremo o ti consentiremo di selezionare una password. Devi mantenere la tua password riservata e non puoi consentire a nessun'altra persona di utilizzare la tua password. Sarai responsabile di tutto l'uso della tua password, incluso, senza limitazione, qualsiasi uso da parte di terzi autorizzati o non autorizzati. Devi notificarci immediatamente se ritieni che la tua password possa essere utilizzata da una persona o entità non autorizzata. Per motivi di sicurezza, ti consigliamo di cambiare la tua password spesso. In nessun caso dovresti rispondere a una richiesta per la tua password. I nostri dipendenti non chiederanno mai la tua password. Devi notificarci immediatamente se ricevi una richiesta simile. Ci riserviamo il diritto di sospendere o interrompere il tuo uso dei Siti o dei Servizi se riteniamo che la tua password venga utilizzata senza autorizzazione o comunque in modo tale da interrompere i Siti o i Servizi.
  
  1.2 Requisiti del Dispositivo
  
  Per utilizzare la nostra app sul tuo smartphone o altro dispositivo, il dispositivo deve soddisfare i requisiti di sistema specifici indicati nella descrizione dell'app nell'Apple App Store o nel Google Play Store.
  
  2. Linee Guida per l’Utente
  
  Accetti di non utilizzare i Siti o i Servizi per trasmettere comunicazioni che diffamino, abusino, molestino, perseguitino, minaccino o violino in altro modo i diritti di qualsiasi altra persona. Accetti inoltre di non utilizzare i Siti o i Servizi per trasmettere comunicazioni contenenti materiale illecito, molesto, diffamatorio, offensivo, indecente, minaccioso, dannoso, volgare, osceno, pornografico o discriminatorio. Non utilizzerai i Siti o i Servizi per commettere frodi, impegnarti in attività criminali o interferire con l'utilizzo dei Siti o dei Servizi da parte di altri. Non interferirai, ostacolerai o intralcerai in altro modo l'utilizzo dei Siti o dei Servizi da parte di chiunque. Ci riserviamo il diritto, ma non abbiamo alcun obbligo, di esaminare le tue comunicazioni e, senza preavviso, di terminare il tuo accesso e utilizzo dei Siti o dei Servizi se determiniamo, a nostra esclusiva discrezione, che hai violato le linee guida dell'utente sopra menzionate. Inoltre, non riprodurrai, decompilerai, ingegnerai al contrario o ti impegnerai in qualsiasi attività con l'intento o l'effetto di ricreare o trasferire a te stesso o a terzi una parte o l'intero dei Siti o dei Servizi.
  
  3. Dati dell’Utente
  
  Manterremo alcuni dati che trasmetti come parte dei Servizi per scopi di gestione dei Servizi e di fornitura dei Servizi stessi. Non garantiamo che conserveremo i tuoi dati per qualsiasi periodo di tempo o affatto. Dovresti conservare copie e backup di tutti i dati che ci fornisci. Accetti che non avremo alcuna responsabilità per qualsiasi perdita o corruzione dei dati che ci fornisci o che memorizziamo in relazione al tuo utilizzo dei Servizi.
  
  4. Risoluzione
  
  Possiamo sospendere o interrompere il tuo utilizzo dei Siti o dei Servizi in qualsiasi momento per qualsiasi motivo e senza preavviso. Al momento della risoluzione, possiamo, ma non siamo obbligati, eliminare tutte le informazioni da noi archiviate relative al tuo utilizzo dei Siti o dei Servizi. Se interrompiamo il tuo utilizzo dei Siti o dei Servizi a causa di una violazione di questi Termini da parte tua, come determinato da noi a nostra esclusiva discrezione, non avrai diritto a un rimborso di eventuali commissioni da te pagate a noi.
  
  4.1 Esclusione di Garanzie
  
  I Siti e i Servizi sono forniti "COSÌ COME SONO" e "COME DISPONIBILI", E A TUO RISCHIO NELLA MISURA MASSIMA CONSENTITA DALLA LEGGE APPLICABILE. DECLINIAMO, NELLA MISURA MASSIMA CONSENTITA DALLA LEGGE APPLICABILE, TUTTE LE GARANZIE, SIA ESPRESSE CHE IMPLICITE, INCLUSE, SENZA LIMITAZIONE, EVENTUALI GARANZIE IMPLICITE DI TITOLO, COMMERCIABILITÀ, NON VIOLAZIONE E IDONEITÀ A UNO SCOPO PARTICOLARE E TUTTE LE GARANZIE RELATIVE A SICUREZZA, VALUTA, CORRETTEZZA, QUALITÀ, PRECISIONE, COMPLETEZZA, AFFIDABILITÀ, PRESTAZIONI, TEMPESTIVITÀ O DISPONIBILITÀ CONTINUATA RISPETTO AL SITO E AI SERVIZI. Decliniamo espressamente, nella misura massima consentita dalla legge applicabile, qualsiasi garanzia in relazione a qualsiasi interruzione, ritardo o errore nella trasmissione o consegna di qualsiasi comunicazione, informazione, materiale o servizio tramite i Siti o i Servizi. Nella misura in cui una giurisdizione non consenta l'esclusione di determinate garanzie, alcune delle esclusioni sopra potrebbero non applicarsi. Comprendi e accetti che le azioni o l'inazione di terzi sono fuori dal nostro controllo e che non garantiamo che terzi prenderanno o si asterranno dal prendere alcuna azione.
  
  Non garantiamo, avalliamo, garantiamo né ci assumiamo alcuna responsabilità per qualsiasi prodotto o servizio di terzi consigliato,`,
  pt: `Termos de Uso
  
  Estes Termos de Uso se aplicam a todos os sites de propriedade, operados e mantidos por ou para a S.A.S Ochy, uma empresa francesa, e suas afiliadas (coletivamente, “nós”, “nos” ou “nosso”), incluindo [ochy.io], e outros sites nos quais estes Termos de Uso estão vinculados (“Sites”), bem como ao uso dos serviços que fornecemos, nosso aplicativo móvel e outras transações e interações que você possa ter conosco, incluindo interações via mídias sociais (coletivamente, “Serviços”).
  
  1. Disposições Gerais
  
  ESTES TERMOS DE USO, JUNTAMENTE COM A POLÍTICA DE PRIVACIDADE, E QUAISQUER TERMOS, CONDIÇÕES OU REGRAS SUPLEMENTARES PUBLICADOS EM UMA ÁREA ESPECÍFICA DOS SITES E SERVIÇOS (COLETIVAMENTE, “TERMOS”) ESTABELECEM OS TERMOS LEGALMENTE VINCULANTES QUE REGEM O SEU USO DO SITE E DOS SERVIÇOS.
  
  Ao acessar os Sites ou usar os Serviços, você reconhece e concorda com todos os termos, condições e regras estabelecidos nestes Termos. Você não está autorizado a usar os Sites ou os Serviços caso não concorde em se vincular legalmente a estes Termos. Leia estes Termos com atenção. Os Sites e Serviços são destinados ao uso apenas por cidadãos e residentes legais permanentes da França, com residência no país e com 13 anos de idade ou mais. Se você não fizer parte do público-alvo pretendido, fica proibido de acessar os Sites ou usar os Serviços.
  
  Nós podemos, a nosso exclusivo critério, modificar os Termos de tempos em tempos e nos reservamos o direito de fazer alterações a qualquer momento, sem aviso prévio ou obrigação, nos Sites ou Serviços. Se uma alteração for significativa, conforme determinado por nós a nosso exclusivo critério, forneceremos pelo menos quatorze (14) dias de aviso prévio antes da data efetiva da alteração, por meio de um aviso nos Sites e/ou por e-mail para você. Ao não encerrar seu relacionamento conosco antes da data efetiva da alteração, você reconhece e concorda que estará vinculado aos Termos atualizados e/ou aos Sites ou Serviços. Sugerimos visitar periodicamente esta página dos Sites para revisar estes Termos.
  
  Você declara e garante que é a pessoa que afirma ser em suas interações conosco, nos Sites e nos Serviços. Você usará os Sites e Serviços em conformidade com todas as leis e regulamentos aplicáveis e não os usará para fraudar, prejudicar ou se passar por outra pessoa.
  
  1.1 Senha e Segurança da Conta
  
  Você pode criar suas próprias informações de login completando o processo de registro online ou no aplicativo. Ao fazer isso, você deve nos fornecer informações de registro precisas e completas e atualizá-las caso essas informações mudem. Você concorda em não se registrar com o nome de outra pessoa, escolher um nome de usuário vulgar, profano ou ofensivo, escolher um nome de usuário que imite ou sugira representação de outra pessoa ou entidade, ou escolher um nome de usuário que inclua uma solicitação.
  
  Se você criar um login, nós lhe atribuíremos, ou permitiremos que selecione, uma senha. Você deve manter sua senha confidencial e não deve permitir que outra pessoa a utilize. Você será responsável por todo o uso de sua senha, incluindo, sem limitação, qualquer uso por terceiros autorizados ou não autorizados. Você deve nos notificar imediatamente se acreditar que sua senha possa estar sendo utilizada por uma pessoa ou entidade não autorizada. Por motivos de segurança, recomendamos que você altere sua senha com frequência. Em nenhuma circunstância você deve responder a uma solicitação de sua senha. Nossos funcionários nunca solicitarão sua senha. Você deve nos notificar imediatamente caso receba tal solicitação. Nos reservamos o direito de suspender ou encerrar o seu uso dos Sites ou dos Serviços caso acreditarmos que sua senha esteja sendo usada sem permissão ou de outra forma que possa prejudicar os Sites ou Serviços.
  
  1.2 Requisitos do Dispositivo
  
  Para utilizar nosso aplicativo em seu smartphone ou outro dispositivo, seu dispositivo deve atender aos requisitos de sistema específicos descritos nas lojas Apple App Store ou Google Play Store.
  
  2. Diretrizes do Usuário
  
  Você concorda em não usar os Sites ou Serviços para transmitir comunicações que difamem, abusem, assediem, persigam, ameacem ou violem de qualquer outra forma os direitos de qualquer outra pessoa. Você também concorda em não usar os Sites ou Serviços para transmitir comunicações contendo material ilegal, ofensivo, difamatório, abusivo, indecente, ameaçador, prejudicial, vulgar, obsceno, pornográfico ou discriminatório. Você não usará os Sites ou Serviços para cometer fraude, participar de atividades criminosas ou interferir no uso dos Sites ou Serviços por outras pessoas. Você não alterará, interferirá ou prejudicará de qualquer outra forma o uso dos Sites ou Serviços por qualquer pessoa. Nos reservamos o direito, mas não temos obrigação, de revisar suas comunicações e, sem aviso prévio, encerrar seu acesso e uso dos Sites ou Serviços se determinarmos, a nosso exclusivo critério, que você violou as diretrizes de usuário acima mencionadas. Além disso, você não deve reproduzir, decompilar, fazer engenharia reversa ou participar de qualquer atividade com a intenção ou efeito de recriar ou transferir para si mesmo ou para qualquer terceiro uma parte ou a totalidade dos Sites ou Serviços.
  
  3. Dados do Usuário
  
  Manteremos certos dados transmitidos por você como parte dos Serviços para fins de gerenciamento e fornecimento dos mesmos. Não garantimos que manteremos seus dados por qualquer período de tempo ou de forma permanente. Você deve manter cópias e backups de todos os dados que nos fornecer. Você concorda que não teremos nenhuma responsabilidade por qualquer perda ou corrupção dos dados que nos fornecer ou que armazenarmos associados ao seu uso dos Serviços.
  
  4. Rescisão
  
  Podemos suspender ou encerrar seu uso dos Sites ou Serviços a qualquer momento, por qualquer motivo e sem aviso prévio. Após a rescisão, podemos, mas não somos obrigados, excluir todas as informações armazenadas por nós relacionadas ao seu uso dos Sites ou Serviços. Se encerrarmos seu uso dos Sites ou Serviços devido a uma violação destes Termos por você, conforme determinado por nós a nosso exclusivo critério, você não terá direito a reembolso de quaisquer taxas pagas por você a nós.
  
  4.1 Isenção de Garantias
  
  Os Sites e Serviços são fornecidos a você "NO ESTADO EM QUE SE ENCONTRAM" e "CONFORME DISPONÍVEL", E A SEU PRÓPRIO RISCO, NA MEDIDA MÁXIMA PERMITIDA PELA LEGISLAÇÃO APLICÁVEL. RENUNCIAMOS, NA MEDIDA MÁXIMA PERMITIDA PELA LEGISLAÇÃO APLICÁVEL, TODAS AS GARANTIAS, SEJAM EXPRESSAS OU IMPLÍCITAS, INCLUINDO, SEM LIMITAÇÃO, QUAISQUER GARANTIAS IMPLÍCITAS DE TÍTULO, COMERCIABILIDADE, NÃO VIOLAÇÃO E ADEQUAÇÃO A UMA FINALIDADE ESPECÍFICA, E TODAS AS GARANTIAS RELACIONADAS À SEGURANÇA, ATUALIDADE, CORREÇÃO, QUALIDADE, PRECISÃO, COMPLETUDE, CONFIABILIDADE, DESEMPENHO, PONTUALIDADE OU DISPONIBILIDADE CONTINUADA RELACIONADAS AO SITE E AOS SERVIÇOS. Renunciamos expressamente, na medida máxima permitida pela legislação aplicável, quaisquer garantias relacionadas a qualquer tempo de inatividade, atrasos ou erros na transmissão ou entrega de qualquer comunicação, informação, material ou serviço por meio dos Sites ou Serviços. Na medida em que uma jurisdição não permita a exclusão de determinadas garantias, algumas das exclusões acima podem não se aplicar. Você entende e concorda que ações ou omissões de terceiros estão fora do nosso controle e que não garantimos que terceiros tomarão qualquer ação ou deixarão de tomar qualquer ação.
  
  Não garantimos, endossamos, asseguramos nem assumimos qualquer responsabilidade por qualquer produto ou serviço de terceiros recomendado.`,

  ja: `利用規約

これらの利用規約は、S.A.S Ochy（フランス法人）およびその関連会社（総称して「当社」、「私たち」または「私たちの」）が所有し、運営し、管理するウェブサイト（[ochy.io]を含む）およびこれらの利用規約がリンクされている他のウェブサイト（以下、「サイト」）および当社が提供するサービス、モバイルアプリケーション、ソーシャルメディアを通じたその他の取引や交流（総称して「サービス」）に適用されます。

一般規定
これらの利用規約は、プライバシーポリシーおよび特定のサイトおよびサービスのエリアに掲示された追加の規約、条件、またはルール（総称して「規約」）と共に、サイトおよびサービスの利用に関する法的拘束力のある規定を定めます。
サイトにアクセスすることまたはサービスを利用することにより、あなたはこれらの規約に記載されたすべての条件に同意したものとみなされます。これらの規約に法的に拘束されることに同意しない場合、サイトやサービスを利用することはできません。これらの規約を慎重にお読みください。サイトおよびサービスは、フランスに居住する13歳以上のフランスの市民および合法的な永住者のみを対象にしています。もしあなたが対象となるオーディエンスに該当しない場合、サイトやサービスにアクセスしたり、利用したりすることは禁じられています。

当社は、独自の判断でこれらの規約を随時変更することができ、サイトやサービスに変更があった場合、通知なしで変更を加える権利を留保します。変更が重要であると当社が判断した場合、変更の実施日から少なくとも14日間前にサイトに通知を掲示するか、またはあなたに電子メールで通知します。変更の実施日までに関係を終了しないことにより、あなたは更新された規約およびサイトやサービスに拘束されることに同意するものとみなされます。定期的にこのページにアクセスして、規約を確認することをお勧めします。

あなたは、当社とのやり取りやサイトおよびサービスにおいて主張した人物があなた自身であることを表明し、保証します。サイトおよびサービスを利用する際には、すべての適用法令を遵守し、他の人物を詐欺や損害を与えることなく、または他の人物になりすますことなく利用することに同意します。

1.1 パスワードおよびアカウントのセキュリティ
オンラインまたはアプリ内の登録手続きを完了することにより、ログイン情報を作成することができます。この際、正確かつ完全な登録情報を提供し、情報が変更された場合にはそれを更新する必要があります。他の人物の名前で登録したり、不快、冒涜的、または攻撃的なユーザー名を選んだり、他の人物や団体を装うユーザー名を選んだり、勧誘を含むユーザー名を選んだりしないことに同意します。

ログイン情報を作成した場合、当社はあなたにパスワードを付与するか、またはあなたが選択できるようにします。パスワードは秘密にしておき、他の人物にパスワードを使用させてはいけません。パスワードの使用については、許可された人物および許可されていない人物を問わず、あなたが責任を負うものとします。もし不正使用の疑いがある場合は、すぐに当社に通知する必要があります。セキュリティ上の理由から、頻繁にパスワードを変更することをお勧めします。パスワードの要求には応じないでください。当社の社員はパスワードを要求することはありません。そのような要求を受けた場合は、すぐに当社に通知してください。当社は、パスワードが無断で使用されている場合や、サイトまたはサービスを妨害する方法で使用されていると判断した場合、あなたのサイトまたはサービスの利用を停止または終了する権利を留保します。

1.2 デバイス要件
スマートフォンやその他のデバイスでアプリを利用するためには、Apple App StoreまたはGoogle Play Storeのアプリ説明に記載されている特定のシステム要件を満たしている必要があります。

ユーザーガイドライン
サイトやサービスを利用して、他人を中傷、虐待、嫌がらせ、ストーキング、脅迫するような通信を送信しないことに同意します。また、違法、有害、侮辱的、下品、卑猥、脅迫的、性的、差別的な内容を含む通信を送信しないことに同意します。詐欺行為を行ったり、犯罪活動に関与したり、サイトやサービスの利用を妨害したりしないことに同意します。サイトやサービスの使用を妨害、干渉、または妨げる行為をしないことに同意します。違反行為をしたと当社が独自の判断で判断した場合、通知なしにサイトやサービスの利用を終了することがあります。

ユーデータ
サービスを提供するために、当社はあなたが送信するデータを一定期間保存することがありますが、その保存期間については保証しません。データのバックアップを保存することをお勧めします。データの紛失や破損に関して、当社は責任を負わないことに同意します。

終了
当社は、理由の如何を問わず、また通知なしで、あなたのサイトやサービスの利用を一時的または永久に停止または終了する権利を留保します。終了後、当社はあなたに関連する情報を削除することがあります。規約違反により利用が終了された場合、料金の払い戻しは行いません。

4.1 保証の免責
サイトおよびサービスは「現状のまま」および「利用可能な状態で」提供され、リスクは完全にあなたの責任です。法律で許可される最大限の範囲で、当社は明示的または暗示的なすべての保証を否認します。

4.2 知的財産
当社は、サイトおよびサービス上のすべての著作権、商標、その他の知的財産を所有、管理、またはライセンスしています。あなたに提供されるのは、サイトやサービスの利用に関する制限付きのライセンスのみであり、知的財産を商業的に利用することはできません。

4.3 不可抗力
当社は、天候、自然災害、戦争、テロ攻撃、疾病、パンデミックなどの不可抗力の事象により、サイトやサービスの提供が遅延または不履行になる場合、責任を負いません。

4.4 フィードバック
フィードバックや提案は常に歓迎します。提供されたフィードバックは、商業的またはその他の目的で当社が使用できることに同意します。

4.5 その他
これらの規約は、あなたと当社との間の完全な契約を構成します。`,
  ko: `
이용 약관

이 약관은 S.A.S Ochy(프랑스 법인) 및 그 자회사가 소유하고 운영하며 관리하는 웹사이트([ochy.io] 포함)와 이 약관이 링크된 다른 웹사이트(이하 "사이트") 및 당사가 제공하는 서비스, 모바일 애플리케이션, 소셜 미디어를 통한 다른 거래 및 상호작용(총칭하여 "서비스")에 적용됩니다.

일반 규정
이 약관은 개인정보 처리방침 및 특정 사이트와 서비스의 영역에 게시된 추가 규정, 조건 또는 규칙(총칭하여 "규정")과 함께 사이트 및 서비스의 이용에 관한 법적 구속력이 있는 규정을 정합니다. 사이트에 접근하거나 서비스를 이용함으로써 귀하는 이 약관에 명시된 모든 조건에 동의한 것으로 간주됩니다. 이 약관에 법적으로 구속되는 것에 동의하지 않으면 사이트나 서비스를 이용할 수 없습니다. 이 약관을 신중히 읽어 주십시오. 사이트 및 서비스는 프랑스에 거주하는 13세 이상의 프랑스 시민 및 합법적인 영주권자만을 대상으로 합니다. 귀하가 대상이 아닌 경우 사이트나 서비스에 접근하거나 이용하는 것은 금지됩니다.

당사는 독자적인 판단에 따라 이 약관을 언제든지 변경할 수 있으며, 사이트나 서비스에 변경이 있을 경우 통지 없이 변경을 가할 권리를 보유합니다. 중요한 변경이 있을 경우, 변경 시행일로부터 최소 14일 전에 사이트에 통지하거나 귀하에게 이메일로 통지할 것입니다. 변경 시행일까지 관계를 종료하지 않으면 귀하는 업데이트된 약관 및 사이트와 서비스에 구속되도록 동의한 것으로 간주됩니다. 이 페이지를 정기적으로 확인하여 약관을 확인하시기 바랍니다.

귀하는 당사와의 상호작용이나 사이트 및 서비스에서 주장된 인물이 귀하 자신임을 진술하고 보증합니다. 사이트 및 서비스를 이용할 때는 모든 관련 법률을 준수하고, 다른 사람을 사기나 피해를 주지 않으며, 다른 사람을 가장하지 않도록 동의합니다.

1.1 비밀번호 및 계정 보안
온라인 또는 앱 내 등록 절차를 완료함으로써 로그인 정보를 생성할 수 있습니다. 이때 정확하고 완전한 등록 정보를 제공하고, 정보가 변경되면 이를 업데이트해야 합니다. 다른 사람의 이름으로 등록하거나 불쾌하거나 욕설적인 사용자 이름을 선택하거나, 다른 사람이나 단체를 가장하는 사용자 이름을 선택하거나, 홍보를 포함한 사용자 이름을 선택하지 않기로 동의합니다.

로그인 정보를 생성하면 당사는 귀하에게 비밀번호를 부여하거나 귀하가 선택할 수 있도록 할 수 있습니다. 비밀번호는 비밀로 유지하고, 다른 사람에게 비밀번호를 사용하게 해서는 안 됩니다. 비밀번호 사용에 대해서는 허가된 사람과 허가되지 않은 사람을 불문하고 귀하가 책임을 집니다. 비밀번호의 부정 사용이 의심될 경우 즉시 당사에 통지해야 합니다. 보안을 위해 비밀번호를 자주 변경하는 것이 좋습니다. 당사는 비밀번호를 요구하지 않습니다. 만약 그런 요청을 받으면 즉시 당사에 통지하십시오. 당사는 비밀번호가 무단으로 사용되었거나, 사이트나 서비스의 운영을 방해하는 방식으로 사용된다고 판단할 경우 귀하의 사이트 또는 서비스 이용을 중단하거나 종료할 권리를 보유합니다.

1.2 기기 요구 사항
스마트폰이나 기타 기기에서 앱을 이용하려면 Apple App Store 또는 Google Play Store에 명시된 특정 시스템 요구 사항을 충족해야 합니다.

사용자 가이드라인
사이트나 서비스를 이용하여 다른 사람을 비방, 학대, 괴롭힘, 스토킹, 협박하는 커뮤니케이션을 보내지 않기로 동의합니다. 또한 불법적이거나 유해하거나 모욕적, 음란하거나 협박적, 성적이거나 차별적인 내용이 포함된 커뮤니케이션을 보내지 않기로 동의합니다. 사기 행위를 하거나 범죄 활동에 연루되거나 사이트나 서비스의 사용을 방해하지 않기로 동의합니다. 사이트나 서비스 사용을 방해하거나 간섭하거나 방해하는 행위를 하지 않기로 동의합니다. 당사가 독자적으로 판단하여 위반 행위가 있다고 판단되면, 통지 없이 사이트나 서비스의 사용을 종료할 수 있습니다.

유저 데이터
서비스를 제공하기 위해 당사는 귀하가 전송한 데이터를 일정 기간 동안 저장할 수 있습니다. 그러나 저장 기간에 대해서는 보장하지 않습니다. 데이터 백업을 저장하는 것이 좋습니다. 데이터의 분실이나 손상에 대해 당사는 책임지지 않는 것에 동의합니다.

종료
당사는 이유 불문하고, 또한 사전 통지 없이 귀하의 사이트나 서비스 이용을 일시적 또는 영구적으로 중단하거나 종료할 권리를 보유합니다. 종료 후 당사는 귀하와 관련된 정보를 삭제할 수 있습니다. 약관 위반으로 인한 이용 종료 시, 환불은 제공되지 않습니다.

4.1 보증의 면책
사이트 및 서비스는 "있는 그대로" 및 "이용 가능한 상태"로 제공되며, 리스크는 전적으로 귀하의 책임입니다. 법률이 허용하는 최대 범위 내에서 당사는 명시적 또는 암시적인 모든 보증을 부인합니다.

4.2 지적 재산권
당사는 사이트 및 서비스의 모든 저작권, 상표 및 기타 지적 재산을 소유, 관리 또는 라이선스를 보유합니다. 귀하에게 제공되는 것은 사이트나 서비스 이용에 관한 제한된 라이센스일 뿐이며, 지적 재산을 상업적으로 이용하는 것은 허용되지 않습니다.

4.3 불가항력
당사는 기상, 자연재해, 전쟁, 테러 공격, 질병, 팬데믹 등 불가항력적인 사건으로 인해 사이트나 서비스 제공이 지연되거나 불이행되는 경우에 대해 책임을 지지 않습니다.

4.4 피드백
피드백이나 제안은 항상 환영합니다. 제공된 피드백은 상업적 또는 기타 목적을 위해 당사가 사용할 수 있다는 데 동의합니다.

4.5 기타
이 약관은 귀하와 당사 간의 완전한 계약을 구성합니다.`,
};
