import {collection, deleteDoc, doc, getDoc, setDoc} from 'firebase/firestore';

import {auth, firestore} from 'utils/firebaseInit';
import {USER_LOCALE} from 'services/remoteConfig';
import {getDownloadURL, getStorage, ref} from 'firebase/storage';
import {ANALYSISTYPE_RUNNING_TREADMILL} from 'constant/constants';
import {getAuth} from 'firebase/auth';

const ANALYSIS_COLLECTION = 'analysis';
const PROJECT_COLLECTION = 'projects';
const PROJECT_SUB_COLLECTION = 'analysisDetails';
const API_URL = process.env.REACT_APP_API_KEY;

export async function startVideoAnalysis(id, data) {
  const analysisType =
    data?.analysis_type === ANALYSISTYPE_RUNNING_TREADMILL
      ? 'running'
      : data?.analysis_type;

  let body = {
    id: id,
    height: data?.height ?? -1,
    weight: data?.weight ?? -1,
    language: USER_LOCALE,
    source: 'webApp',
    analysis_type: analysisType ?? 'running',
    treadmill:
      data?.analysis_type === ANALYSISTYPE_RUNNING_TREADMILL ? true : false,
    pace: data?.pace ?? '',
  };

  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('User is not authenticated');
    }
    const idToken = await currentUser.getIdToken();

    const response = await fetch(API_URL + '/analysis/start', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        token: `${idToken}`,
      },
      body: JSON.stringify(body),
    });

    const responseText = await response.text();
    return responseText;
  } catch (error) {
    console.error('Error in Start Analysis : ', error);
    return null;
  }
}

export async function getVideoAnalysisStatus(id) {
  // const body = `status=${id}`;
  // try {
  //   const response = await fetch(API_URL, {
  //     method: "post",
  //     body: body,
  //     referrerPolicy: "unsafe-url",
  //   });
  //   const responseJson = await response?.json();
  //   return responseJson;
  // } catch (error) {
  //   console.error(error.message);
  //   return null;
  // }
}

export async function updateAnalysis(data, analysisId, merge = true) {
  if (!analysisId) return;
  if (auth) {
    try {
      const collectionRef = collection(firestore, ANALYSIS_COLLECTION);
      const ref = doc(collectionRef, analysisId);
      setDoc(ref, data, {merge: merge});
      return ref;
    } catch (e) {
      console.log(e);
    }
  }
}

export async function getDowloadUrlFromGsUrl(gsUrl) {
  if (!gsUrl) return;
  const storage = getStorage();
  const url = await getDownloadURL(ref(storage, gsUrl)).catch(error => {
    console.log('error', error);
  });
  if (url) return url;
}

export async function getSharedAnalysis(analysisId) {
  const collectionRef = collection(firestore, ANALYSIS_COLLECTION);
  const ref = doc(collectionRef, analysisId);
  const docSnap = await getDoc(ref);

  if (docSnap.exists()) {
    const analysisData = docSnap.data();

    if (analysisData?.analysisReference) {
      const docRef = analysisData.analysisReference;
      const projectDocSnap = await getDoc(docRef);

      if (projectDocSnap.exists()) {
        return projectDocSnap.data();
      } else {
        console.log('No document found in the referenced analysis!');
      }
    } else {
      const userID = analysisData?.data?.owner;
      if (!userID) {
        console.log('Owner not found in analysis data');
        return;
      }

      const subCollectionRef = collection(
        firestore,
        PROJECT_COLLECTION,
        userID,
        PROJECT_SUB_COLLECTION,
      );
      const subCollectionDocRef = doc(subCollectionRef, analysisId);
      const subCollectionDocSnap = await getDoc(subCollectionDocRef);

      if (subCollectionDocSnap.exists()) {
        return subCollectionDocSnap.data();
      } else {
        console.log('No document found in the subcollection!');
      }
    }
  } else {
    console.log('No such document found in the analysis collection!');
  }
}
