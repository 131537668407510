import React, { useState } from 'react';
import { ReactComponent as TrashIcon } from 'assets/svg/trash.svg';
import { ReactComponent as PenIcon } from 'assets/svg/pen.svg';

import './styles/DocDisplay.css';
import TextComponent from 'components/atoms/TextComponent';
import { BOLD } from 'constant/constants';
import { createTextObjectReusable } from 'utils/utils';
import { colors } from 'themes';
import { useSelector } from 'react-redux';

const DocDisplay = ({ data, handleDelete, handleEdit }) => {
    const [openPanels, setOpenPanels] = useState({});
    const userSlice = useSelector(state => state?.user);

    const togglePanel = (key) => {
        setOpenPanels((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const truncate = (str, length = 25) => {
        return str.length > length ? `${str.slice(0, length)}...` : str;
    };

    const renderValue = (key, value, keyToDisplay) => {
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' || value === null) {
            return (
                <div className='item-container'>
                    <div style={{ width: '80%' }}>
                        <TextComponent multipleText={createTextObjectReusable(
                            `{{${keyToDisplay}:}} ${value}`,
                            {

                            },
                            {
                                weight: BOLD,
                                style: { color: colors.SECONDARY }
                            },
                        )}
                        />
                    </div>
                    {(userSlice?.dev === true || keyToDisplay === 'creditNumber' || keyToDisplay === 'monthlyCreditNumber' || (data?.exemple !== true && data?.video_id)) && (
                        <>
                            <PenIcon
                                className="pen-icon"
                                onClick={() => handleEdit(key, keyToDisplay, value)}
                                fill={'white'}
                                height={'1vw'}
                                width={'1vw'}
                            />
                            <TrashIcon className='trash-icon' onClick={() => handleDelete(key)} fill={'white'} height={'1vw'} width={'1vw'} />
                        </>
                    )}
                </div>
            );
        }

        if (typeof value === 'object') {
            const sortedEntries = Object.entries(value).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

            return (
                <>
                    <div className={`item-container ${openPanels[key] ? 'open' : ''}`} style={{ color: 'white' }} onClick={() => togglePanel(key)}>
                        <TextComponent
                            multipleText={createTextObjectReusable(
                                `{{${openPanels[key] ? `[-] ${keyToDisplay}` : `[+] ${keyToDisplay}`}:}} ${openPanels[key] ? '' : truncate(JSON.stringify(value))}`,
                                {},
                                {
                                    weight: BOLD,
                                    style: { color: colors.PRIMARY }
                                },
                            )}
                        />
                    </div>

                    {openPanels[key] && (
                        <div style={{ marginLeft: '20px' }}>
                            {Array.isArray(value)
                                ? value.map((item, index) => (
                                    <div key={index}>
                                        <TextComponent text={index + 1} sx={{ color: 'blue' }} weight={BOLD} />
                                        {renderValue(`${key}-${index}`, item, key)}
                                    </div>
                                ))
                                : sortedEntries.map(([subKey, subVal]) => (
                                    <div key={subKey}>
                                        {renderValue(`${key}-${subKey}`, subVal, subKey)}
                                    </div>
                                ))}
                        </div>
                    )}
                </>
            );
        }

        return null;
    };

    if (!data) return null;

    return (
        <div style={{ width: '100%' }}>
            {Object.entries(data).map(([key, value]) => (
                <div key={key} >
                    {renderValue(key, value, key)}
                </div>
            ))}
        </div>
    );
};

export default DocDisplay;