import React from 'react';

import TextComponent from 'components/atoms/TextComponent';

import { translate } from 'services/remoteConfig';
import { mpTrack } from 'services/mixpanel';

import { BODY_1, BOLD, H4, H5, H6, MEDIUM } from 'constant/constants';

import { colors } from 'themes';

import { ReactComponent as Lock } from 'assets/svg/lock.svg';

function RunningStylePanel({ data, version, locked, modalRef }) {
  const runningStyles = data?.running_styles;

  if (version < 3 || !runningStyles)
    return (
      <TextComponent
        text={
          version < 3 ? translate('NoStyle') : translate('RunningStyleError')
        }
        sx={styles.error}
        weight={MEDIUM}
        variant={BODY_1}
      />
    );

  return (
    <div style={styles.container}>
      <div style={{ ...styles.content, ...(locked ? styles.blur : null) }}>
        <TextComponent
          text={translate('RunningStyleTitle') + runningStyles?.title + ' !'}
          weight={MEDIUM}
          variant={H5}
        />

        <img
          src={runningStyles?.asset}
          alt="Running Style"
          style={styles.image}
        />
        <TextComponent
          text={runningStyles?.animal}
          weight={MEDIUM}
          variant={H4}
        />
        <div style={styles.explanation}>
          <TextComponent
            text={runningStyles?.animalExplanation}
            weight={MEDIUM}
            variant={BODY_1}
            sx={styles.white70}
          />
          <TextComponent
            multipleText={[
              {
                text: runningStyles?.title,
                weight: BOLD,
                variant: BODY_1,
                style: styles.white,
              },
              {
                text: ` : ${runningStyles?.summary}`,
                weight: MEDIUM,
                variant: BODY_1,
                style: styles.white70,
              },
            ]}
          />
          <TextComponent
            text={runningStyles?.explanation}
            weight={MEDIUM}
            variant={BODY_1}
            sx={styles.white70}
          />
        </div>
      </div>

      {locked && (
        <div
          style={styles.lockedContainer}
          onClick={() => {
            mpTrack('Web App Running Style Locked Button Clicked');
            modalRef?.current?.open('locked', 'runningStyle');
          }}>
          <Lock height={20} width={20} />
          <TextComponent
            text={translate('UnlockToSeeRunningStyle')}
            variant={H6}
            sx={styles.textUnlock}
          />
        </div>
      )}
    </div>
  );
}

const styles = {
  blur: {
    filter: 'blur(30px)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10vh',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  error: {
    marginLeft: '2vh',
  },
  explanation: {
    margin: '2vh',
  },
  image: {
    height: '20vh',
    width: '20vh',
  },
  lockedContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    zIndex: 3,
  },
  textUnlock: {
    color: colors.YELLOW,
    marginLeft: '1vh',
  },
  white: {
    color: colors.WHITE,
  },
  white70: {
    color: colors.WHITE_OPACITY_70,
  },
};

export default RunningStylePanel;
