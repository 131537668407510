import { Button, styled } from "@mui/material";
import TextComponent from "components/atoms/TextComponent";
import TextInput from "components/atoms/TextInput";
import { BOLD, CONTAINED, H5 } from "constant/constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectVideoId, setCurrentVideoId } from "slices/adminSlice";
import { colors } from "themes";
import { ReactComponent as LoopIcon } from 'assets/svg/loop.svg';
import DocDisplay from "./DocDisplay";
import './styles/BackOffice.css';
import EditModal from "./component/EditModal";
import DeleteModal from "./component/DeleteModal";
import { updateAnalysisInProject } from "services/projectApi";


function BackOfficeAnalysis({ analysisDetails, modalRef }) {

    const [videoId, setVideoId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [analysis, setAnalysis] = useState(null);
    const videoIdFromStore = useSelector(selectVideoId);
    const [editDb, setEditDb] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        const updateData = async () => {
            if (editDb) {
                await updateAnalysisInProject(analysis, videoId, false);
                setEditDb(false);
            }
        };

        updateData();
    }, [editDb]);

    useEffect(() => {

        if (!videoIdFromStore) {
            setAnalysis(null);
            setVideoId('');
            setDisabled(true);
            return;
        }

        const fetchData = async () => {
            if (videoIdFromStore) {
                setErrorMessage(null);
                setDisabled(false);
                setVideoId(videoIdFromStore);
                await handleSubmit(videoIdFromStore);
            }
        };

        fetchData();
    }, [videoIdFromStore])


    const handleIdChange = e => {
        setVideoId(e?.target?.value);
        setErrorMessage(null);
        if (e?.target?.value) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    async function handleSubmit(id) {
        if (!id && !videoId) return;
        dispatch(setCurrentVideoId(id || videoId));
        const analysis = analysisDetails?.find(item => item?.data?.uploadVideoId === (id || videoId));
        if (!analysis) {
            setErrorMessage('No analysis found');
            return;
        }
        setAnalysis(analysis);

    }

    async function handleDelete(key) {
        modalRef.current.open();
        modalRef?.current?.setContent(
            <DeleteModal textKey={key} setState={setAnalysis} setEditDb={setEditDb} modalRef={modalRef} />
        );
    }

    async function handleEdit(path, key, value) {
        modalRef.current.setEditField(value)
        modalRef.current.open();
        modalRef?.current?.setContent(
            <EditModal textKey={key} path={path} value={value} modalRef={modalRef} setState={setAnalysis} setEditDb={setEditDb} />
        );
    }



    return (
        <div className="category-container">
            <TextComponent
                text={!analysisDetails ? 'Search an analysis' : 'Analysis'}
                variant={H5}
                weight={BOLD}
                sx={{ color: colors.WHITE, textAlign: 'center' }} />
            <div style={{ display: 'flex', marginTop: '4vh', marginLeft: '1vw', width: '90%', alignItems: 'center' }}>

                <TextInput
                    label={"Video id"}
                    value={videoId}
                    onChange={handleIdChange}
                    handleSubmit={handleSubmit}
                    error={errorMessage}
                    helperText={errorMessage}
                />
                <IconButton variant={CONTAINED} sx={{ backgroundColor: colors.SECONDARY, marginLeft: '1vw' }} disabled={disabled} onClick={() => handleSubmit(videoId)}>
                    <LoopIcon />
                </IconButton>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '2vh' }}>
                {analysis && (
                    <>
                        <TextComponent
                            text={new Date(analysis?.date)?.toLocaleDateString()}
                            variant={H5}
                            weight={BOLD}
                            sx={{ color: colors.WHITE, textAlign: 'center', marginBottom: '2vh' }} />
                        <img src={analysis?.thumbnail} style={{ width: analysis?.analysis_type === 'pronation' || analysis?.treadmill === true ? '40%' : '70%', height: analysis?.analysis_type === 'pronation' ? '40vh' : '30vh', cursor: 'pointer', alignSelf: 'center', marginBottom: '2vh' }} alt="thumbnail" onClick={() => navigate(`/analysis-shared/${analysis?.data?.uploadVideoId}`)} />
                        <DocDisplay data={Object.entries(analysis)
                            .sort((a, b) => a[0].localeCompare(b[0]))
                            .reduce((acc, [key, value]) => {
                                acc[key] = value;
                                return acc;
                            }, {})}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                        />
                    </>

                )}


            </div>
        </div>

    );
}

const IconButton = styled(Button)({
    '&:hover': {
        backgroundColor: colors.OCHY_BLUE,
        transition: '0.2s ease-in-out',
    },
    '&:disabled': {
        backgroundColor: '#2E2E2E',
        color: colors.TEXT_GRAY,
    },
});


export default BackOfficeAnalysis;