import React from 'react';

import { colors, images } from 'themes';
import TextComponent from 'components/atoms/TextComponent';
import { metricsConfig } from '../config/metricsConfig';
import { BODY_2, BOLD, H2, H6 } from 'constant/constants';
import './styles/AnalysisDetailCard.css';
import { translate } from 'services/remoteConfig';
import { mpTrack } from 'services/mixpanel';
import { ReactComponent as Lock } from 'assets/svg/lock.svg';
import { createTextObjectReusable } from 'utils/utils';

function MetricsCardContent({ cardData, setAnalysisData, locked, modalRef }) {
  const metricsContent = metricsConfig(cardData?.metricKey, cardData?.value);
  if (!metricsContent) return null;
  return (
    <div
      className="analysis-detail-card-container"
      onClick={() => {
        if (locked) {
          mpTrack('Web App Metrics Locked Button Clicked')
          modalRef?.current?.open('locked', 'metrics');
        }
        else {
          mpTrack('Web App Metrics Card Clicked', {
            'Metrics Item': cardData?.metricKey
          })
        }
        setAnalysisData(cardData);
      }}
    >
      <div style={styles.textContainer}>
        {cardData?.value < 0 ? (
          <TextComponent
            text={translate('ErrorMetrics')}
            variant={H6}
            weight={BOLD}
            sx={{ color: colors?.RED, textAlign: 'center' }}
          />
        ) : (
          <>{locked ? (
            <div style={styles.lockedContainer} >
              <Lock height={20} width={20} />
              <TextComponent
                text={translate("UnlockMetric")}
                variant={H6}
                sx={styles.textUnlock}
              />
            </div>) :
            <TextComponent
              text={cardData?.metricKey === 'duty_factor' ? metricsContent?.value : cardData?.value}
              variant={H2}
              weight={BOLD}
              sx={styles.textLight}
              multipleText={
                typeof cardData?.value === 'object' &&
                createTextObjectReusable(
                  `${cardData?.value?.ft} {{ft}} ${cardData?.value?.inch} {{inch}}`,
                  { style: styles.explanationText, variant: H2, weight: BOLD },
                  {
                    style: { color: colors.WHITE_OPACITY_50 },
                    variant: BODY_2,
                  },
                  {},
                )
              }
            />


          }

            <TextComponent
              text={typeof cardData?.value !== 'object' ? metricsContent?.unity : ''}
              variant={BODY_2}
              sx={styles.textUnity}
            />
          </>
        )}

        <TextComponent
          text={metricsContent?.translation}
          variant={H6}
          sx={styles.textTranslation}
        />
      </div>
      <div style={styles.imageContainer}>
        {cardData?.metricKey === 'duty_factor' ? (
          <></>
        ) : (
          <img
            src={
              metricsContent?.image
            }
            alt={'steps'}
            style={{ width: '100%' }}
          />
        )}
      </div>
    </div>
  );
}
const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  textContainer: {
    marginLeft: 5,
    marginTop: 5
  },
  imageContainer: {
    width: 200,
    display: 'flex',
    alignSelf: 'center',
    marginTop: '5vh'
  },
  lockedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2vh'
  },
  textLight: {
    fontWeight: 'light'
  },
  textUnity: {
    color: '#737373',
    marginTop: -1
  },
  textTranslation: {
    marginTop: 1.5
  },
  textUnlock: {
    color: colors.YELLOW,
    marginLeft: '0.5vw'
  }
};
export default MetricsCardContent;
