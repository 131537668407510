import React, {useEffect, useState} from 'react';
import {BLUE, GREEN, ORANGE, YELLOW} from 'constant/constants';
import {colors} from 'themes';
import {ReactComponent as RoundedRectangle} from 'assets/svg/roundedRectangle.svg';

function CardWithRoundedIcon({
  icon: Icon,
  iconSize = 33,
  children,
  type,
  svgColor,
  svgBackground,
  svgRotation = '0deg',
}) {
  const [displayData, setDisplayData] = useState({});

  useEffect(() => {
    if (type === ORANGE) {
      setDisplayData({
        svgColor: svgColor ? svgColor : colors.SECONDARY,
        svgBackgroundColor: colors.SECONDARY_OPACITY_15,
        radialBackground: colors.SECONDARY_OPACITY_10,
      });
    } else if (type === YELLOW) {
      setDisplayData({
        svgColor: svgColor ? svgColor : colors.YELLOW,
        svgBackgroundColor: colors.YELLOW_OPACITY_15,
        radialBackground: colors.YELLOW_OPACITY_10,
      });
    } else if (type === GREEN) {
      setDisplayData({
        svgColor: colors.RADIAL_GREEN,
        svgBackgroundColor: colors.RADIAL_GREEN_BACKGROUND,
        radialBackground: colors.GREEN_OPACITY_10,
      });
    } else if (type === BLUE) {
      setDisplayData({
        svgColor: colors.PRIMARY,
        svgBackgroundColor: colors.PRIMARY_OPACITY_15,
        radialBackground: colors.PRIMARY_OPACITY_10,
      });
    } else {
      setDisplayData({
        svgColor: svgColor ? svgColor : colors.WHITE,
        svgBackgroundColor: colors.WHITE_OPACITY_10,
        radialBackground: colors.WHITE_OPACITY_10,
      });
    }
  }, [type, svgColor, svgBackground]);

  return (
    <div style={styles.container}>
      <div style={styles.roundedBorder}>
        <div
          style={{
            ...styles.iconContainer,
            ...{backgroundColor: displayData?.svgBackgroundColor},
          }}>
          {Icon &&
            (React.isValidElement(Icon) ? (
              React.cloneElement(Icon, {
                width: iconSize,
                height: iconSize,
                fill: displayData?.svgColor,
                style: {
                  ...(Icon.props.style || {}),
                  transform: `rotate(${svgRotation})`,
                },
              })
            ) : (
              <Icon
                width={iconSize}
                height={iconSize}
                style={{transform: `rotate(${svgRotation})`}}
              />
            ))}
        </div>
        <div style={styles.roundedRectangle}>
          <RoundedRectangle
            height={65}
            fill={colors.BACKGROUND}
            stroke={'#2C2C2C'}
            strokeWidth={1.5}
            style={{
              position: 'absolute',
              top: -32,
            }}
          />
        </div>
      </div>
      <div
        style={{
          ...styles.radialCard,
          ...{
            background: `radial-gradient(ellipse at top, ${displayData?.radialBackground}, transparent)`,
          },
        }}>
        <div style={styles.children}>{children}</div>
      </div>
    </div>
  );
}

const styles = {
  children: {
    width: '100%',
    display: 'flex',
    padding: 10,
    flexDirection: 'column',
    marginTop: '2vh',
  },
  container: {
    position: 'relative',
    alignSelf: 'center',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  iconContainer: {
    position: 'absolute',
    width: 50,
    height: 50,
    borderRadius: 30,
    top: -28,
    borderColor: '#363636',
    borderWidth: 2.5,
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  radialCard: {
    width: '100%',
    height: '100%',
    borderColor: '#363636',
    borderWidth: 2.5,
    borderStyle: 'solid',
    borderRadius: 20,
    display: 'flex',
  },
  roundedBorder: {
    width: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 20,
  },
  roundedRectangle: {
    height: 200,
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
};

export default CardWithRoundedIcon;
