import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  sAccountUid: '',
  sCurrentVideoId: '',
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetAdminStore: (state, action) => {
      state.sAccountUid = '';
      state.sCurrentVideoId = '';
    },
    setAccountUid: (state, action) => {
      state.sAccountUid = action?.payload;
    },
    setCurrentVideoId: (state, action) => {
      state.sCurrentVideoId = action?.payload;
    },
  },
});

export const {resetAdminStore, setAccountUid, setCurrentVideoId} =
  adminSlice.actions;

export const selectAccountUid = state => state?.admin?.sAccountUid;
export const selectVideoId = state => state?.admin?.sCurrentVideoId;

export default adminSlice.reducer;
