import PickerComponent from "components/atoms/PickerComponent";
import TextComponent from "components/atoms/TextComponent";
import { BOLD } from "constant/constants";
import React, { useEffect, useRef, useState } from "react";
import { colors } from "themes";
import '../styles/BackOfficeUsage.css';
import { ReactComponent as PenIcon } from 'assets/svg/pen.svg';
import { createTextObjectReusable } from "utils/utils";
import TextInput from "components/atoms/TextInput";


function AddPlanToUsage({ userUsage, modalRef, setUserInfo, setEditDb, userType }) {

    const languageRef = useRef();
    const [usage, setUsage] = useState(userUsage);
    const [displayNewUsage, setDisplayNewUsage] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setUsage(userUsage);
    }, [userUsage]);


    async function handleSubscriptionChange(subscriptionType) {

        const updatedUsage = usageConfig?.reduce((acc, type) => {
            acc[type] = type === subscriptionType;
            return acc;
        }, {});

        setUsage(prevUsage => ({
            ...prevUsage,
            ...updatedUsage
        }));
    }

    const usageConfig =
        userType === 'athlete' ?
            [
                'monthlyPlan',
                'quaterlyPlan',
                'yearlyPlan',
            ]
            :
            [
                'monthlyPlan',
                'monthly10',
                'monthly30',
                'quaterlyPlan',
                'yearlyPlan',
                'yearly10',
                'yearly30',
            ];


    useEffect(() => {
        if (Object.entries(displayNewUsage)?.length === 0) {
            setDisplayNewUsage({
                subscriptionExpiresDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
                monthlyCreditNumber: 0,
                monthlyPlan: true,
            })
            return;
        }


        async function setUsageValues() {
            let subscriptionExpiresDate, monthlyCreditExpired, monthlyCreditNumber;
            let monthlyPlan, yearlyPlan = false;
            for (const [key, value] of Object.entries(usage)) {
                if (value === true && (key?.startsWith('yearly') || key?.startsWith('quaterlyPlan') || key?.startsWith('monthly'))) {

                    if (key?.startsWith('yearly')) {
                        subscriptionExpiresDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
                        monthlyCreditExpired = new Date(new Date().setMonth(new Date().getMonth() + 1));
                        const yearlyNumber = key?.split('yearly')[1];
                        monthlyCreditNumber = Number(yearlyNumber) ? parseInt(yearlyNumber, 10) : 0;
                        yearlyPlan = true;
                        break;
                    } else if (key?.startsWith('quaterlyPlan')) {
                        subscriptionExpiresDate = new Date(new Date().setMonth(new Date().getMonth() + 3));
                        monthlyCreditExpired = new Date(new Date().setMonth(new Date().getMonth() + 1));
                        monthlyCreditNumber = 3;
                        break;
                    } else if (key?.startsWith('monthly')) {
                        subscriptionExpiresDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
                        monthlyCreditExpired = new Date(new Date().setMonth(new Date().getMonth() + 1));
                        const monthlyNumber = key?.split('monthly')[1];
                        monthlyCreditNumber = Number(monthlyNumber) ? parseInt(monthlyNumber, 10) : 0;
                        monthlyPlan = true;
                        break;
                    }
                } else {
                    subscriptionExpiresDate = null;
                    monthlyCreditExpired = null;
                    monthlyCreditNumber = 0;
                }
            }
            setDisplayNewUsage({
                subscriptionExpiresDate: subscriptionExpiresDate,
                monthlyCreditExpired: monthlyCreditExpired,
                monthlyCreditNumber: monthlyCreditNumber,
                monthlyPlan: monthlyPlan || false,
                yearlyPlan: yearlyPlan || false
            });
        }

        setUsageValues();
    }, [usage]);

    function changeDate(e, type) {
        if (type === 'subscriptionExpiration') {
            setDisplayNewUsage((prev) => ({
                ...prev,
                subscriptionExpiresDate: new Date(e.target.value)
            }))
        }
        else if (type === 'monthlyExpiration') {
            setDisplayNewUsage((prev) => ({
                ...prev,
                monthlyCreditExpired: new Date(e.target.value)
            }))
        }
    }


    async function onValidateClick() {
        const subscriptionExpiresDate = new Date(displayNewUsage?.subscriptionExpiresDate);
        const monthlyCreditExpiredDate = new Date(displayNewUsage?.monthlyCreditExpired);
        const today = new Date();

        subscriptionExpiresDate?.setHours(0, 0, 0, 0);
        monthlyCreditExpiredDate?.setHours(0, 0, 0, 0);
        today?.setHours(0, 0, 0, 0);
        if (subscriptionExpiresDate < today || monthlyCreditExpiredDate < today) {

            setErrorMessage({
                subscriptionExpiresDate: subscriptionExpiresDate < today ? 'Date must be greater than today' : null,
                monthlyCreditExpired: monthlyCreditExpiredDate < today ? 'Date must be greater than today' : null
            })
            return;
        }

        setUsage((prevUsage) => ({
            ...prevUsage,

            subscriptionExpiresDate: displayNewUsage?.subscriptionExpiresDate,
            monthlyCreditExpired: displayNewUsage?.monthlyCreditExpired,
            monthlyCreditNumber: displayNewUsage?.monthlyCreditNumber,
            monthlyPlan: displayNewUsage?.monthlyPlan || false,
            yearlyPlan: displayNewUsage?.yearlyPlan || false
        }));

        setUserInfo((prevUserInfo) => ({
            ...prevUserInfo,
            usage: {
                ...prevUserInfo?.usage,
                ...usage,
                subscriptionExpiresDate: displayNewUsage?.subscriptionExpiresDate,
                monthlyCreditExpired: displayNewUsage?.monthlyCreditExpired,
                monthlyCreditNumber: displayNewUsage?.monthlyCreditNumber,
                monthlyPlan: displayNewUsage?.monthlyPlan || false,
                yearlyPlan: displayNewUsage?.yearlyPlan || false
            }


        }));
        setEditDb(true);

        modalRef.current.setClose();
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '2vh' }}>

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '2vh' }}>
                <TextComponent
                    text={'Subscription: '}
                    weight={BOLD}
                    sx={{ color: colors.WHITE, textAlign: 'center', marginRight: '2vh' }}
                />
                <PickerComponent
                    config={usageConfig}
                    height={'4vh'}
                    handleValueChanges={handleSubscriptionChange}
                    ref={languageRef}
                    defaultValue={usageConfig[0]} />
            </div>
            <div style={{ width: '90%', display: 'flex', flexDirection: 'column', marginTop: '2vh' }}>
                <TextComponent
                    multipleText={createTextObjectReusable(
                        `[[Monthly credit number]] : ${displayNewUsage?.monthlyCreditNumber ? displayNewUsage?.monthlyCreditNumber : 'Unlimited'}`,
                        { style: { color: colors.WHITE } },
                        {},
                        {
                            style: { color: colors.SECONDARY },
                            weight: BOLD,
                        },
                    )}
                    sx={{ marginBottom: '2vh' }}
                />
                {displayNewUsage?.monthlyCreditNumber !== 0 &&
                    (

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: '2vh' }}>
                            <TextComponent
                                text={'Monthly credit expiration : '}
                                weight={BOLD}
                                sx={{ color: colors.SECONDARY, marginRight: '1vh', width: '100%' }}
                            />
                            <TextInput
                                onChange={(e) => changeDate(e, 'monthlyExpiration')}
                                type={'date'}
                                value={displayNewUsage?.monthlyCreditExpired && new Date(displayNewUsage?.monthlyCreditExpired)?.toISOString().split('T')[0]}
                                error={errorMessage?.monthlyCreditExpired}
                                helperText={errorMessage?.monthlyCreditExpired}
                            />
                        </div>
                    )}

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: '2vh' }}>
                    <TextComponent
                        text={'Subscription expiration : '}
                        weight={BOLD}
                        sx={{ color: colors.SECONDARY, marginRight: '1vh', width: '100%' }}
                    />
                    <TextInput
                        onChange={(e) => changeDate(e, 'subscriptionExpiration')}
                        type={'date'}
                        value={displayNewUsage?.subscriptionExpiresDate && new Date(displayNewUsage?.subscriptionExpiresDate)?.toISOString().split('T')[0]}
                        error={errorMessage?.subscriptionExpiresDate}
                        helperText={errorMessage?.subscriptionExpiresDate}
                    />
                </div>
            </div>
            <div className={`button button-edit`} onClick={onValidateClick} style={{ marginTop: '2vh', marginBottom: '2vh' }}>
                <TextComponent text={'Add plan'} sx={{ color: colors.WHITE }} />
                <PenIcon className="button-icon" />
            </div>
        </div>

    )

}


export default AddPlanToUsage;