import React from 'react';
import TextComponent from 'components/atoms/TextComponent';
import RoundedScoreMeter from 'screen/analysis/AnalysisListCard/components/RoundedScoreMeter';
import { score_colors } from 'screen/analysis/AnalysisListCard/constants/analysisListConstants';
import ScoreTextWithBackground from '../CardComponent/components/ScoreTextWithBackground';

import { translate } from 'services/remoteConfig';
import { mpTrack } from 'services/mixpanel';

import { getAverageScore } from 'utils/utils';

import { BODY_2, H3, H6, LIGHT } from 'constant/constants';

import { colors } from 'themes';

import { ReactComponent as Lock } from 'assets/svg/lock.svg';

function TotalScore({ totalScore, locked, modalRef }) {


  return (
    <div style={styles.container}>
      <div style={{ ...styles.subContainer, ...(locked ? styles.blur : null) }}>
        <div style={styles.row}>
          <RoundedScoreMeter
            size={70}
            item={'score'}
            scoreNumber={totalScore * 0.01}
            score_text={
              totalScore > 66
                ? 'score_good'
                : totalScore > 33
                  ? 'score_okay'
                  : 'score_bad'
            }
          />
          <div style={styles.justifyCenter}>
            <TextComponent
              text={translate('TotalScore')}
              sx={styles.totalScoreText}
              variant={BODY_2}
            />
            <ScoreTextWithBackground scoreNumber={totalScore / 100} />
          </div>
          <TextComponent
            sx={{
              ...styles.score,
              color:
                totalScore >= 66
                  ? score_colors.score_good.stroke
                  : totalScore < 33
                    ? score_colors.score_bad.stroke
                    : score_colors.score_okay.stroke,
            }}
            text={totalScore + '%'}
            weight={LIGHT}
          />
        </div>
      </div>
      {locked && (
        <div
          style={styles.locked}
          onClick={() => {
            mpTrack('Web App Total Score Locked Button Clicked');
            modalRef?.current?.open('locked', 'totalScore');
          }}>
          <Lock height={20} width={20} />
          <TextComponent
            text={translate('UnlockToSee')}
            variant={H6}
            sx={styles.lockedText}
          />
        </div>
      )}
    </div>
  );
}

const styles = {
  blur: {
    filter: 'blur(30px)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  container: {
    display: 'flex',
    width: '100%',
    minHeight: '10vh',
    maxHeight: '15vh',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#181818',
    borderRadius: '4px',
    marginTop: '16px',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  locked: {
    display: 'flex',
    width: '100%',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  lockedText: {
    color: colors.YELLOW,
    marginLeft: '8px',
  },
  score: {
    width: '100%',
    textAlign: 'left',
    marginLeft: '8px',
    '@media (min-width:600px)': {
      textAlign: 'center',
    },
    fontSize: '2.5vw'
  },
  totalScoreText: {
    color: colors.TEXT_GRAY,
    whiteSpace: 'nowrap',
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '16px',
    '@media (min-width:600px)': {
      flexDirection: 'row',
      width: '50%',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
  },
};

export default TotalScore;
